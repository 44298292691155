import { path, prop } from 'ramda'
import { all, fork, takeEvery, put, call, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import {
  getOne,
  createOne,
  updateOne,
  updateStatus,
  deleteOne,
  activateOne,
} from '../../api/advert'
import actionType, {
  ADVERT,
  ADVERT_UPDATE_STATUS,
  ADVERT_DELETE,
  ADVERT_ACTIVATE,
} from './actionTypes'
import * as route from '../../constants/routes'
import { ADVERT_CREATE } from '../actionTypes'
import * as actions from '../../redux/actions'

function* getOneRequest() {
  yield takeEvery(actionType[ADVERT].request, function* ({ payload }) {
    try {
      const { data } = yield call(getOne, payload)

      yield put({
        type: actionType[ADVERT].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[ADVERT].error,
        error,
      })
    }
  })
}

function* updateStatusRequest() {
  yield takeEvery(actionType[ADVERT_UPDATE_STATUS].request, function* ({ payload }) {
    try {
      const { data } = yield call(updateStatus, payload)

      yield put({
        type: actionType[ADVERT_UPDATE_STATUS].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[ADVERT_UPDATE_STATUS].error,
        error,
      })
    }
  })
}

function* updateStatusSuccess() {
  yield takeEvery(actionType[ADVERT_UPDATE_STATUS].success, function* ({ args }) {
    const { router } = yield select()
    const query = path(['location', 'query'], router)
    const status = prop('status', query)

    yield put(push({
      pathname: route.ADMIN_PRODUCTS_LIST,
      search: queryString.stringify({
        ...query,
        status,
      }),
      state: {
        update: true
      }
    }))
  })
}

function* createOneRequest() {
  yield takeEvery(actionType[ADVERT_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? updateOne : createOne
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[ADVERT_CREATE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[ADVERT_CREATE].error,
        error,
      })
    }
  })
}

function* createOneSuccess() {
  yield takeEvery(actionType[ADVERT_CREATE].success, function* ({ args }) {
    const { router } = yield select()
    const query = path(['location', 'query'], router)

    yield put(
      actions.advertsList({
        ...query,
        page: prop('page', args),
      })
    )
  })
}

function* deleteOneRequest() {
  yield takeEvery(actionType[ADVERT_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(deleteOne, payload)

      yield put({
        type: actionType[ADVERT_DELETE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[ADVERT_DELETE].error,
        error,
      })
    }
  })
}

function* deleteOneSuccess() {
  yield takeEvery(actionType[ADVERT_DELETE].success, function* () {
    yield put(push(route.ADMIN_PRODUCTS_LIST))
  })
}

function* activateOneRequest() {
  yield takeEvery(actionType[ADVERT_ACTIVATE].request, function* ({ payload }) {
    try {
      const { data } = yield call(activateOne, payload)

      yield put({
        type: actionType[ADVERT_ACTIVATE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[ADVERT_ACTIVATE].error,
        error,
      })
    }
  })
}

function* activateOneSuccess() {
  yield takeEvery(actionType[ADVERT_ACTIVATE].success, function* () {
    yield put(push(route.ADMIN_PRODUCTS_LIST))
  })
}

export default function* rootSaga() {
  yield all([
    fork(getOneRequest),
    fork(createOneRequest),
    fork(createOneSuccess),
    fork(updateStatusRequest),
    fork(updateStatusSuccess),
    fork(deleteOneRequest),
    fork(deleteOneSuccess),
    fork(activateOneRequest),
    fork(activateOneSuccess),
  ])
}
