import { path, prop } from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import ImagesUploader from 'react-images-uploader'
import { NEW_FILE_UPLOAD_REACT_URL } from '../../constants/api'
import { getToken } from '../../helpers/tokenStorage'
import '../../assets/css/custom-styles.css'

function SingleImageUploader(props) {
  const { input, addon } = props

  const fileUploadObj = prop('value', input)
  const onChange = prop('onChange', input)
  let defaultImage = undefined
  if (fileUploadObj) {
    defaultImage = fileUploadObj['url']
  }

  return (
    <div>
      {addon}
      <ImagesUploader
        url={NEW_FILE_UPLOAD_REACT_URL}
        headers={{ Authorization: `Bearer ${getToken()}` }}
        optimisticPreviews={true}
        multiple={false}
        image={defaultImage}
        deleteImage={number => {
          onChange(null)
        }}
        onLoadEnd={(err, response) => {
          if (err) {
            const data = path(['response', 'data'], err)
            onChange(data[0])

            console.error(err)
          } else if (response) {
            if (response.length > 0) {
              const data = prop('data', response)
              console.log(data)

              onChange(data[0])
            } else onChange(null)
          }
        }}
        dataName='files'
      />

      {props.meta.touched && props.meta.error && (
        <p style={{ color: 'red', fontSize: '12px' }}> {props.meta.error} </p>
      )}
    </div>
  )
}

SingleImageUploader.defaultProps = {}

SingleImageUploader.propTypes = {
  input: PropTypes.object.isRequired,
}

export default SingleImageUploader
