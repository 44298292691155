import actionType, { RUBRIC, RUBRIC_ACTIVATE, RUBRIC_CREATE, RUBRIC_DELETE } from './actionTypes'

export const rubric = payload => ({
  type: actionType[RUBRIC].request,
  payload,
})

export const rubricClear = payload => ({
  type: actionType[RUBRIC].clear,
  payload,
})

export const rubricCreate = payload => ({
  type: actionType[RUBRIC_CREATE].request,
  payload,
})

export const rubricDelete = payload => ({
  type: actionType[RUBRIC_DELETE].request,
  payload,
})

export const rubricActivate = payload => ({
  type: actionType[RUBRIC_ACTIVATE].request,
  payload,
})
