import createAction from '../../helpers/createAction'
import {
  PAGE_CATEGORY,
  PAGE_CATEGORY_CREATE,
  PAGE_CATEGORY_DELETE,
  PAGE_CATEGORY_ACTIVATE,
} from '../actionTypes'

export default {
  [PAGE_CATEGORY]: createAction(PAGE_CATEGORY),
  [PAGE_CATEGORY_CREATE]: createAction(PAGE_CATEGORY_CREATE),
  [PAGE_CATEGORY_DELETE]: createAction(PAGE_CATEGORY_DELETE),
  [PAGE_CATEGORY_ACTIVATE]: createAction(PAGE_CATEGORY_ACTIVATE),
}

export { PAGE_CATEGORY, PAGE_CATEGORY_CREATE, PAGE_CATEGORY_DELETE, PAGE_CATEGORY_ACTIVATE }
