import { TOKEN } from '../constants/storage'

export const getStorage = local => (local ? localStorage : sessionStorage)

export const getToken = () => localStorage.getItem(TOKEN) || sessionStorage.getItem(TOKEN)

export const setToken = (token, local = false) => {
  const storage = getStorage(local)

  storage.setItem(TOKEN, token)
}

export const removeToken = () => {
  localStorage.removeItem(TOKEN)
  sessionStorage.removeItem(TOKEN)
}
