import { compose, path, prop, propOr, map, find, propEq } from 'ramda'
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap'
import Header from '../components/Headers/Header'
import CreateForm from '../components/District/DistrictCreateForm'
import * as route from '../constants/routes'
import * as actions from '../redux/actions'

const enhance = compose(
  connect(({ district, cityList, form, router }) => {
    const item = prop('data', district)
    const loading = prop('loading', district)
    const cityOptions = map(
      item => ({
        label: path(['name', 'ru'], item),
        value: prop('id', item),
      }),
      propOr([], 'data', cityList)
    )

    const formValues = path(['DistrictForm', 'values'], form)

    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    return {
      item,
      loading,
      cityOptions,
      formValues,
      pathname,
      query,
    }
  })
)

function District(props) {
  const { history, pathname, query, item, cityOptions, formValues } = props
  const id = prop('id', query)

  useEffect(() => {
    if (!id) {
      history.push({
        pathname: route.ADMIN_DISTRICT_LIST,
        state: {
          from: pathname,
        },
      })
    }

    if (+id > 0) {
      props.dispatch(
        actions.district({
          id,
        })
      )
    }

    props.dispatch(actions.cityList())

    return () => {
      props.dispatch(actions.districtClear())
    }
  }, [])

  function handleSubmit() {
    props.dispatch(
      actions.districtCreate({
        id: +id > 0 ? id : undefined,
        ...formValues,
        city_id: path(['city_id', 'value'], formValues),
      })
    )
  }

  function handleSuccess() {
    props.dispatch(submit('DistrictForm'))
  }

  function handleDelete() {
    props.dispatch(
      actions.districtDelete({
        id,
      })
    )
  }

  function handleBack() {
    history.push({
      pathname: route.ADMIN_DISTRICT_LIST,
      state: {
        from: pathname,
      },
    })
  }

  const initialValues = {
    name: prop('name', item),
    city_id: find(propEq('value', prop('city_id', item)), cityOptions),
  }

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>District</h3>
                  </Col>
                  <Col xs='4' className='text-right'>
                    {+id > 0 && (
                      <Button size='sm' color='danger' onClick={handleDelete}>
                        Удалить
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <CreateForm
                  initialValues={initialValues}
                  cityOptions={cityOptions}
                  onSubmit={handleSubmit}
                />
              </CardBody>

              <CardFooter className='text-right'>
                <Button onClick={handleBack}>Назад</Button>

                <Button color='primary' onClick={handleSuccess}>
                  Сохранить
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default enhance(District)
