import { prop, dissoc } from 'ramda'
import { AuthApi } from '../helpers/httpClients'
import { STORE } from '../constants/api'

export const getAll = data =>
  AuthApi().get(
    STORE + `/all?page=${prop('page', data)}&size=10&search=${prop('search', data)}`,
    data
  )

export const getOne = data => AuthApi().get(`${STORE}/${prop('id', data)}`, dissoc('id', data))

export const createOne = data => AuthApi().post(STORE + '/', data)

export const updateOne = data => AuthApi().put(`${STORE}/${prop('id', data)}`, data)

export const deleteOne = data => AuthApi().delete(`${STORE}/${prop('id', data)}`)

export const activateOne = data => AuthApi().put(`${STORE}/activate/${prop('id', data)}`)

export const updateStatus = data =>
  AuthApi().put(`${STORE}/update/status?id=${prop('id', data)}&status=${prop('status', data)}`)
