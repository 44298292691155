import actionType, {
  SUBCATEGORY,
  SUBCATEGORY_CREATE,
  SUBCATEGORY_DELETE,
  SUBCATEGORY_ACTIVATE,
} from './actionTypes'

export const subcategory = payload => ({
  type: actionType[SUBCATEGORY].request,
  payload,
})

export const subcategoryClear = payload => ({
  type: actionType[SUBCATEGORY].clear,
  payload,
})

export const subcategoryCreate = payload => ({
  type: actionType[SUBCATEGORY_CREATE].request,
  payload,
})

export const subcategoryDelete = payload => ({
  type: actionType[SUBCATEGORY_DELETE].request,
  payload,
})

export const subcategoryActivate = payload => ({
  type: actionType[SUBCATEGORY_ACTIVATE].request,
  payload,
})
