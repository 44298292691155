import { type } from 'ramda'
import React, { Fragment } from 'react'
import { ContentState, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

function EditorFieldNew(props) {
  const { input, addon } = props

  const { value, onChange } = input

  const editorStyleObj = {
    border: '0',
    fontSize: '0.875rem',
    boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
    borderRadius: '0.375rem',
    backgroundColor: '#ffffff',
    lineHeight: '1.5',
    marginBottom: '1.5rem',
    transition: 'box-shadow .15s ease',
    padding: '0.625rem 0.75rem',
    backgroundClip: 'padding-box',
  }

  function handleOnChange(event) {
    onChange(event)
  }

  const toDraft = value => {
    const contentBlock = htmlToDraft(value)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    return EditorState.createWithContent(contentState)
  }

  const editorState = type(value) === 'String' ? toDraft(value) : value

  return (
    <Fragment>
      {addon}
      <Editor
        editorState={editorState}
        wrapperClassName='demo-wrapper'
        editorClassName='demo-editor'
        editorStyle={editorStyleObj}
        onEditorStateChange={handleOnChange}
      />
    </Fragment>
  )
}

export default EditorFieldNew
