import React from 'react'
import { eventChannel } from 'redux-saga'
import { SocketApi } from '../../helpers/httpClients'
import { Client } from '@stomp/stompjs'

export const Socket = ({ actionType: { success, error }, onMessageHandle }) => {
  return eventChannel(dispatch => {
    const { url, token, subscriptionUrl } = SocketApi()
    // const socket = stomp.client(url)

    const socket = new Client({
      brokerURL: url,
      connectHeaders: {
        login: token,
      },
      // debug: function (str) {
      //   console.log("SOCKET DEBUG:");
      //   console.log(str);
      // },
      reconnectDelay: 10000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
    })

    socket.onConnect = function (frame) {
      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect
      onMessageHandle('success', 'onConnect')
      dispatch({
        type: success,
        payload: socket,
      })

      const onMessage = message => {
        onMessageHandle(message, 'onMessage')
      }

      socket.subscribe(subscriptionUrl, onMessage)
    }

    socket.onStompError = function (frame) {
      // Will be invoked in case of error encountered at Broker
      // Bad login/passcode typically will cause an error
      // Complaint brokers will set `message` header with a brief message. Body may contain details.
      // Compliant brokers will terminate the connection after any error
      console.log('Broker reported error: ' + frame.headers['message'])
      console.log('Additional details: ' + frame.body)

      onMessageHandle('error', 'onStompError')
    }

    socket.activate()

    return () => {
      console.log('eventChannel')
    }
  })
}
