import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { districtGet, districtCreate, districtUpdate, districtDelete } from '../../api/district'
import actionType, { DISTRICT, DISTRICT_CREATE, DISTRICT_DELETE } from './actionTypes'
import * as route from '../../constants/routes'

function* districtRequest() {
  yield takeEvery(actionType[DISTRICT].request, function* ({ payload }) {
    try {
      const { data } = yield call(districtGet, payload)

      yield put({
        type: actionType[DISTRICT].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[DISTRICT].error,
        error,
      })
    }
  })
}

function* districtCreateRequest() {
  yield takeEvery(actionType[DISTRICT_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? districtUpdate : districtCreate
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[DISTRICT_CREATE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[DISTRICT_CREATE].error,
        error,
      })
    }
  })
}

function* districtCreateSuccess() {
  yield takeEvery(actionType[DISTRICT_CREATE].success, function* () {
    yield put(push(route.ADMIN_DISTRICT_LIST))
  })
}

function* districtDeleteRequest() {
  yield takeEvery(actionType[DISTRICT_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(districtDelete, payload)

      yield put({
        type: actionType[DISTRICT_DELETE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[DISTRICT_DELETE].error,
        error,
      })
    }
  })
}

function* districtDeleteSuccess() {
  yield takeEvery(actionType[DISTRICT_DELETE].success, function* () {
    yield put(push(route.ADMIN_DISTRICT_LIST))
  })
}

export default function* rootSaga() {
  yield all([
    fork(districtRequest),
    fork(districtCreateRequest),
    fork(districtCreateSuccess),
    fork(districtDeleteRequest),
    fork(districtDeleteSuccess),
  ])
}
