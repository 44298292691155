import { prop, dissoc } from 'ramda'
import { NotAuthApi, AuthApi } from '../helpers/httpClients'
import { SUBCATEGORY } from '../constants/api'

export const subcategoryList = data => AuthApi().get(SUBCATEGORY + '/', data)

export const subcategoryGet = data =>
  AuthApi().get(`${SUBCATEGORY}/${prop('id', data)}`, dissoc('id', data))

export const subcategoryCreate = data => AuthApi().post(SUBCATEGORY + '/', data)

export const subcategoryUpdate = data => AuthApi().put(`${SUBCATEGORY}/${prop('id', data)}`, data)

export const subcategoryDelete = data => AuthApi().delete(`${SUBCATEGORY}/${prop('id', data)}`)

export const subcategoryActivate = data =>
  AuthApi().put(`${SUBCATEGORY}/activate/${prop('id', data)}`)
