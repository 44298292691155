import { compose, path, pathOr, prop, isEmpty } from 'ramda'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import NotificationAlert from 'react-notification-alert'
import AdminNavbar from '../components/Navbars/AdminNavbar'
import AdminFooter from '../components/Footers/AdminFooter'
import Sidebar from '../components/Sidebar/Sidebar'
import * as route from '../constants/routes'
import { getToken } from '../helpers/tokenStorage'
import { getUserRole, hasAnyRole, hasRole } from '../helpers/get'
import * as actions from '../redux/actions'
import routes from '../routes'
import { ADMIN, MODERATOR, SEO_MODERATOR } from '../constants/userRole'

const enhance = compose(
  connect(({ userInfo, token }) => {
    const roles = pathOr([], ['data', 'roles'], userInfo)

    return ({
      token: prop('data', token),
      roles
    })
  })
)

class Admin extends Component {
  constructor(props) {
    super(props);
    const token = prop('token', props)

    this.state = { subscribedToSocket: false, socketConnectionErrorShown: false };

    if (!token) {
      props.dispatch(actions.token(getToken()))
      props.dispatch(actions.userInfo())
    }
  }

  newAdvertAddedNotif(advert) {
    const options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {'Добавлено новое обновление ' + advert['headline']}
          </div>
        </div>
      ),
      type: "success",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3
    }
    this.refs.notify.notificationAlert(options);
  }

  newStoreAddedNotif(store) {
    const options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {'Добавлен новый магазин ' + path(['store_name', 'ru'], store)}
          </div>
        </div>
      ),
      type: "success",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3
    }
    this.refs.notify.notificationAlert(options)
  }

  componentDidMount() {
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    this.refs.mainContent.scrollTop = 0

    const roles = prop('roles', this.props)

    if(hasAnyRole(roles, [ADMIN, MODERATOR]) && this.state.subscribedToSocket === false) {
      const onMessageHandle = (message, type) => {
        if(type === 'onMessage') {
          const messageBody = message['body']
          const messageJson = JSON.parse(messageBody);
          const messageData = messageJson['data']
          const notificationType = messageJson['notification_type']

          if(notificationType === "AD_CREATED") {
            this.newAdvertAddedNotif(messageData)
          } else if(notificationType === "STORE_CREATED") {
            this.newStoreAddedNotif(messageData)
          }
        } else if(type === 'onConnect') {
          this.showNotification('Подключен к серверу', 'info')
        } else if(type === 'onStompError') {
          if(!this.state.socketConnectionErrorShown) {
            this.showNotification('Ошибка подключения к серверу...', 'danger')
            this.setState({socketConnectionErrorShown: true})
          }
        }
      }

      this.setState({subscribedToSocket: true})
      // this.props.dispatch(actions.socket(getToken()))
      this.props.dispatch(actions.socket(onMessageHandle))
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === route.ADMIN) {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    })
  }

  getBrandText = path => {
    const route = routes(this.props.roles)

    for (let i = 0; i < route.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          route[i].layout + route[i].path
        ) !== -1
      ) {
        return route[i].name;
      }
    }
    return "BARAKA"
  }

  showNotification = (text, type) => {
    const options = {
      place: 'tr',
      message: (
          <div>
            <div>
              { text}
            </div>
          </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3
    }
    if(this.refs.notify)
      this.refs.notify.notificationAlert(options);
  }

  render() {
    return (
      <Fragment>
        <NotificationAlert ref="notify" />
        
        <Sidebar
          {...this.props}
          routes={routes(this.props.roles)}
          logo={{
            innerLink: "/admin/dashboard",
            imgSrc: require("assets/img/icons/baraka_logo.svg"),
            imgAlt: "..."
          }}
        // logout={() => this.props.dispatch(profileLogout())}
        />
        
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          // logout={() => this.props.dispatch(profileLogout())}
          />
          
          <Switch>
            {this.getRoutes(routes(this.props.roles))}
          </Switch>
          
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </Fragment>
    )
  }
}

export default enhance(Admin)
