import { prop, equals } from 'ramda'
import axios from 'axios'
import { API_URL, SOCKET_URL, SOCKET_SUBSCRIPTION_URL } from '../constants/api'
import { getToken, removeToken } from './tokenStorage'
import { history } from '../redux/store'
import * as route from '../constants/routes'

export const NotAuthApi = () => {
  const instance = axios.create({
    baseURL: API_URL,
  })

  instance.interceptors.response.use(
    response => {
      return response
    },
    ({ response }) => {
      console.error(response)
      history.push(route.AUTH_LOGIN)
      return Promise.reject(response)
    }
  )

  return instance
}

export const AuthApi = () => {
  const TOKEN = getToken()
  //If there is no token in local storage then redirect to login pagesList
  if (TOKEN === null) {
    history.push(route.AUTH_LOGIN)
  }

  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: TOKEN ? `Bearer ${TOKEN}` : undefined,
    },
  })

  instance.interceptors.response.use(
    response => {
      return response
    },
    ({ response }) => {
      if (equals(prop('status', response), 401)) {
        removeToken()

        history.push(route.AUTH_LOGIN)
      }
      return Promise.reject(response)
    }
  )

  return instance
}

export const SocketApi = () => {
  return {
    url: SOCKET_URL,
    token: `Bearer ${getToken()}`,
    subscriptionUrl: SOCKET_SUBSCRIPTION_URL,
  }
}
