import { prop, dissoc } from 'ramda'
import { NotAuthApi, AuthApi } from '../helpers/httpClients'
import { REGION } from '../constants/api'

export const regionList = data => AuthApi().get(REGION + '/', data)

export const regionGet = data => AuthApi().get(`${REGION}/${prop('id', data)}`, dissoc('id', data))

export const regionCreate = data => AuthApi().post(REGION + '/', data)

export const regionUpdate = data => AuthApi().put(`${REGION}/${prop('id', data)}`, data)

export const regionDelete = data => AuthApi().delete(`${REGION}/${prop('id', data)}`)
