import {compose, map, path, prop} from 'ramda'
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from 'react-redux'
import Avatar from 'react-avatar';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import * as ROUTE from '../../constants/routes'
import {removeToken} from '../../helpers/tokenStorage'
import UpdateProfileModal from "../Modal/UpdateProfileModal";
import {updateUserPassword} from "../../redux/userInfo/actions";
import {submit} from "redux-form";

const enhance = compose(
  connect(({userInfo, form}) => {
    const info = path(['data'], userInfo)
    const formValues = path(['UpdateProfileForm', 'values'], form)
    return {
      info,
      formValues,
    }
  })
)

class AdminNavbar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      modalIsOpened: false
    }
  }


  handleLogout() {
    const {history, location} = this.props
    const pathname = prop('pathname', location)

    removeToken()

    return history.push({
      pathname: ROUTE.AUTH_LOGIN,
      state: {
        from: pathname,
      }
    })
  }


  handleModalOpen() {
    this.setState({modalIsOpened: true})
  }


  handleModalClose() {
    this.setState({modalIsOpened: false})
  }


  handleProfileUpdateSubmit() {
    const {formValues} = this.props

    this.props.dispatch(updateUserPassword({
      ...formValues,
      // closeModal: this.handleModalClose.bind(this)
    }))

    // this.handleModalClose()
  }

  handleProfileUpdateSuccess() {
    this.props.dispatch(submit('UpdateProfileForm'))
  }


  render() {
    const {info} = this.props

    const firstName = prop('firstname', info);
    const lastName = prop('lastname', info);
    const photo = prop('photo', info);


    return (
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {this.props.brandText}
          </Link>
          {/*<Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">*/}
          {/*  <FormGroup className="mb-0">*/}
          {/*    <InputGroup className="input-group-alternative">*/}
          {/*      <InputGroupAddon addonType="prepend">*/}
          {/*        <InputGroupText>*/}
          {/*          <i className="fas fa-search" />*/}
          {/*        </InputGroupText>*/}
          {/*      </InputGroupAddon>*/}
          {/*      <Input placeholder="Поиск" type="text" />*/}
          {/*    </InputGroup>*/}
          {/*  </FormGroup>*/}
          {/*</Form>*/}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      {/*<img*/}
                      {/*  alt="..."*/}
                      {/*  src={require("assets/img/theme/team-4-800x800.jpg")}*/}
                      {/*/>*/}
                      <Avatar src={prop('url', photo)} name={`${firstName} ${lastName}`} size="36" round={true}/>
                    </span>
                  <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {firstName}&nbsp;{lastName}
                      </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Добро пожаловать, {firstName}!</h6>
                </DropdownItem>
                {/*<DropdownItem to="/admin/user-profile" tag={Link}>*/}
                {/*  <i className="ni ni-single-02" />*/}
                {/*  <span>My profile</span>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem to="/admin/user-profile" tag={Link}>*/}
                {/*  <i className="ni ni-settings-gear-65" />*/}
                {/*  <span>Settings</span>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem to="/admin/user-profile" tag={Link}>*/}
                {/*  <i className="ni ni-calendar-grid-58" />*/}
                {/*  <span>Activity</span>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem to="/admin/user-profile" tag={Link}>*/}
                {/*  <i className="ni ni-support-16" />*/}
                {/*  <span>Support</span>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem divider />*/}
                <DropdownItem onClick={this.handleModalOpen.bind(this)}>
                  <i className="ni ni-lock-circle-open"/>
                  <span>Изменить пароль</span>
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem
                  href="?logout"
                  onClick={this.handleLogout.bind(this)}
                >
                  <i className="ni ni-user-run"/>
                  <span>Выйти</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <UpdateProfileModal
            modalIsOpened={this.state.modalIsOpened}
            setModalClose={this.handleModalClose.bind(this)}
            handleSubmit={this.handleProfileUpdateSubmit.bind(this)}
            handleSuccess={this.handleProfileUpdateSuccess.bind(this)}
          />
        </Container>
      </Navbar>
    );
  }
}

export default enhance(AdminNavbar);
