import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  rubricGet,
  rubricCreate,
  rubricUpdate,
  rubricDelete,
  rubricActivate,
} from '../../api/rubric'
import actionType, { RUBRIC, RUBRIC_CREATE, RUBRIC_DELETE, RUBRIC_ACTIVATE } from './actionTypes'
import * as route from '../../constants/routes'
import queryString from 'query-string'

function* rubricRequest() {
  yield takeEvery(actionType[RUBRIC].request, function* ({ payload }) {
    try {
      const { data } = yield call(rubricGet, payload)

      yield put({
        type: actionType[RUBRIC].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[RUBRIC].error,
        error,
      })
    }
  })
}

function* rubricCreateRequest() {
  yield takeEvery(actionType[RUBRIC_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? rubricUpdate : rubricCreate
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[RUBRIC_CREATE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[RUBRIC_CREATE].error,
        error,
      })
    }
  })
}

function* rubricCreateSuccess() {
  yield takeEvery(actionType[RUBRIC_CREATE].success, function* () {
    yield put(
      push({
        pathname: route.ADMIN_RUBRIC_LIST,
        search: queryString.stringify({
          listRefresh: true,
        }),
      })
    )
  })
}

function* rubricDeleteRequest() {
  yield takeEvery(actionType[RUBRIC_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(rubricDelete, payload)

      yield put({
        type: actionType[RUBRIC_DELETE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[RUBRIC_DELETE].error,
        error,
      })
    }
  })
}

function* rubricDeleteSuccess() {
  yield takeEvery(actionType[RUBRIC_DELETE].success, function* () {
    yield put(push(route.ADMIN_RUBRIC_LIST))
  })
}

function* rubricActivateRequest() {
  yield takeEvery(actionType[RUBRIC_ACTIVATE].request, function* ({ payload }) {
    try {
      const { data } = yield call(rubricActivate, payload)

      yield put({
        type: actionType[RUBRIC_ACTIVATE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[RUBRIC_ACTIVATE].error,
        error,
      })
    }
  })
}

function* rubricActivateSuccess() {
  yield takeEvery(actionType[RUBRIC_ACTIVATE].success, function* () {
    yield put(push(route.ADMIN_RUBRIC_LIST))
  })
}

export default function* rootSaga() {
  yield all([
    fork(rubricRequest),
    fork(rubricCreateRequest),
    fork(rubricCreateSuccess),
    fork(rubricDeleteRequest),
    fork(rubricDeleteSuccess),
    fork(rubricActivateRequest),
    fork(rubricActivateSuccess),
  ])
}
