import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Form, Button } from 'reactstrap'
import { InputField } from '../Form'

function validate(values) {
  const errors = {}

  if (!values.start_date) {
    errors.start_date = 'Start date is required!'
  }
  if (!values.end_date) {
    errors.end_date = 'End date is required!'
  }

  return errors
}

function ComplainFilterForm({ handleSubmit, onClear }) {
  return (
    <Form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'baseline',
        }}
      >
        <div className='mr-2'>
          <Field
            name='start_date'
            type='date'
            component={InputField}
            placeholder='create date'
            formGroupClassName='mb-0'
          />
        </div>

        <div className='mr-2'>
          <Field
            name='end_date'
            type='date'
            component={InputField}
            placeholder='end date'
            formGroupClassName='mb-0'
          />
        </div>

        <div className='mr-2'>
          <Button
            color='primary'
            onClick={handleSubmit}
          >
            Фильтр
          </Button>
        </div>

        <div>
          <Button
            color='danger'
            onClick={onClear}
          >
            Очистить фильтр
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default reduxForm({
  form: 'complainFilterForm',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ComplainFilterForm)
