import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { districtList } from '../../api/district'
import actionType, { DISTRICT_LIST } from './actionTypes'

function* districtListRequest() {
  yield takeEvery(actionType[DISTRICT_LIST].request, function* ({ payload }) {
    try {
      const { data } = yield call(districtList, payload)

      yield put({
        type: actionType[DISTRICT_LIST].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[DISTRICT_LIST].error,
        error,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(districtListRequest)])
}
