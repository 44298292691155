import { compose, path, prop, propOr, map, find, propEq } from 'ramda'
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap'
import Header from '../components/Headers/Header'
import CreateForm from '../components/Region/RegionCreateForm'
import * as route from '../constants/routes'
import * as actions from '../redux/actions'

const enhance = compose(
  connect(({ region, countryList, form, router }) => {
    const item = prop('data', region)
    const loading = prop('loading', region)
    const countryOptions = map(
      item => ({
        label: path(['name', 'ru'], item),
        value: prop('id', item),
      }),
      propOr([], 'data', countryList)
    )

    const formValues = path(['RegionForm', 'values'], form)

    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    return {
      item,
      loading,
      countryOptions,
      formValues,
      pathname,
      query,
    }
  })
)

function Region(props) {
  const { history, pathname, query, item, countryOptions, formValues } = props
  const id = prop('id', query)

  useEffect(() => {
    if (!id) {
      history.push({
        pathname: route.ADMIN_REGION_LIST,
        state: {
          from: pathname,
        },
      })
    }

    if (+id > 0) {
      props.dispatch(
        actions.region({
          id,
        })
      )
    }

    props.dispatch(actions.countryList())

    return () => {
      props.dispatch(actions.regionClear())
    }
  }, [])

  function handleSubmit() {
    props.dispatch(
      actions.regionCreate({
        id: +id > 0 ? id : undefined,
        ...formValues,
        country_id: path(['country_id', 'value'], formValues),
      })
    )
  }

  function handleSuccess() {
    props.dispatch(submit('RegionForm'))
  }

  function handleDelete() {
    props.dispatch(
      actions.regionDelete({
        id,
      })
    )
  }

  function handleBack() {
    history.push({
      pathname: route.ADMIN_REGION_LIST,
      state: {
        from: pathname,
      },
    })
  }

  const initialValues = {
    name: prop('name', item),
    country_id: find(propEq('value', prop('country_id', item)), countryOptions),
  }

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Область</h3>
                  </Col>
                  <Col xs='4' className='text-right'>
                    {+id > 0 && (
                      <Button size='sm' color='danger' onClick={handleDelete}>
                        Удалить
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <CreateForm
                  initialValues={initialValues}
                  countryOptions={countryOptions}
                  onSubmit={handleSubmit}
                />
              </CardBody>

              <CardFooter className='text-right'>
                <Button onClick={handleBack}>Назад</Button>

                <Button color='primary' onClick={handleSuccess}>
                  Сохранить
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default enhance(Region)
