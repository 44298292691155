import { map, addIndex } from 'ramda'
import React, { Fragment } from 'react'
import { Table } from 'reactstrap'

const indexedMap = addIndex(map)

function TableContent(props) {
  const { header, content, notResponsive } = props

  const head = indexedMap((item, index) => {
    const { title, style } = item

    return (
      <th key={index} scope='col' style={style}>
        {title}
      </th>
    )
  }, header)

  const div = props => <div {...props} className='align-items-center table-flush' />

  if (notResponsive) {
    return (
      <Fragment>
        <Table hover className='align-items-center table-flush'>
          <thead className='thead-light'>
            <tr>{head}</tr>
          </thead>

          <tbody>{content}</tbody>
        </Table>
      </Fragment>
    )
  }
  return (
    <Fragment>
      <Table hover responsive responsiveTag={div} className='align-items-center table-flush'>
        <thead className='thead-light'>
          <tr>{head}</tr>
        </thead>

        <tbody>{content}</tbody>
      </Table>
    </Fragment>
  )
}

TableContent.defaultProps = {
  header: [],
  content: [],
}

export default TableContent
