import React, { Fragment } from 'react'
import { FormGroup, FormText } from 'reactstrap'
import MaskedInput from 'react-text-mask'
import { isEmpty } from 'ramda'

const MaskedInputField = props => {
  const { input, meta, label, className, active, autoFocus } = props

  let { value, onChange, onBlur } = input
  const { touched, error } = meta
  value = isEmpty(value) ? '' : value
  return (
    <FormGroup>
      {label && <label>{label}</label>}
      <MaskedInput
        {...input}
        className={`form-control ${className}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        active={active}
        mask={[
          '(',
          '+',
          /[1-9]/,
          /\d/,
          /\d/,
          ')',
          ' ',
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        // mask={[
        //   '+',
        //   /[1-9]/,
        //   /[1-9]/,
        //   /[1-9]/,
        //   /[1-9]/,
        //   /[1-9]/,
        //   /[1-9]/,
        //   /[1-9]/,
        //   /[1-9]/,
        //   /[1-9]/,
        //   /[1-9]/,
        //   /[1-9]/,
        //   /[1-9]/,
        // ]}
        // placeholder='(___) __-___-____'
        autoFocus={autoFocus}
      />
      {touched && error && (
        <FormText color='primary'> Required </FormText>
        /*<FormText color='danger' >*/
        /*<IntlMessages id='form_required' />*/
        // </FormText>
      )}
    </FormGroup>
  )
}

export default MaskedInputField
