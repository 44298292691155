import createAction from '../../helpers/createAction'
import { CATEGORY, CATEGORY_CREATE, CATEGORY_DELETE, CATEGORY_ACTIVATE } from '../actionTypes'

export default {
  [CATEGORY]: createAction(CATEGORY),
  [CATEGORY_CREATE]: createAction(CATEGORY_CREATE),
  [CATEGORY_DELETE]: createAction(CATEGORY_DELETE),
  [CATEGORY_ACTIVATE]: createAction(CATEGORY_ACTIVATE),
}

export { CATEGORY, CATEGORY_CREATE, CATEGORY_DELETE, CATEGORY_ACTIVATE }
