import { css } from '@emotion/core'
import ContentLoader from 'react-content-loader'
import React from 'react'

import ClipLoader from 'react-spinners/ClipLoader'

function LoadingContent(props) {
  const { margin, minHeight, color, size } = props

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: ${color || '#24bc79'};
  `

  return (
    <div style={{ margin: margin || '16px', minHeight: minHeight }}>
      <ClipLoader css={override} size={size || 36} loading={true} />
    </div>
  )
}

export default LoadingContent
