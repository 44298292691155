import { compose, path, pathOr, prop } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import classnames from 'classnames'
import { Russia, UnitedKingdom, Uzbekistan } from 'react-flat-flags'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import Header from '../components/Headers/Header'
import CreateForm from '../components/Page/PageCreateForm'
import LoadingContent from '../components/Placeholder/LoadingContent'
import { ADMIN, MODERATOR } from '../constants/userRole'
import * as route from '../constants/routes'
import * as actions from '../redux/actions'
import { hasAnyRole, editorFieldConvertToHTML } from '../helpers/get'

const enhance = compose(
  connect(({ page, userInfo, form, router }) => {
    const item = prop('data', page)
    const loading = prop('loading', page)

    const formValues = path(['PageCreateForm', 'values'], form)
    const roles = pathOr([], ['data', 'roles'], userInfo)

    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    return {
      item,
      loading,
      formValues,
      roles,
      pathname,
      query,
    }
  })
)

function Page(props) {
  const { dispatch, history, pathname, loading, query, item, formValues, roles } = props

  const id = prop('id', query)

  useEffect(() => {
    if (!id) {
      history.push({
        pathname: route.ADMIN_PAGES_LIST,
        state: {
          from: pathname,
        },
      })
    }

    if (+id > 0) {
      dispatch(
        actions.page({
          id,
        })
      )
    }

    return () => {
      dispatch(actions.pageClear())
    }
  }, [])

  function handleSubmit() {
    let headerContent = editorFieldConvertToHTML(prop('header_content', formValues))
    if (headerContent.replace('<p></p>\n', '').length === 0) headerContent = ''

    let footerContent = editorFieldConvertToHTML(prop('footer_content', formValues))
    if (footerContent.replace('<p></p>\n', '').length === 0) footerContent = ''

    //Uz
    let headerContentUz = editorFieldConvertToHTML(prop('header_content_uz', formValues))
    if (headerContentUz.replace('<p></p>\n', '').length === 0) headerContentUz = ''

    let footerContentUz = editorFieldConvertToHTML(prop('footer_content_uz', formValues))
    if (footerContentUz.replace('<p></p>\n', '').length === 0) footerContentUz = ''

    //En
    let headerContentEn = editorFieldConvertToHTML(prop('header_content_en', formValues))
    if (headerContentEn.replace('<p></p>\n', '').length === 0) headerContentEn = ''

    let footerContentEn = editorFieldConvertToHTML(prop('footer_content_en', formValues))
    if (footerContentEn.replace('<p></p>\n', '').length === 0) footerContentEn = ''

    dispatch(
      actions.pageCreate({
        id: +id > 0 ? id : undefined,
        ...formValues,
        main_content: prop('main_content', formValues),
        header_content: headerContent,
        footer_content: footerContent,
        main_content_uz: prop('main_content_uz', formValues),
        header_content_uz: headerContentUz,
        footer_content_uz: footerContentUz,
        main_content_en: prop('main_content_en', formValues),
        header_content_en: headerContentEn,
        footer_content_en: footerContentEn,
        type: path(['type', 'value'], formValues),
      })
    )
  }

  function handleSuccess() {
    dispatch(submit('PageCreateForm'))
  }

  function handleDelete() {
    dispatch(
      actions.pageDelete({
        id,
      })
    )
  }

  function handleActivate() {
    dispatch(
      actions.pageActivate({
        id,
      })
    )
  }

  function handleBack() {
    history.push({
      pathname: route.ADMIN_PAGES_LIST,
      state: {
        from: pathname,
      },
    })
  }

  const title = prop('title', item)
  // const titleRu = prop('ru', title)
  const deleted = prop('deleted', item)
  // const key = prop('key', item)

  const initialValues = {
    title: prop('title', item),
    key: prop('key', item),
    main_content: prop('main_content', item),
    footer_content: prop('footer_content', item),
    header_content: prop('header_content', item),
    main_content_uz: prop('main_content_uz', item),
    footer_content_uz: prop('footer_content_uz', item),
    header_content_uz: prop('header_content_uz', item),
    main_content_en: prop('main_content_en', item),
    footer_content_en: prop('footer_content_en', item),
    header_content_en: prop('header_content_en', item),
    meta_title: prop('meta_title', item),
    meta_description: prop('meta_description', item),
    meta_keywords: prop('meta_keywords', item),
  }

  const toggleNavs = (e, index) => {
    e.preventDefault()
    setTabs(index)
  }

  const [tabs, setTabs] = useState(1)

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <div className='nav-wrapper'>
              <Nav
                className='nav-fill flex-column flex-md-row'
                id='tabs-icons-text'
                pills
                role='tablist'
              >
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames('mb-sm-3 mb-md-0', {
                      active: tabs === 1,
                    })}
                    onClick={e => toggleNavs(e, 1)}
                    role='tab'
                  >
                    <Russia size={18} style={{ marginRight: '8px' }} />
                    Русский
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    aria-selected={tabs === 2}
                    className={classnames('mb-sm-3 mb-md-0', {
                      active: tabs === 2,
                    })}
                    onClick={e => toggleNavs(e, 2)}
                    role='tab'
                  >
                    <Uzbekistan size={18} style={{ marginRight: '8px' }} />
                    Узбекский
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    aria-selected={tabs === 3}
                    className={classnames('mb-sm-3 mb-md-0', {
                      active: tabs === 3,
                    })}
                    onClick={e => toggleNavs(e, 3)}
                    role='tab'
                  >
                    <UnitedKingdom size={18} style={{ marginRight: '8px' }} />
                    Английский
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Страница проекта</h3>
                  </Col>

                  <Col xs='4' className='text-right'>
                    {+id > 0 && hasAnyRole(roles, [ADMIN, MODERATOR]) && !loading && (
                      <Button
                        size='sm'
                        color={deleted ? 'success' : 'danger'}
                        onClick={deleted ? handleActivate : handleDelete}
                      >
                        {deleted ? 'Восстановить' : 'Отключить'}
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                {loading ? (
                  <LoadingContent minHeight={'56px'} color={'#24bc79'} />
                ) : (
                  <CreateForm tabs={tabs} initialValues={initialValues} onSubmit={handleSubmit} />
                )}
              </CardBody>

              <CardFooter className='text-right'>
                <Button onClick={handleBack}>Назад</Button>

                {!loading && (
                  <Button color={+id > 0 ? 'primary' : 'success'} onClick={handleSuccess}>
                    {+id > 0 ? 'Сохранить' : 'Создать'}
                  </Button>
                )}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default enhance(Page)
