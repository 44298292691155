import { head, prop, propOr } from 'ramda'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FILE_UPLOAD_REACT_SINGLE_URL, NEW_FILE_UPLOAD_REACT_URL } from '../../constants/api'
import { getToken } from '../../helpers/tokenStorage'

function ImgInputField(props) {
  const dispatch = useDispatch()

  const { input, meta, className, setLoading } = props
  const { value, name } = input
  const { touched, error } = meta
  const [src, setSrc] = useState('')
  const [image, setImage] = useState(null)

  const onDelete = () => {
    dispatch(change('StoreCreateForm', name, null))
    setSrc('')
    setImage(null)
  }

  if (value && src === '') {
    if ((value || []).length > 0) {
      const item = head(value)
      if (item) {
        setImage(true)
        setSrc(prop('url', item))
      }
    }
  }

  async function uploadFiles(file) {
    const data = new FormData()

    data.append('files', file[0])

    fetch(NEW_FILE_UPLOAD_REACT_URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      body: data,
    }).then(
      function (res) {
        setLoading(false)
        if (res.ok) {
          res.json().then(jsonResponse => {
            const data = prop('data', jsonResponse)

            setSrc(propOr('', 'thumb', head(data)))
            setImage(data)
            dispatch(change('StoreCreateForm', name, [...data]))
          })
        } else {
          console.log('file error')
        }
      },
      function () {
        setLoading(false)
      }
    )
  }

  return (
    <Fragment>
      <label className={`position-relative ${className}`}>
        <input
          type='file'
          style={{
            display: 'none',
          }}
          onChange={e => {
            e.preventDefault()
            if (e.target.files.length > 0) {
              uploadFiles(e.target.files)
            }
          }}
        />

        <div
          style={{
            width: `5rem`,
            height: `5rem`,
            margin: '4px',
            cursor: !image && 'pointer',
            backgroundColor: '#f6f6f6',
            borderRadius: `0.25rem`,
            border: '1px solid #DEDEDF',
          }}
        >
          {image && (
            <img
              alt='...'
              style={{
                width: `5rem`,
                height: `5rem`,
                borderRadius: `0.25rem`,
                backgroundPosition: '50%',
                objectFit: 'cover',
                backgroundRepeat: 'no-repeat',
                verticalAlign: 'bottom',
              }}
              src={src}
              // src={ propOr('', 'url', nth(idx, propOr([],'photos', productFormValues))) }
            />
          )}

          {!image && (
            <FontAwesomeIcon
              icon={faPlus}
              color='dark'
              size='2x'
              style={{
                marginLeft: '1.5rem',
                marginTop: '1.5rem',
              }}
            />
          )}
        </div>

        {image && (
          <div
            style={{
              position: 'absolute',
              top: '-13px',
              left: `5rem`,
              cursor: 'pointer',
              width: `1.5rem`,
              borderRadius: `7rem`,
              backgroundColor: '#ff4641',
              height: `1.5rem`,
              alignText: 'center',
              verticalAlign: 'middle',
            }}
            onClick={e => {
              e.preventDefault()
              onDelete()
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              color='white'
              style={{
                marginLeft: '0.4em',
                marginTop: '0',
              }}
            />
          </div>
        )}
      </label>
    </Fragment>
  )
}

export default ImgInputField
