import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { cityGet, cityCreate, cityUpdate, cityDelete } from '../../api/city'
import actionType, { CITY, CITY_CREATE, CITY_DELETE } from './actionTypes'
import * as route from '../../constants/routes'

function* cityRequest() {
  yield takeEvery(actionType[CITY].request, function* ({ payload }) {
    try {
      const { data } = yield call(cityGet, payload)

      yield put({
        type: actionType[CITY].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[CITY].error,
        error,
      })
    }
  })
}

function* cityCreateRequest() {
  yield takeEvery(actionType[CITY_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? cityUpdate : cityCreate
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[CITY_CREATE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[CITY_CREATE].error,
        error,
      })
    }
  })
}

function* cityCreateSuccess() {
  yield takeEvery(actionType[CITY_CREATE].success, function* () {
    yield put(push(route.ADMIN_CITY_LIST))
  })
}

function* cityDeleteRequest() {
  yield takeEvery(actionType[CITY_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(cityDelete, payload)

      yield put({
        type: actionType[CITY_DELETE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[CITY_DELETE].error,
        error,
      })
    }
  })
}

function* cityDeleteSuccess() {
  yield takeEvery(actionType[CITY_DELETE].success, function* () {
    yield put(push(route.ADMIN_CITY_LIST))
  })
}

export default function* rootSaga() {
  yield all([
    fork(cityRequest),
    fork(cityCreateRequest),
    fork(cityCreateSuccess),
    fork(cityDeleteRequest),
    fork(cityDeleteSuccess),
  ])
}
