import { nth, prop, propOr } from 'ramda'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { Col, Container, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import { NEW_FILE_UPLOAD_REACT_URL } from '../../constants/api'
import { getToken } from '../../helpers/tokenStorage'

function MultipleFileInput(props) {
  const {
    input,
    accept,
    limit,
    type,
    item,
    setModalIsOpened,
    setUrl,
    setIndex,
    productFormValues,
  } = props

  const dispatch = useDispatch()
  const value = prop('value', input)
  const onChange = prop('onChange', input)
  const [loading, setLoading] = useState(false)

  const uploaderBoxSizeRem = 5
  const removeBtnSizeRem = 1.5
  const uploaderBorderRadius = 0.25
  const uploaderMargin = '4px'

  async function uploadFiles(files) {
    const data = new FormData()
    const filesList = Array.from(files).slice(0, maxImages)
    filesList.forEach(file => {
      data.append('files', file)
    })
    data.append('type', type)

    fetch(NEW_FILE_UPLOAD_REACT_URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      body: data,
    }).then(
      function (res) {
        setLoading(false)
        if (res.ok) {
          res.json().then(jsonResponse => {
            const data = prop('data', jsonResponse)

            if (!value || value.length === 0) {
              dispatch(change('ProductCreateForm', 'photos', data))
            } else {
              dispatch(change('ProductCreateForm', 'photos', [...value, ...data]))
            }
          })
        }
      },
      function (e) {
        setLoading(false)
      }
    )
  }

  const imageArray = createArrayLength(limit || 2)
  const maxImages = imageArray.length - (value || []).length

  const gridColumns = imageArray.map((position, idx) => {
    let image = value[position]

    return (
      <Col key={position}>
        <label>
          <input
            multiple={true}
            accept={accept}
            style={{
              display: 'none',
              zIndex: 9,
            }}
            type='file'
            onChange={e => {
              if (e.target.files.length > 0) {
                setLoading(true)
                uploadFiles(e.target.files)
              }
            }}
          />
          <div
            style={{
              width: `${uploaderBoxSizeRem}rem`,
              height: `${uploaderBoxSizeRem}rem`,
              margin: uploaderMargin,
              cursor: !image && 'pointer',
              backgroundColor: '#f6f6f6',
              borderRadius: `${uploaderBorderRadius}rem`,
              border: '1px solid #DEDEDF',
            }}
          >
            {image && (
              <div
                style={{
                  width: `${uploaderBoxSizeRem}rem`,
                  height: `${uploaderBoxSizeRem}rem`,
                  borderRadius: `${uploaderBorderRadius}rem`,
                  verticalAlign: 'bottom',
                  backgroundImage: `url(${propOr(
                    '',
                    'url',
                    nth(idx, propOr([], 'photos', productFormValues))
                  )})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: 'transparent',
                  backgroundSize: '100%',
                }}
              />
            )}
            {image && (
              <FontAwesomeIcon
                icon={faEdit}
                color='black'
                onClick={e => {
                  e.preventDefault()
                  setModalIsOpened(true)
                  // setUrl(nth(idx, propOr([], 'photos', item)))
                  setUrl(propOr('', 'url', nth(idx, propOr([], 'photos', productFormValues))))
                  setIndex(idx)
                }}
                style={{
                  position: 'absolute',
                  top: '-4px',
                  right: `${uploaderBoxSizeRem + removeBtnSizeRem / 2.4}rem`,
                  width: `${removeBtnSizeRem}rem`,
                  height: `${removeBtnSizeRem}rem`,
                  borderRadius: `${removeBtnSizeRem / 2}rem`,
                  marginLeft: '0.4em',
                  marginTop: '0',
                  cursor: 'pointer',
                }}
              />
            )}
            {image && (
              <div
                style={{
                  position: 'absolute',
                  top: '-4px',
                  left: `${uploaderBoxSizeRem + removeBtnSizeRem / 2.4}rem`,
                  cursor: 'pointer',
                  width: `${removeBtnSizeRem}rem`,
                  borderRadius: `${removeBtnSizeRem / 2}rem`,
                  backgroundColor: '#ff4641',
                  height: `${removeBtnSizeRem}rem`,
                  alignText: 'center',
                  verticalAlign: 'middle',
                }}
                onClick={event => {
                  event.preventDefault()
                  const newInput = [...value]
                  newInput.splice(position, 1)
                  onChange(newInput)
                }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  color='white'
                  style={{
                    marginLeft: '0.4em',
                    marginTop: '0',
                  }}
                />
              </div>
            )}
            {!image && (
              <div
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  top: `${uploaderBoxSizeRem / 2 - uploaderBorderRadius * 4.5}rem`,
                  left: `${uploaderBoxSizeRem / 2 - uploaderBorderRadius * 1.5}rem`,
                  width: `${removeBtnSizeRem * 2}rem`,
                  height: `${removeBtnSizeRem * 2}rem`,
                  borderRadius: `${removeBtnSizeRem}rem`,
                  backgroundColor: 'rgba(56,188,91,0.65)',
                  alignText: 'center',
                }}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  color='white'
                  size='2x'
                  style={{
                    top: '8px',
                    left: '10px',
                    position: 'absolute',
                  }}
                />
              </div>
            )}
          </div>
        </label>
      </Col>
    )
  })

  return (
    <div style={{ display: 'block' }}>
      {/*{Boolean(value.length) && (*/}
      {/*    <div>Selected files: {value.map(item => item['url']).join(", ")}</div>*/}
      {/*)}*/}
      {/*<label>*/}

      <Container style={{ marginBottom: '0.25rem', marginTop: '0.25rem' }}>
        <Row xs='4'>{gridColumns}</Row>
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              left: '0px',
              right: '0px',
              zIndex: 1000,
            }}
            onClick={event => {
              event.preventDefault()
            }}
          >
            <div style={{ backgroundColor: 'rgba(135,135,135,0.31)' }}>
              <p style={{ color: '#000' }}>Загрузка изображений...</p>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        )}
      </Container>

      {/*</label>*/}
    </div>
  )
}

function createArrayLength(length) {
  let arr = []
  for (let i = 0; i < length; i++) {
    arr.push(i)
  }
  return arr
}

export default MultipleFileInput
