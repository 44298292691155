import { prop, length } from 'ramda'
import React from 'react'
import { Row, Col, Modal, ModalBody, ModalFooter, Button, Card, CardBody } from 'reactstrap'

function ComplainModal(props) {
  const { open, close, complain } = props
  const ad = prop('ad', complain)
  const owner = prop('ad_owner', complain)
  const user = prop('user', complain)
  const type = prop('type', complain)
  const description = prop('description', complain)

  const adHeadline = prop('headline', ad)
  const adDescription = prop('description', ad)
  const adStatus = prop('status', ad)
  const adRubricTitle = prop('rubric_title', ad)
  const adCategoryTitle = prop('category_title', ad)
  const adSubcategoryTitle = prop('sub_category_title', ad)

  const ownerFirstName = prop('first_name', owner)
  const ownerLastName = prop('last_name', owner)
  const ownerPhone = prop('phone', owner)

  const userFirstName = prop('first_name', user)
  const userLastName = prop('last_name', user)
  const userPhone = prop('phone', user)

  return (
    <Modal isOpen={open} toggle={close}>
      <ModalBody style={{ padding: '10px' }}>
        <h3>Объявление</h3>
        <Card>
          <CardBody>
            <Row>
              <Col sm={6}>
                <b>Название</b>
                <div style={{ paddingLeft: '10px' }}>{adHeadline}</div>
                <b>Описание</b>
                <div style={{ paddingLeft: '10px' }}>
                  {length(adDescription) > 50
                    ? `${adDescription.substring(0, 50)}...`
                    : adDescription}
                </div>
                <b>Состояние</b>
                <div style={{ paddingLeft: '10px' }}>{adStatus}</div>
                <b>Автор</b>
                <div style={{ paddingLeft: '10px' }}>
                  {ownerFirstName} {ownerLastName}
                </div>
              </Col>

              <Col sm={6}>
                <b>Рубрика</b>
                <div style={{ paddingLeft: '10px' }}>{adRubricTitle}</div>
                <b>Kатегория</b>
                <div style={{ paddingLeft: '10px' }}>{adCategoryTitle}</div>
                <b>Подкатегория</b>
                <div style={{ paddingLeft: '10px' }}>{adSubcategoryTitle}</div>
                <b>Телефон</b>
                <div style={{ paddingLeft: '10px' }}>+{ownerPhone}</div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <h4 style={{ marginTop: '10px' }}>Жалоба</h4>
        <Card>
          <CardBody>
            <Row>
              <Col sm={6}>
                <b>Автор</b>
                <div style={{ paddingLeft: '10px' }}>
                  {userFirstName} {userLastName}
                </div>
                <b>Тип</b>
                <div style={{ paddingLeft: '10px' }}>{type}</div>
              </Col>

              <Col sm={6}>
                <b>Phone</b>
                <div style={{ paddingLeft: '10px' }}>{userPhone && `+${userPhone}`}</div>
              </Col>

              <Col>
                <b>Описание жалобы</b>
                <div style={{ paddingLeft: '10px' }}>{description}</div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>

      <ModalFooter>
        <Button color='danger' onClick={close}>
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ComplainModal
