import { prop, dissoc } from 'ramda'
import { AuthApi } from '../helpers/httpClients'
import { ADVERT } from '../constants/api'

export const getAll = data => {
  const status = prop('status', data) ? `&status=${prop('status', data)}` : ''
  const search = prop('search', data) ? `&search=${prop('search', data)}` : ''

  if (status)
    return AuthApi().get(
      ADVERT + `/all?page=${prop('page', data)}&size=10${search}${status}`,
      data
    )
  else
    return AuthApi().get(ADVERT + `/all?page=${prop('page', data)}&size=10${search}`, data)
}

//Without deleted ones
// export const getAll= data => AuthApi()
//   .get(ADVERT + '/', data)

export const getOne = data => AuthApi().get(`${ADVERT}/${prop('id', data)}`, dissoc('id', data))

export const createOne = data => AuthApi().post(ADVERT + '/', data)

export const updateOne = data => AuthApi().post(`${ADVERT}/`, data)

export const deleteOne = data => AuthApi().delete(`${ADVERT}/${prop('id', data)}`)

export const activateOne = data => AuthApi().put(`${ADVERT}/activate/${prop('id', data)}`)

export const updateStatus = data =>
  AuthApi().put(`${ADVERT}/update/status?id=${prop('id', data)}&status=${prop('status', data)}`)
