import { pipe, prop, length, map, find, propEq, dropLast, prepend, equals } from 'ramda'
import { all, fork, takeEvery, put, call, select } from 'redux-saga/effects'
import { getAll } from '../../api/store'
import actionType, { STORES_LIST, STORES_LIST_GET, STORES_LIST_NOTIFICATION } from './actionTypes'
import * as actions from '../actions'

function* storeListRequest() {
  yield takeEvery(actionType[STORES_LIST].request, function* ({ payload }) {
    try {
      yield put({
        type: actionType[STORES_LIST].success,
        payload,
      })
    } catch (error) {
      yield put({
        type: actionType[STORES_LIST].error,
        error,
      })
    }
  })
}

function* storeListGetRequest() {
  yield takeEvery(actionType[STORES_LIST_GET].request, function* ({ payload }) {
    try {
      const { data } = yield call(getAll, payload)

      yield put({
        type: actionType[STORES_LIST_GET].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[STORES_LIST_GET].error,
        error,
      })
    }
  })
}

function* storeListGetSuccess() {
  yield takeEvery(actionType[STORES_LIST_GET].success, function* ({ payload }) {
    yield put(actions.storesListForSaga(payload))
  })
}

function* storeListNotificationRequest() {
  yield takeEvery(actionType[STORES_LIST_NOTIFICATION].request, function* ({ payload }) {
    try {
      const {
        storesList: { data },
      } = yield select()
      const list = prop('content', data)
      const listLength = length(list)

      const id = prop('id', payload)
      const inList = find(propEq('id', id), list)

      const addToList = pipe(dropLast(1), prepend(payload))(list)

      const dynamicAddToList = listLength < 5 ? [payload, ...list] : addToList

      const replaceItemToList = pipe(
        map(item => {
          if (equals(prop('id', item), id)) {
            return {
              ...payload,
            }
          }
          return item
        })
      )(list)

      const newContent = inList ? replaceItemToList : dynamicAddToList

      yield put({
        type: actionType[STORES_LIST_NOTIFICATION].success,
        payload: {
          ...data,
          content: newContent,
        },
      })
    } catch (error) {
      yield put({
        type: actionType[STORES_LIST_NOTIFICATION].error,
        error,
      })
    }
  })
}

function* storeListNotificationSuccess() {
  yield takeEvery(actionType[STORES_LIST_NOTIFICATION].success, function* ({ payload }) {
    yield put(actions.storesListForSaga(payload))
  })
}

export default function* rootSaga() {
  yield all([
    fork(storeListRequest),
    fork(storeListGetRequest),
    fork(storeListGetSuccess),
    fork(storeListNotificationRequest),
    fork(storeListNotificationSuccess),
  ])
}
