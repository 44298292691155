import { prop } from 'ramda'
import { AuthApi } from '../helpers/httpClients'
import { SUBCATEGORY_FIELD } from '../constants/api'

export const createOne = data => AuthApi().post(SUBCATEGORY_FIELD + '/', data)

export const copyFromSubcategory = data =>
  AuthApi().post(
    `${SUBCATEGORY_FIELD}/copy?from_id=${prop('from_id', data)}&to_id=${prop('to_id', data)}`,
    data
  )

export const updateOne = data => AuthApi().put(`${SUBCATEGORY_FIELD}/${prop('id', data)}`, data)

export const deleteOne = data =>
  AuthApi().delete(
    `${SUBCATEGORY_FIELD}?id=${prop('id', data)}&subcategory_id=${prop('subcategory_id', data)}`
  )
