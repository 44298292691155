import createAction from '../../helpers/createAction'
import { ADVERTS_LIST, ADVERTS_LIST_GET, ADVERTS_LIST_NOTIFICATION } from '../actionTypes'

export default {
  [ADVERTS_LIST]: createAction(ADVERTS_LIST),
  [ADVERTS_LIST_GET]: createAction(ADVERTS_LIST_GET),
  [ADVERTS_LIST_NOTIFICATION]: createAction(ADVERTS_LIST_NOTIFICATION),
}

export { ADVERTS_LIST, ADVERTS_LIST_GET, ADVERTS_LIST_NOTIFICATION }
