import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { getOne, updateStatus, deleteOne, activateOne, createOne } from '../../api/store'
import actionType, { STORE, STORE_UPDATE_STATUS, STORE_DELETE, STORE_ACTIVATE } from './actionTypes'
import * as route from '../../constants/routes'
import { STORE_CREATE } from '../actionTypes'

function* getOneRequest() {
  yield takeEvery(actionType[STORE].request, function* ({ payload }) {
    try {
      const { data } = yield call(getOne, payload)

      yield put({
        type: actionType[STORE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[STORE].error,
        error,
      })
    }
  })
}

function* updateStatusRequest() {
  yield takeEvery(actionType[STORE_UPDATE_STATUS].request, function* ({ payload }) {
    try {
      const { data } = yield call(updateStatus, payload)

      yield put({
        type: actionType[STORE_UPDATE_STATUS].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[STORE_UPDATE_STATUS].error,
        error,
      })
    }
  })
}

function* updateStatusSuccess() {
  yield takeEvery(actionType[STORE_UPDATE_STATUS].success, function* ({ args }) {
    yield put(push(route.ADMIN_STORES_LIST, { updated: true, data: args }))
  })
}

function* updateRequest() {
  yield takeEvery(actionType[STORE_CREATE].request, function* ({ payload }) {
    try {
      const { data } = yield call(createOne, payload)

      yield put({
        type: actionType[STORE_CREATE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[STORE_CREATE].error,
        error,
      })
    }
  })
}

function* updateSuccess() {
  yield takeEvery(actionType[STORE_CREATE].success, function* ({ args }) {
    yield put(push(route.ADMIN_STORES_LIST, { data: args }))
  })
}

function* deleteOneRequest() {
  yield takeEvery(actionType[STORE_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(deleteOne, payload)

      yield put({
        type: actionType[STORE_DELETE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[STORE_DELETE].error,
        error,
      })
    }
  })
}

function* deleteOneSuccess() {
  yield takeEvery(actionType[STORE_DELETE].success, function* () {
    yield put(push(route.ADMIN_STORES_LIST))
  })
}

function* activateOneRequest() {
  yield takeEvery(actionType[STORE_ACTIVATE].request, function* ({ payload }) {
    try {
      const { data } = yield call(activateOne, payload)

      yield put({
        type: actionType[STORE_ACTIVATE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[STORE_ACTIVATE].error,
        error,
      })
    }
  })
}

function* activateOneSuccess() {
  yield takeEvery(actionType[STORE_ACTIVATE].success, function* () {
    yield put(push(route.ADMIN_STORES_LIST))
  })
}

export default function* rootSaga() {
  yield all([
    fork(getOneRequest),
    fork(updateRequest),
    fork(updateSuccess),
    fork(updateStatusRequest),
    fork(updateStatusSuccess),
    fork(deleteOneRequest),
    fork(deleteOneSuccess),
    fork(activateOneRequest),
    fork(activateOneSuccess),
  ])
}
