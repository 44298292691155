import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { cityList } from '../../api/city'
import actionType, { CITY_LIST } from './actionTypes'

function* cityListRequest() {
  yield takeEvery(actionType[CITY_LIST].request, function* ({ payload }) {
    try {
      const { data } = yield call(cityList, payload)

      yield put({
        type: actionType[CITY_LIST].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[CITY_LIST].error,
        error,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(cityListRequest)])
}
