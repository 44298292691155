import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  subcategoryGet,
  subcategoryCreate,
  subcategoryUpdate,
  subcategoryDelete,
  subcategoryActivate,
} from '../../api/subcategory'
import actionType, {
  SUBCATEGORY,
  SUBCATEGORY_CREATE,
  SUBCATEGORY_DELETE,
  SUBCATEGORY_ACTIVATE,
} from './actionTypes'
import * as route from '../../constants/routes'
import queryString from 'query-string'

function* subcategoryRequest() {
  yield takeEvery(actionType[SUBCATEGORY].request, function* ({ payload }) {
    try {
      const { data } = yield call(subcategoryGet, payload)

      yield put({
        type: actionType[SUBCATEGORY].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[SUBCATEGORY].error,
        error,
      })
    }
  })
}

function* subcategoryCreateRequest() {
  yield takeEvery(actionType[SUBCATEGORY_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? subcategoryUpdate : subcategoryCreate
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[SUBCATEGORY_CREATE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[SUBCATEGORY_CREATE].error,
        error,
      })
    }
  })
}

function* subcategoryCreateSuccess() {
  yield takeEvery(actionType[SUBCATEGORY_CREATE].success, function* ({ args }) {
    const parent_id = prop('category_id', args)
    yield put(
      push({
        pathname: route.ADMIN_CATEGORY,
        search: queryString.stringify({
          id: parent_id,
          categoryRefresh: true,
        }),
      })
    )
  })
}

function* subcategoryDeleteRequest() {
  yield takeEvery(actionType[SUBCATEGORY_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(subcategoryDelete, payload)

      yield put({
        type: actionType[SUBCATEGORY_DELETE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[SUBCATEGORY_DELETE].error,
        error,
      })
    }
  })
}

function* subcategoryDeleteSuccess() {
  yield takeEvery(actionType[SUBCATEGORY_DELETE].success, function* ({ args }) {
    const categoryId = prop('category_id', args)
    yield put(
      push({
        pathname: route.ADMIN_CATEGORY,
        search: queryString.stringify({
          id: categoryId,
          categoryRefresh: true,
        }),
      })
    )
  })
}

function* subcategoryActivateRequest() {
  yield takeEvery(actionType[SUBCATEGORY_ACTIVATE].request, function* ({ payload }) {
    try {
      const { data } = yield call(subcategoryActivate, payload)

      yield put({
        type: actionType[SUBCATEGORY_ACTIVATE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[SUBCATEGORY_ACTIVATE].error,
        error,
      })
    }
  })
}

function* subcategoryActivateSuccess() {
  yield takeEvery(actionType[SUBCATEGORY_ACTIVATE].success, function* ({ args }) {
    const categoryId = prop('category_id', args)
    yield put(
      push({
        pathname: route.ADMIN_CATEGORY,
        search: queryString.stringify({
          id: categoryId,
          categoryRefresh: true,
        }),
      })
    )
  })
}

export default function* rootSaga() {
  yield all([
    fork(subcategoryRequest),
    fork(subcategoryCreateRequest),
    fork(subcategoryCreateSuccess),
    fork(subcategoryDeleteRequest),
    fork(subcategoryDeleteSuccess),
    fork(subcategoryActivateRequest),
    fork(subcategoryActivateSuccess),
  ])
}
