import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import {
  categoryGet,
  categoryCreate,
  categoryUpdate,
  categoryDelete,
  categoryActivate,
} from '../../api/category'
import actionType, {
  CATEGORY,
  CATEGORY_CREATE,
  CATEGORY_DELETE,
  CATEGORY_ACTIVATE,
} from './actionTypes'
import * as route from '../../constants/routes'

function* categoryRequest() {
  yield takeEvery(actionType[CATEGORY].request, function* ({ payload }) {
    try {
      const { data } = yield call(categoryGet, payload)

      yield put({
        type: actionType[CATEGORY].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[CATEGORY].error,
        error,
      })
    }
  })
}

function* categoryCreateRequest() {
  yield takeEvery(actionType[CATEGORY_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? categoryUpdate : categoryCreate
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[CATEGORY_CREATE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[CATEGORY_CREATE].error,
        error,
      })
    }
  })
}

function* categoryCreateSuccess() {
  yield takeEvery(actionType[CATEGORY_CREATE].success, function* ({ args }) {
    const rubricId = prop('rubric_id', args)

    yield put(
      push({
        pathname: route.ADMIN_RUBRIC,
        search: queryString.stringify({
          id: rubricId,
        }),
      })
    )
  })
}

function* categoryDeleteRequest() {
  yield takeEvery(actionType[CATEGORY_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(categoryDelete, payload)

      yield put({
        type: actionType[CATEGORY_DELETE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[CATEGORY_DELETE].error,
        error,
      })
    }
  })
}

function* categoryDeleteSuccess() {
  yield takeEvery(actionType[CATEGORY_DELETE].success, function* ({ args }) {
    const rubricId = prop('rubricId', args)

    yield put(
      push({
        pathname: route.ADMIN_RUBRIC,
        search: queryString.stringify({
          id: rubricId,
        }),
      })
    )
  })
}

function* categoryActivateRequest() {
  yield takeEvery(actionType[CATEGORY_ACTIVATE].request, function* ({ payload }) {
    try {
      const { data } = yield call(categoryActivate, payload)

      yield put({
        type: actionType[CATEGORY_ACTIVATE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[CATEGORY_ACTIVATE].error,
        error,
      })
    }
  })
}

function* categoryActivateSuccess() {
  yield takeEvery(actionType[CATEGORY_ACTIVATE].success, function* ({ args }) {
    const rubricId = prop('rubricId', args)

    yield put(
      push({
        pathname: route.ADMIN_RUBRIC,
        search: queryString.stringify({
          id: rubricId,
        }),
      })
    )
  })
}

export default function* rootSaga() {
  yield all([
    fork(categoryRequest),
    fork(categoryCreateRequest),
    fork(categoryCreateSuccess),
    fork(categoryDeleteRequest),
    fork(categoryDeleteSuccess),
    fork(categoryActivateRequest),
    fork(categoryActivateSuccess),
  ])
}
