import actionType, { PAGE_CATEGORY_LIST } from './actionTypes'

export const pageCategoryList = payload => ({
  type: actionType[PAGE_CATEGORY_LIST].request,
  payload,
})

export const pageCategoryListClear = payload => ({
  type: actionType[PAGE_CATEGORY_LIST].clear,
  payload,
})
