import React, { Fragment } from 'react'
import { reduxForm, Field, FormSection } from 'redux-form'
import { Form, Row, Col } from 'reactstrap'
import { InputField } from '../Form'

function CountryCreateForm(props) {
  const { handleSubmit } = props

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <div className='pl-lg-0'>
          <FormSection name='name'>
            <Row>
              <Col>
                <Field
                  name='ru'
                  component={InputField}
                  placeholder='название на русском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название на русском
                    </label>
                  }
                />
              </Col>

              <Col>
                <Field
                  name='uz'
                  component={InputField}
                  placeholder='название на русском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название на узбекском
                    </label>
                  }
                />
              </Col>

              <Col>
                <Field
                  name='en'
                  component={InputField}
                  placeholder='название на русском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название на английском
                    </label>
                  }
                />
              </Col>
            </Row>
          </FormSection>

          <Row>
            <Col lg='4'>
              <Field
                name='country_code'
                component={InputField}
                placeholder='пример: UZ'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Код страны
                  </label>
                }
              />
            </Col>

            <Col lg='4'>
              <Field
                name='phone_code'
                component={InputField}
                onKeyPress={event => {
                  const isValid = event.charCode >= 48 && event.charCode <= 57 // Only numbers
                  if (!isValid) event.preventDefault()
                }}
                placeholder='пример: 998'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Телефонный код
                  </label>
                }
              />
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'CountryForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(CountryCreateForm)
