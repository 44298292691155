import createAction from '../../helpers/createAction'
import {
  ADVERT,
  ADVERT_DELETE,
  ADVERT_ACTIVATE,
  ADVERT_UPDATE_STATUS,
  ADVERT_CREATE,
} from '../actionTypes'

export default {
  [ADVERT]: createAction(ADVERT),
  [ADVERT_DELETE]: createAction(ADVERT_DELETE),
  [ADVERT_ACTIVATE]: createAction(ADVERT_ACTIVATE),
  [ADVERT_UPDATE_STATUS]: createAction(ADVERT_UPDATE_STATUS),
  [ADVERT_CREATE]: createAction(ADVERT_CREATE),
}

export { ADVERT, ADVERT_DELETE, ADVERT_ACTIVATE, ADVERT_UPDATE_STATUS, ADVERT_CREATE }
