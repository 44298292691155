import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { regionGet, regionCreate, regionUpdate, regionDelete } from '../../api/region'
import actionType, { REGION, REGION_CREATE, REGION_DELETE } from './actionTypes'
import * as route from '../../constants/routes'

function* regionRequest() {
  yield takeEvery(actionType[REGION].request, function* ({ payload }) {
    try {
      const { data } = yield call(regionGet, payload)

      yield put({
        type: actionType[REGION].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[REGION].error,
        error,
      })
    }
  })
}

function* regionCreateRequest() {
  yield takeEvery(actionType[REGION_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? regionUpdate : regionCreate
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[REGION_CREATE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[REGION_CREATE].error,
        error,
      })
    }
  })
}

function* regionCreateSuccess() {
  yield takeEvery(actionType[REGION_CREATE].success, function* () {
    yield put(push(route.ADMIN_REGION_LIST))
  })
}

function* regionDeleteRequest() {
  yield takeEvery(actionType[REGION_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(regionDelete, payload)

      yield put({
        type: actionType[REGION_DELETE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[REGION_DELETE].error,
        error,
      })
    }
  })
}

function* regionDeleteSuccess() {
  yield takeEvery(actionType[REGION_DELETE].success, function* () {
    yield put(push(route.ADMIN_REGION_LIST))
  })
}

export default function* rootSaga() {
  yield all([
    fork(regionRequest),
    fork(regionCreateRequest),
    fork(regionCreateSuccess),
    fork(regionDeleteRequest),
    fork(regionDeleteSuccess),
  ])
}
