import Index from 'views/Index.jsx'
// import Profile from "views/examples/Profile.jsx";
// import Maps from "views/examples/Maps.jsx";
import Register from 'views/examples/Register.jsx'
import Login from 'views/examples/Login.jsx'
// import Tables from "views/examples/Tables.jsx";
// import Icons from "views/examples/Icons.jsx";
import CountryList from './views/country-list'
import Country from './views/country'
import RegionList from './views/region-list'
import Region from './views/region'
import CityList from './views/city-list'
import City from './views/city'
import DistrictList from './views/district-list'
import District from './views/district'
import RubricList from './views/rubric-list'
import PageCategoryList from './views/page-category-list'
import * as route from './constants/routes'
import Rubric from './views/rubric'
import Category from './views/category'
import Page from './views/page'
import PagesList from './views/pages-list'
import ProductsList from './views/products-list'
import StoresList from './views/stores-list'
import Subcategory from './views/subcategory'
import PageCategory from './views/page-category'
import ComplainList from './views/complain-list'
import UsersList from './views/users-list'
import { ADMIN, MODERATOR, SEO_MODERATOR } from './constants/userRole'
import { hasAnyRole } from './helpers/get'

const routes = roles => [
  {
    path: route.ADMIN_DASHBOARD,
    name: 'Главная',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: route.ADMIN,
    show: true,
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  //   show: true,
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  //   show: true,
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  //   show: true,
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  //   show: true,
  // },
  {
    path: route.AUTH_LOGIN,
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: route.AUTH,
    show: false,
  },
  {
    path: route.AUTH_REGISTER,
    name: 'Register',
    icon: 'ni ni-circle-08 text-pink',
    component: Register,
    layout: route.AUTH,
    show: false,
  },
  {
    path: route.ADMIN_PRODUCTS_LIST,
    name: 'Объявления',
    icon: 'ni ni-single-copy-04 text-blue',
    component: ProductsList,
    layout: route.ADMIN,
    show: hasAnyRole(roles, [ADMIN, MODERATOR]),
  },
  {
    path: route.ADMIN_STORES_LIST,
    name: 'Магазины',
    icon: 'ni ni-shop text-orange',
    component: StoresList,
    layout: route.ADMIN,
    show: hasAnyRole(roles, [ADMIN, MODERATOR]),
  },
  {
    path: route.ADMIN_RUBRIC_LIST,
    name: 'Рубрики',
    icon: 'ni ni-bullet-list-67 text-purple',
    component: RubricList,
    layout: route.ADMIN,
    show: hasAnyRole(roles, [SEO_MODERATOR, ADMIN, MODERATOR]),
  },
  {
    path: route.ADMIN_USERS_LIST,
    name: 'Список пользователей',
    icon: 'ni ni-circle-08 text-blue',
    component: UsersList,
    layout: route.ADMIN,
    show: hasAnyRole(roles, [ADMIN, MODERATOR]),
  },
  {
    path: route.ADMIN_PAGE_CATEGORY_LIST,
    name: 'Категории страниц',
    icon: 'ni ni-ungroup text-red',
    component: PageCategoryList,
    layout: route.ADMIN,
    show: hasAnyRole(roles, [ADMIN, MODERATOR]),
  },
  {
    path: route.ADMIN_PAGE_CATEGORY,
    name: 'Категории страниц',
    icon: 'ni ni-ungroup text-red',
    component: PageCategory,
    layout: route.ADMIN,
    show: false,
  },
  {
    path: route.ADMIN_PAGES_LIST,
    name: 'Страницы проекта',
    icon: 'ni ni-html5 text-yellow',
    component: PagesList,
    layout: route.ADMIN,
    show: hasAnyRole(roles, [ADMIN, MODERATOR, SEO_MODERATOR]),
  },

  {
    path: route.ADMIN_RUBRIC,
    name: 'Рубрика',
    icon: 'ni ni-left-2 text-yellow',
    component: Rubric,
    layout: route.ADMIN,
    show: false,
  },
  {
    path: route.ADMIN_CATEGORY,
    name: 'Категория',
    icon: 'ni ni-book-bookmark text-yellow',
    component: Category,
    layout: route.ADMIN,
    show: false,
  },
  {
    path: route.ADMIN_SUBCATEGORY,
    name: 'Подкатегория',
    icon: 'ni ni-left-2 text-yellow',
    component: Subcategory,
    layout: route.ADMIN,
    show: false,
  },
  {
    path: route.ADMIN_PAGE,
    name: 'Страница',
    icon: 'ni ni-left-2 text-yellow',
    component: Page,
    layout: route.ADMIN,
    show: false,
  },
  {
    path: route.ADMIN_COMPLAIN_LIST,
    name: 'Список жалоб',
    icon: 'ni ni-book-bookmark text-yellow',
    component: ComplainList,
    layout: route.ADMIN,
    show: true,
  },

  // Регионы
  {
    path: route.ADMIN_COUNTRY_LIST,
    name: 'Страны',
    icon: 'ni ni-world text-green',
    component: CountryList,
    layout: route.ADMIN,
    show: hasAnyRole(roles, [ADMIN, MODERATOR]),
    divider: true,
    divider_text: 'Регионы',
  },
  {
    path: route.ADMIN_COUNTRY,
    name: 'Страна',
    icon: 'ni ni-world text-green',
    component: Country,
    layout: route.ADMIN,
    show: false,
  },
  {
    path: route.ADMIN_REGION_LIST,
    name: 'Области',
    icon: 'ni ni-map-big text-green',
    component: RegionList,
    layout: route.ADMIN,
    show: hasAnyRole(roles, [ADMIN, MODERATOR]),
  },
  {
    path: route.ADMIN_REGION,
    name: 'Область',
    icon: 'ni ni-world-2 text-yellow',
    component: Region,
    layout: route.ADMIN,
    show: false,
  },
  {
    path: route.ADMIN_CITY_LIST,
    name: 'Города',
    icon: 'ni ni-istanbul text-green',
    component: CityList,
    layout: route.ADMIN,
    show: hasAnyRole(roles, [ADMIN, MODERATOR]),
  },
  {
    path: route.ADMIN_CITY,
    name: 'Город',
    icon: 'ni ni-book-bookmark text-yellow',
    component: City,
    layout: route.ADMIN,
    show: false,
  },
  {
    path: route.ADMIN_DISTRICT_LIST,
    name: 'Районы',
    icon: 'ni ni-square-pin text-green',
    component: DistrictList,
    layout: route.ADMIN,
    show: hasAnyRole(roles, [ADMIN, MODERATOR]),
  },
  {
    path: route.ADMIN_DISTRICT,
    name: 'Район',
    icon: 'ni ni-book-bookmark text-yellow',
    component: District,
    layout: route.ADMIN,
    show: false,
  },
]

export default routes
