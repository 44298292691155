import { compose, pipe, path, prop, map, pathOr, propOr, find, propEq, dissoc } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  CardFooter,
} from 'reactstrap'
import Header from '../components/Headers/Header'
import Table from '../components/Table'
import TableSearch from '../components/Table/TableSearch'
import * as actions from '../redux/actions'
import { SocketApi } from '../helpers/httpClients'
import { css } from '@emotion/core'
import ClipLoader from 'react-spinners/ClipLoader'
import TablePagination from '../components/Pagination'
import LoadingContent from '../components/Placeholder/LoadingContent'
import ProductModal from '../components/Modal/ProductModal'
import ProductCreateForm from '../components/Product/ProductCreateForm'
import { submit } from 'redux-form'
import { advertCreate } from '../redux/actions'
import { createBrowserHistory } from 'history'

const tableHeader = [
  {
    title: 'ID',
  },
  {
    title: 'Заголовок',
  },
  {
    title: 'Магазин',
  },
  {
    title: 'Статус',
  },
  {
    title: 'Действие',
  },
]

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

const productStatusOptions = [
  {
    label: 'Все',
    value: null,
  },
  {
    label: 'Активные',
    value: 'ACTIVE',
  },
  {
    label: 'На модерации',
    value: 'ONHOLD',
  },
  {
    label: 'Отклоненные',
    value: 'DECLINED',
  },
  {
    label: 'Архивированные',
    value: 'ARCHIVED',
  },
  {
    label: 'Заблокированные',
    value: 'BLOCKED',
  },
]

const enhance = compose(
  connect(
    ({ advertsList, advertUpdateStatus, userInfo, socket, router, form, rubricActiveList }) => {
      //
      // console.log("Compose:")
      // console.log(advertsList)

      const list = prop('data', advertsList)
      const listContent = prop('content', list)
      const listLength = prop('total_elements', list)
      const isLastPage = prop('last', list)
      const pageSize = path(['pageable', 'pageSize'], list)
      const loading = prop('loading', advertsList)

      const rubricsList = prop('data', rubricActiveList)

      const advertUpdateStatusLoading = prop('loading', advertUpdateStatus)

      const roles = pathOr([], ['data', 'roles'], userInfo)
      const pathname = path(['location', 'pathname'], router)
      const query = path(['location', 'query'], router)
      const productFormValues = path(['ProductCreateForm', 'values'], form)

      const updated = path(['location', 'state', 'updated'], router)
      let updatedData = null

      if (updated === true) {
        updatedData = path(['location', 'state', 'data'], router)

        const history = createBrowserHistory()

        if (history.location.state) {
          let state = { ...history.location.state }
          delete state.updated
          delete state.data
          history.replace({ ...history.location, state })
        }

        const updatedId = prop('id', updatedData)
        const updatedStatus = prop('status', updatedData)
        const updatedIndex = listContent.findIndex(function (c) {
          return c.id === updatedId
        })

        if (listContent[updatedIndex]) {
          listContent[updatedIndex]['status'] = updatedStatus
          listContent[updatedIndex]['loading'] = false
        }
      }

      const socketData = prop('data', socket)

      return {
        list,
        advertsList,
        rubricsList,
        listContent,
        listLength,
        isLastPage,
        pageSize,
        loading,
        advertUpdateStatusLoading,
        roles,
        socketData,
        pathname,
        updated,
        updatedData,
        query,
        productFormValues,
      }
    }
  )
)

function ProductsList(props) {
  const {
    dispatch,
    history,
    pathname,
    listContent,
    rubricsList,
    loading,
    advertUpdateStatusLoading,
    // advertsList,
    listLength,
    isLastPage,
    pageSize,
    query,
    socketData,
    productFormValues,
  } = props

  const currentPage = propOr(1, 'page', query)

  const tableStyle = {
    cursor: 'pointer',
  }

  const currentStatusTitle = pipe(
    find(propEq('value', prop('status', query))),
    propOr('Все', 'label')
  )(productStatusOptions)

  const [subscribed, setSubscribed] = useState(false)

  const [modalIsOpened, setModalIsOpened] = useState(false)
  const [productInitialValues, setProductInitialValues] = useState({})
  const [currentItem, setCurrentItem] = useState(null)

  const [currentRubricId, setCurrentRubricId] = useState(null)
  const [currentCategoryId, setCurrentCategoryId] = useState(null)
  const [currentSubCategoryId, setCurrentSubCategoryId] = useState(null)

  function onProductStatusChanged(item, title) {
    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        status: item,
      }),
    })

    // setProductStatus(item)
    // setCurrentStatusTitle(title)
    // props.dispatch(actions.advertsListClear())
    // props.dispatch(actions.advertsList({
    //   page: currentPage - 1,
    //   status: item
    // }))
  }

  if (socketData != null) {
    const { subscriptionUrl } = SocketApi()
    if (!subscribed) {
      socketData.subscribe(subscriptionUrl, message => {
        const messageBody = message['body']
        if (messageBody) {
          const messageJson = JSON.parse(messageBody)
          const messageData = messageJson['data']

          const notificationType = messageJson['notification_type']
          if (notificationType === 'AD_CREATED') {
            dispatch(actions.advertNotification(messageData))
            // handlePagination(currentPage)
          }
        }
      })
      setSubscribed(true)
    }
  }

  useEffect(() => {
    const search = prop('search', query) ? { search: prop('search', query) } : {}

    props.dispatch(
      actions.advertsList({
        page: currentPage - 1,
        status: prop('status', query),
        ...search,
      })
    )

    props.dispatch(actions.rubricActiveList())
  }, [query])

  function handlePagination(page) {
    // props.dispatch(actions.advertsListClear())
    props.dispatch(
      actions.advertsList({
        page: page - 1,
        status: prop('status', query),
      })
    )

    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        page: page,
        status: prop('status', query),
      }),
    })
  }

  function handleAdvert(id = 0) {
    const item = find(propEq('id', id), listContent)

    if (item) {
      const rubricId = path(['rubric_info', 'id'], item)
      const categoryId = path(['category_info', 'id'], item)
      const subCategoryId = path(['sub_category_info', 'id'], item)

      setCurrentRubricId(rubricId)
      setCurrentCategoryId(categoryId)
      setCurrentSubCategoryId(subCategoryId)

      const initialValues = {
        headline: prop('headline', item),
        description: prop('description', item),
        photos: prop('photos_info', item),
        email: prop('email', item),
        phone: prop('phone', item),
        youtube_url: prop('youtube_url', item),
        id: prop('id', item),
      }

      if (rubricsList !== null && rubricId != null) {
        const chosenRubric = rubricsList.find(obj => obj.id === rubricId)

        if (chosenRubric) {
          initialValues['rubric'] = {
            label: chosenRubric['title']['ru'],
            value: chosenRubric['id'],
          }

          const categories = chosenRubric['items']
          const chosenCategory =
            categories.find(obj => obj.id === categoryId) || prop('category_info', item)

          initialValues['category'] = {
            label: chosenCategory['title']['ru'],
            value: chosenCategory['id'],
          }

          const subCategories = chosenCategory['items']
          const chosenSubCategory =
            subCategories.find(obj => obj.id === subCategoryId) || prop('sub_category_info', item)

          initialValues['subcategory'] = {
            label: chosenSubCategory['title']['ru'],
            value: chosenSubCategory['id'],
          }
        }
      }

      setProductInitialValues(initialValues)

      setCurrentItem(item)
      setModalIsOpened(true)
    }
  }

  function handleUpdateStatus(id, status) {
    props.dispatch(
      actions.advertUpdateStatus({
        id,
        status,
      })
    )

    const updatedIndex = listContent.findIndex(function (c) {
      return c.id === id
    })
    listContent[updatedIndex]['loading'] = true
  }

  function getStatusItem(status) {
    let iClassName = 'bg-success'
    let statusText = 'N/A'

    switch (status) {
      case 'ACTIVE': {
        iClassName = 'bg-success'
        statusText = 'активен'
        break
      }
      case 'ONHOLD': {
        iClassName = 'bg-yellow'
        statusText = 'на модерации'
        break
      }
      case 'DECLINED': {
        iClassName = 'bg-danger'
        statusText = 'отклонен'
        break
      }
      case 'ARCHIVED': {
        iClassName = 'bg-orange'
        statusText = 'архивирован'
        break
      }
      case 'BLOCKED': {
        iClassName = 'bg-default'
        statusText = 'заблокирован'
        break
      }
    }

    return (
      <span className='badge badge-dot mr-4'>
        <i className={iClassName} style={{ width: '0.65rem', height: '0.65rem' }} />
        <span className='status'>{statusText}</span>
      </span>
    )
  }

  function getActionDropdown(item) {
    const status = prop('status', item)
    const id = prop('id', item)
    const loading = prop('loading', item) || false

    return (
      <UncontrolledDropdown onClick={e => {
        e.stopPropagation()
      }}>
        <DropdownToggle
          className='btn-icon-only text-light'
          style={tableStyle}
          role='button'
          size='sm'
          color=''
          onClick={e => {
            e.preventDefault()
          }}
        >
          {(loading && (
            <ClipLoader css={override} size={16} color={'#123abc'} loading={true} />
          )) || <i className='fas fa-ellipsis-v' />}
        </DropdownToggle>
        {/*<DropdownToggle*/}
        {/*    caret*/}
        {/*    size='sm'*/}
        {/*    color="success"*/}
        {/*    onClick={e => e.preventDefault()}*/}
        {/*>*/}
        {/*    {loading && 'Изменяем статус'||'Изменить статус'}*/}
        {/*    /!*{loading &&  <ClipLoader*!/*/}
        {/*    /!*    css={override}*!/*/}
        {/*    /!*    size={16}*!/*/}
        {/*    /!*    color={"#123abc"}*!/*/}
        {/*    /!*    loading={true}*!/*/}
        {/*||  <i className="fas fa-ellipsis-v"/>}*/}
        {/*</DropdownToggle>*/}

        <DropdownMenu className='dropdown-menu-arrow' right>
          <DropdownItem
            style={tableStyle}
            onClick={e => {
              handleAdvert(id)
            }}
          >
            Редактировать
          </DropdownItem>
          <DropdownItem header style={{ color: '#c5c5c5' }}>
            Статусы
          </DropdownItem>
          {status !== 'ACTIVE' && (
            <DropdownItem
              href='#pablo'
              onClick={e => {
                e.preventDefault()
                handleUpdateStatus(id, 'ACTIVE')
              }}
            >
              Активировать
            </DropdownItem>
          )}
          {status !== 'ONHOLD' && (
            <DropdownItem
              href='#pablo'
              onClick={e => {
                e.preventDefault()
                handleUpdateStatus(id, 'ONHOLD')
              }}
            >
              На модерацию
            </DropdownItem>
          )}
          {status !== 'DECLINED' && (
            <DropdownItem
              href='#pablo'
              onClick={e => {
                e.preventDefault()
                handleUpdateStatus(id, 'DECLINED')
              }}
            >
              Отклонить
            </DropdownItem>
          )}
          {status !== 'ARCHIVED' && (
            <DropdownItem
              href='#pablo'
              onClick={e => {
                e.preventDefault()
                handleUpdateStatus(id, 'ARCHIVED')
              }}
            >
              Архивировать
            </DropdownItem>
          )}
          {status !== 'BLOCKED' && (
            <DropdownItem
              href='#pablo'
              onClick={e => {
                e.preventDefault()
                handleUpdateStatus(id, 'BLOCKED')
              }}
            >
              Заблокировать
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  const createTableRow = item => {
    const store = prop('store', item)
    const storeName = (store && path(['store_name', 'ru'], store)) || '-'
    return (
      <tr
        style={{ cursor: 'pointer' }}
        key={prop('id', item)}
        onClick={e => {
          e.stopPropagation()
          handleAdvert(prop('id', item))
        }}
        // onClick={() => handleAdvert(prop('id', item))}
      >
        <th scope='row'>{prop('id', item)}</th>
        <td>{prop('headline', item)}</td>
        <td>{storeName}</td>
        <td>{getStatusItem(prop('status', item))}</td>
        <td>{getActionDropdown(item)}</td>
      </tr>
    )
  }

  // console.log("Items:")
  // console.log(listContent)

  let tableContent = map((item, index) => {
    return createTableRow(item, index)
  }, listContent || [])

  function handleProductSubmit() {
    const photos = prop('photos', productFormValues)
    console.log('test', map(prop('src'), photos))

    props.dispatch(
      advertCreate({
        ...dissoc('photos', productFormValues),
        photos_src: photos && map(prop('src'), photos),
        sub_category_id: path(['subcategory', 'value'], productFormValues),
        rubric: null,
        category: null,
        subcategory: null,
        page: currentPage - 1,
      })
    )
    setCurrentItem(null)
    setModalIsOpened(false)
  }

  function handleProductSuccess() {
    props.dispatch(submit('ProductCreateForm'))
  }

  return (
    <Fragment>
      <Header />

      {/*<Field*/}
      {/*  name='rubric'*/}
      {/*  onChange={onProductStatusChanged}*/}
      {/*  component={SelectField}*/}
      {/*  placeholder='Рубрика'*/}
      {/*  options={productStatusOptions}*/}
      {/*  addon={*/}
      {/*    <label*/}
      {/*      className="form-control-label"*/}
      {/*      htmlFor="input-username"*/}
      {/*    >*/}
      {/*      Статус*/}
      {/*    </label>*/}
      {/*  }*/}
      {/*/>*/}

      <Container className=' mt--7' fluid>
        <Row>
          <div className='col'>
            <Card className='shadow'>
              <CardHeader className='border-0'>
                <Row className='align-items-center'>
                  <Col xs='4'>
                    <h3 className='mb-0'>Объявления</h3>
                  </Col>
                  <Col xs='5'>
                    <UncontrolledDropdown>
                      <DropdownToggle caret color='info'>
                        {currentStatusTitle}
                        {/*{currentStatusTitle === null && 'Выберите статус' || currentStatusTitle}*/}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem key={1} onClick={e => onProductStatusChanged(null, 'Все')}>
                          Все
                        </DropdownItem>
                        <DropdownItem
                          key={2}
                          onClick={e => onProductStatusChanged('ACTIVE', 'Активные')}
                        >
                          Активные
                        </DropdownItem>
                        <DropdownItem
                          key={3}
                          onClick={e => onProductStatusChanged('ONHOLD', 'На модерации')}
                        >
                          На модерации
                        </DropdownItem>
                        <DropdownItem
                          key={4}
                          onClick={e => onProductStatusChanged('DECLINED', 'Отклоненные')}
                        >
                          Отклоненные
                        </DropdownItem>
                        <DropdownItem
                          key={5}
                          onClick={e => onProductStatusChanged('ARCHIVED', 'Архивированные')}
                        >
                          Архивированные
                        </DropdownItem>
                        <DropdownItem
                          key={6}
                          onClick={e => onProductStatusChanged('BLOCKED', 'Заблокированные')}
                        >
                          Заблокированные
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>

                  <Col className='text-right' xs='3'>
                    <TableSearch history={history} pathname={pathname} query={query} />
                  </Col>
                </Row>
              </CardHeader>

              <Table
                header={tableHeader}
                content={
                  (tableContent.length > 0 && tableContent) || (
                    <tr>
                      <td colSpan='5' align='center'>
                        {(advertUpdateStatusLoading === false && 'Нету элементов') || (
                          <LoadingContent minHeight={'56px'} color={'#24bc79'} />
                        )}
                      </td>
                    </tr>
                  )
                }
              />

              <CardFooter className='py-4'>
                <nav aria-label='...'>
                  <TablePagination
                    totalElements={listLength}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    last={isLastPage}
                    onChange={handlePagination}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/*Product Modal*/}
      <ProductModal
        modalIsOpened={modalIsOpened}
        setModalIsOpened={setModalIsOpened}
        initialValues={productInitialValues}
        item={currentItem}
        rubricsList={rubricsList}
        rubricId={currentRubricId}
        categoryId={currentCategoryId}
        subCategoryId={currentSubCategoryId}
        handleSubmit={handleProductSubmit}
        handleSuccess={handleProductSuccess}
        listContent={listContent}
        productFormValues={productFormValues}
      />
    </Fragment>
  )
}

export default enhance(ProductsList)
