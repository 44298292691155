import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { regionList } from '../../api/region'
import actionType, { REGION_LIST } from './actionTypes'

function* regionListRequest() {
  yield takeEvery(actionType[REGION_LIST].request, function* ({ payload }) {
    try {
      const { data } = yield call(regionList, payload)

      yield put({
        type: actionType[REGION_LIST].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[REGION_LIST].error,
        error,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(regionListRequest)])
}
