import createAction from '../../helpers/createAction'
import {
  SUBCATEGORY,
  SUBCATEGORY_CREATE,
  SUBCATEGORY_DELETE,
  SUBCATEGORY_ACTIVATE,
} from '../actionTypes'

export default {
  [SUBCATEGORY]: createAction(SUBCATEGORY),
  [SUBCATEGORY_CREATE]: createAction(SUBCATEGORY_CREATE),
  [SUBCATEGORY_DELETE]: createAction(SUBCATEGORY_DELETE),
  [SUBCATEGORY_ACTIVATE]: createAction(SUBCATEGORY_ACTIVATE),
}

export { SUBCATEGORY, SUBCATEGORY_CREATE, SUBCATEGORY_DELETE, SUBCATEGORY_ACTIVATE }
