import actionType, { STORE, STORE_ACTIVATE, STORE_UPDATE_STATUS, STORE_DELETE } from './actionTypes'
import { STORE_CREATE } from '../actionTypes'

export const store = payload => ({
  type: actionType[STORE].request,
  payload,
})

export const storeCreate = payload => ({
  type: actionType[STORE_CREATE].request,
  payload,
})

export const storeClear = payload => ({
  type: actionType[STORE].clear,
  payload,
})

export const storeUpdateStatus = payload => ({
  type: actionType[STORE_UPDATE_STATUS].request,
  payload,
})

export const storeDelete = payload => ({
  type: actionType[STORE_DELETE].request,
  payload,
})

export const storeActivate = payload => ({
  type: actionType[STORE_ACTIVATE].request,
  payload,
})
