import React, { Fragment } from 'react'
import { reduxForm, Field, FormSection } from 'redux-form'
import { Form, Row, Col } from 'reactstrap'
import { CheckboxField, InputField, SelectField } from '../Form'

function CategoryCreateForm(props) {
  const { tabs, rubricOptions, typeOptions } = props
  return (
    <Fragment>
      <Form>
        <div className='pl-lg-4'>
          <FormSection name='title'>
            <Row>
              {tabs === 1 && (
                <Col>
                  <Field
                    name='ru'
                    component={InputField}
                    placeholder='Название на русском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Название на русском
                      </label>
                    }
                  />
                </Col>
              )}
              {tabs === 2 && (
                <Col>
                  <Field
                    name='uz'
                    component={InputField}
                    placeholder='Название на узбекском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Название на узбекском
                      </label>
                    }
                  />
                </Col>
              )}
              {tabs === 3 && (
                <Col>
                  <Field
                    name='en'
                    component={InputField}
                    placeholder='Название  на английском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Название на английском
                      </label>
                    }
                  />
                </Col>
              )}
            </Row>
          </FormSection>

          <Row style={{ marginBottom: '24px', marginTop: '24px' }}>
            <Col>
              <Field
                name='description_visible'
                component={CheckboxField}
                label='Отображать описание на сайте'
                id='description_visible'
              />
            </Col>
          </Row>

          <FormSection name='description'>
            {tabs === 1 && (
              <Row>
                <Col>
                  <Field
                    name='ru'
                    component={InputField}
                    placeholder='Введите описание категории (на русском языке)'
                    rows='3'
                    type='textarea'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Описание категории (на русском языке)
                      </label>
                    }
                  />
                </Col>
              </Row>
            )}
            {tabs === 2 && (
              <Row>
                <Col>
                  <Field
                    name='uz'
                    component={InputField}
                    placeholder='Введите описание категории (на узбекском языке)'
                    rows='3'
                    type='textarea'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Описание категории (на узбекском языке)
                      </label>
                    }
                  />
                </Col>
              </Row>
            )}
            {tabs === 3 && (
              <Row>
                <Col>
                  <Field
                    name='en'
                    component={InputField}
                    placeholder='Введите описание категории (на английском языке)'
                    rows='3'
                    type='textarea'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Описание категории (на английском языке)
                      </label>
                    }
                  />
                </Col>
              </Row>
            )}
          </FormSection>

          <Row>
            <Col lg='6'>
              <Field
                name='department_type'
                component={SelectField}
                placeholder='Тип рубрики'
                options={typeOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Тип рубрики
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name='meta_title'
                component={InputField}
                placeholder='Мета-тег Title'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Мета-тег Title
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name='meta_description'
                component={InputField}
                rows='3'
                type='textarea'
                placeholder='Мета-тег Description'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Мета-тег Description
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name='meta_keywords'
                component={InputField}
                placeholder='Meta keywords (через запятую)'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Meta ключевые слова
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg='6'>
              <Field
                name='rubric_id'
                component={SelectField}
                placeholder='Рубрика'
                options={rubricOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Рубрика
                  </label>
                }
              />
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'CategoryCreateForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(CategoryCreateForm)
