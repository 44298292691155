import createAction from '../../../helpers/createAction'
import {
  SUBCATEGORY_FIELD_CREATE,
  SUBCATEGORY_FIELD_DELETE,
  SUBCATEGORY_FIELD_COPY,
} from '../../actionTypes'

export default {
  [SUBCATEGORY_FIELD_CREATE]: createAction(SUBCATEGORY_FIELD_CREATE),
  [SUBCATEGORY_FIELD_DELETE]: createAction(SUBCATEGORY_FIELD_DELETE),
  [SUBCATEGORY_FIELD_COPY]: createAction(SUBCATEGORY_FIELD_COPY),
}

export { SUBCATEGORY_FIELD_CREATE, SUBCATEGORY_FIELD_DELETE, SUBCATEGORY_FIELD_COPY }
