export const FIELD_TYPE_OPTIONS = [
  {
    value: 'TEXT_FIELD',
    label: 'Текстовое поле',
  },
  {
    value: 'MULTI_TEXT_FIELD',
    label: 'Текстовое поле (многострочное)',
  },
  {
    value: 'SINGLE_CHOICE',
    label: 'Выборочный список (одиночный)',
  },
  {
    value: 'MULTIPLE_CHOICE',
    label: 'Выборочный список (множественный)',
  },
  {
    value: 'NUMERIC_FIELD',
    label: 'Числовое поле (целые)',
  },
  {
    value: 'DOUBLE_FIELD',
    label: 'Числовое поле (десятичные)',
  },
  {
    value: 'DATE_FIELD',
    label: 'Дата',
  },
  {
    value: 'TIME_FIELD',
    label: 'Время',
  },
  {
    value: 'NUMERIC_WITH_TYPE',
    label: 'Числовое с типом',
  },
  {
    value: 'TEXT_WITH_TYPE',
    label: 'Текст с типом',
  },
  {
    value: 'DOUBLE_WITH_TYPE',
    label: 'Не целое число с типом',
  },
  {
    value: 'BOOLEAN',
    label: 'Да/Нет',
  },
  {
    value: 'SINGLE_FILE_UPLOAD',
    label: 'Одиночный файловый загрузчик',
  },
  {
    value: 'MULTIPLE_FILE_UPLOAD',
    label: 'Множественный файловый загрузчик',
  },
  {
    value: 'SINGLE_FILE_UPLOAD_WATERMARKED',
    label: 'Одиночный файловый загрузчик (с водяным знаком)',
  },
  {
    value: 'MULTIPLE_FILE_UPLOAD_WATERMARKED',
    label: 'Множественный файловый загрузчик (с водяным знаком)',
  },
]
