import { AuthApi } from '../helpers/httpClients'
import { USER_ADMIN, USER_USER, USER_ME_INFO, USER_UPDATE_PASSWORD } from '../constants/api'

export const usersAdminList = data => AuthApi().get(USER_ADMIN, data)

export const usersUserList = data => AuthApi().get(USER_USER, data)

export const userMeInfo = data => AuthApi().get(USER_ME_INFO, data)

export const updateUserPassword = data => AuthApi().post(USER_UPDATE_PASSWORD, data)
