import { prop } from 'ramda'
import { all, fork, takeEvery, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import actionType, { TOKEN } from './actionTypes'
import { setToken } from '../../helpers/tokenStorage'
import { AUTH_LOGIN } from '../../constants/routes'
import * as STORAGE from '../../constants/storage'

function* tokenRequest() {
  yield takeEvery(actionType[TOKEN].request, function* ({ payload }) {
    try {
      if (!payload) {
        yield put(push(AUTH_LOGIN))
      } else {
        const token = prop(STORAGE.TOKEN, payload)

        if (token) {
          setToken(token)

          yield put({
            type: actionType[TOKEN].success,
            payload: token,
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionType[TOKEN].error,
        error,
      })
    }
  })
}

function* tokenSuccess() {
  yield takeEvery(actionType[TOKEN].success, function* ({ payload }) {
    // yield put(actions.socket(payload))
  })
}

export default function* rootSaga() {
  yield all([fork(tokenRequest), fork(tokenSuccess)])
}
