import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { categoryList } from '../../api/category'
import actionType, { CATEGORY_LIST } from './actionTypes'

function* categoryListRequest() {
  yield takeEvery(actionType[CATEGORY_LIST].request, function* ({ payload }) {
    try {
      const { data } = yield call(categoryList, payload)

      yield put({
        type: actionType[CATEGORY_LIST].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[CATEGORY_LIST].error,
        error,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(categoryListRequest)])
}
