import { prop } from 'ramda'
import { AuthApi } from '../helpers/httpClients'
import { CATEGORY_FIELD } from '../constants/api'

export const createOne = data => AuthApi().post(CATEGORY_FIELD + '/', data)

export const copyFromCategory = data =>
  AuthApi().post(
    `${CATEGORY_FIELD}/copy?from_id=${prop('from_id', data)}&to_id=${prop('to_id', data)}`,
    data
  )

export const updateOne = data => AuthApi().put(`${CATEGORY_FIELD}/${prop('id', data)}`, data)

export const deleteOne = data =>
  AuthApi().delete(
    `${CATEGORY_FIELD}?id=${prop('id', data)}&category_id=${prop('category_id', data)}`
  )
