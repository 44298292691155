import { pipe, find, equals, map, addIndex } from 'ramda'
import { ADMIN, SEO_MODERATOR } from '../constants/userRole'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

export const indexedMap = addIndex(map)

export const categoriesTypes = [
  {
    key: 'FOOD_N_DRINKS',
    value: 'Еда и напитки',
  },
  {
    key: 'ENTERTAINMENT',
    value: 'Развлечения',
  },
  {
    key: 'SHOPPING',
    value: 'Магазин',
  },
]

export const getUserRole = role => {
  const isAdmin = pipe(
    find(item => equals(ADMIN, item)),
    Boolean
  )(role)

  const isSeoModerator = pipe(
    find(item => equals(SEO_MODERATOR, item)),
    Boolean
  )(role)

  return {
    isAdmin,
    isSeoModerator,
  }
}

export const hasAnyRole = (roles, rolesToCheck) => {
  let result = false
  if (!roles) return false
  map(role => {
    const hasRole = pipe(
      find(item => equals(role, item)),
      Boolean
    )(rolesToCheck)
    if (hasRole) result = true
  }, roles)
  return result
}

export const getFieldTypeText = type => {
  switch (type) {
    case 'TEXT_FIELD':
      return 'Текстовое поле'
    case 'MULTI_TEXT_FIELD':
      return 'Текстовое поле (многострочное)'
    case 'SINGLE_CHOICE':
      return 'Выборочный список (одиночный)'
    case 'MULTIPLE_CHOICE':
      return 'Выборочный список (множественный)'
    case 'NUMERIC_FIELD':
      return 'Числовое поле (целые)'
    case 'DOUBLE_FIELD':
      return 'Числовое поле (десятичные)'
    case 'DATE_FIELD':
      return 'Дата'
    case 'TIME_FIELD':
      return 'Время'
    case 'NUMERIC_WITH_TYPE':
      return 'Числовое с типом'
    case 'TEXT_WITH_TYPE':
      return 'Текст с типом'
    case 'DOUBLE_WITH_TYPE':
      return 'Не целое число с типом'
    case 'BOOLEAN':
      return 'Да/Нет'
    case 'SINGLE_FILE_UPLOAD':
      return 'Одиночный файловый загрузчик'
    case 'MULTIPLE_FILE_UPLOAD':
      return 'Множественный файловый загрузчик'
    case 'SINGLE_FILE_UPLOAD_WATERMARKED':
      return 'Одиночный файловый загрузчик (с водяным знаком)'
    case 'MULTIPLE_FILE_UPLOAD_WATERMARKED':
      return 'Множественный файловый загрузчик (с водяным знаком)'
  }
}

export const editorFieldConvertToHTML = value => {
  if (value.getCurrentContent) {
    return draftToHtml(convertToRaw(value.getCurrentContent()))
  }

  return ''
}
