import createAction from '../../helpers/createAction'
import {
  STORE,
  STORE_DELETE,
  STORE_ACTIVATE,
  STORE_UPDATE_STATUS,
  STORE_CREATE,
} from '../actionTypes'

export default {
  [STORE]: createAction(STORE),
  [STORE_DELETE]: createAction(STORE_DELETE),
  [STORE_ACTIVATE]: createAction(STORE_ACTIVATE),
  [STORE_UPDATE_STATUS]: createAction(STORE_UPDATE_STATUS),
  [STORE_CREATE]: createAction(STORE_CREATE),
}

export { STORE, STORE_DELETE, STORE_ACTIVATE, STORE_UPDATE_STATUS }
