import { all } from 'redux-saga/effects'
import token from './token/saga'
import signIn from './signin/saga'
import userInfo from './userInfo/saga'
import country from './country/saga'
import countryList from './countryList/saga'
import region from './region/saga'
import regionList from './regionList/saga'
import city from './city/saga'
import cityList from './cityList/saga'
import district from './district/saga'
import districtList from './districtList/saga'
import rubric from './rubric/saga'
import rubricList from './rubricList/saga'
import rubricField from './rubric/field/saga'
import category from './category/saga'
import categoryList from './categoryList/saga'
import categoryField from './category/field/saga'
import pageCategoryList from './pageCategoryList/saga'
import pageCategory from './pageCategory/saga'
import subcategory from './subcategory/saga'
import subcategoryField from './subcategory/field/saga'
import advertsList from './advertsList/saga'
import advert from './advert/saga'
import storesList from './storesList/saga'
import store from './store/saga'
import pagesList from './pagesList/saga'
import page from './page/saga'
import socket from './socket/saga'
import complainList from './complainList/saga'
import usersList from './usersList/saga'

export default function* rootSaga(getState) {
  yield all([
    token(),
    signIn(),
    userInfo(),
    country(),
    countryList(),
    region(),
    regionList(),
    city(),
    cityList(),
    district(),
    districtList(),
    rubric(),
    rubricList(),
    rubricField(),
    subcategory(),
    subcategoryField(),
    category(),
    categoryList(),
    categoryField(),
    pageCategory(),
    pageCategoryList(),
    advert(),
    advertsList(),
    store(),
    storesList(),
    page(),
    pagesList(),
    socket(),
    complainList(),
    usersList(),
  ])
}
