import { prop, dissoc } from 'ramda'
import { AuthApi } from '../helpers/httpClients'
import { RUBRIC } from '../constants/api'

export const rubricList = data => AuthApi().get(RUBRIC + '/deleted/all', data)

export const rubricPagedList = data => AuthApi().get(RUBRIC + '/', data)

export const rubricActiveList = data => AuthApi().get(RUBRIC + '/all', data)

export const rubricGet = data =>
  AuthApi().get(`${RUBRIC}/full/${prop('id', data)}`, dissoc('id', data))

export const rubricGetOld = data =>
  AuthApi().get(`${RUBRIC}/${prop('id', data)}`, dissoc('id', data))

export const rubricCreate = data => AuthApi().post(RUBRIC + '/', data)

export const rubricUpdate = data => AuthApi().put(`${RUBRIC}/${prop('id', data)}`, data)

export const rubricDelete = data => {
  if (prop('permanent', data)) {
    return AuthApi().delete(`${RUBRIC}/permanent/${prop('id', data)}`)
  } else {
    return AuthApi().delete(`${RUBRIC}/${prop('id', data)}`)
  }
}

export const rubricActivate = data => AuthApi().put(`${RUBRIC}/activate/${prop('id', data)}`)
