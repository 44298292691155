import createAction from '../../helpers/createAction'
import { CITY, CITY_CREATE, CITY_DELETE } from '../actionTypes'

export default {
  [CITY]: createAction(CITY),
  [CITY_CREATE]: createAction(CITY_CREATE),
  [CITY_DELETE]: createAction(CITY_DELETE),
}

export { CITY, CITY_CREATE, CITY_DELETE }
