import React, { Fragment, useState } from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { Row, Col, Form, Label } from 'reactstrap'
import { InputField, SelectField } from '../Form'
import RenderPhones from './RenderPhones'
import ImgInputField from '../Form/ImgInputField'

const StoreCreateForm = props => {
  const { rubricOpts, categoryOpts, handleRubricChange, handleSubmit } = props
  const [loading, setLoading] = useState(false)

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Label className='form-control-label'>Ссылка магазина (только латиница)</Label>
            <Field
              name='store_username'
              component={InputField}
              normalize={value => ('' + value).toLowerCase().replace(' ', '')}
            />
          </Col>

          <Col md={6}>
            <Label className='form-control-label'>Название магазина (русс)</Label>
            <Field name='store_name_ru' component={InputField} />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Label className='form-control-label'>Название магазина (узб)</Label>
            <Field name='store_name_uz' component={InputField} />
          </Col>

          <Col md={6}>
            <Label className='form-control-label'>Название магазина (англ)</Label>
            <Field name='store_name_en' component={InputField} />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Label className='form-control-label'>Описание магазина(англ)</Label>
            <Field type='textarea' component={InputField} rows={3} name='description_en' />
          </Col>

          <Col md={4}>
            <Label className='form-control-label'>Описание магазина (русс)</Label>
            <Field type='textarea' component={InputField} rows={3} name='description_ru' />
          </Col>

          <Col md={4}>
            <Label className='form-control-label'>Описание магазина (узб)</Label>
            <Field type='textarea' component={InputField} rows={3} name='description_uz' />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Label className='form-control-label'>Рубрика</Label>
            <Field
              name='rubric'
              onChange={handleRubricChange}
              component={SelectField}
              options={rubricOpts}
              placeholder='Рубрика'
            />
          </Col>

          <Col md={6}>
            <Label className='form-control-label'>Категория</Label>
            <Field
              name='category'
              component={SelectField}
              options={categoryOpts}
              placeholder='Категория'
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Label className='form-control-label'>Номера телефонов</Label>
            <FieldArray name='list_of_phones' component={RenderPhones} />
          </Col>

          <Col md={6}>
            <Label className='form-control-label'>Адрес магазина</Label>
            <Field type='text' component={InputField} name='address' />
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col md={3} sm={6} className='d-flex flex-column'>
            <Label
              className='form-control-label'
              style={{
                maxWidth: '140px',
              }}
            >
              Логотип магазина - 400x400
            </Label>
            <Field component={ImgInputField} name='brand_image' setLoading={setLoading} />
          </Col>

          <Col md={3} sm={6} className='d-flex flex-column'>
            <Label className='form-control-label'>Верхнее изображение (сайт) - 1920x420</Label>
            <Field component={ImgInputField} name='top_image' setLoading={setLoading} />
          </Col>

          <Col md={3} sm={6} className='d-flex flex-column'>
            <Label className='form-control-label'>Верхнее изображение (мобильное) - 500x3000</Label>
            <Field component={ImgInputField} name='mobile_top_image' setLoading={setLoading} />
          </Col>

          <Col md={3} sm={6} className='d-flex flex-column'>
            <Label className='form-control-label'>Левое изображение (сайт) - 300x1500</Label>
            <Field component={ImgInputField} name='left_image' setLoading={setLoading} />
          </Col>

          <Col md={3} sm={6} className='d-flex flex-column'>
            <Label className='form-control-label'>Правое изображение (сайт) - 300x1500</Label>
            <Field component={ImgInputField} name='right_image' setLoading={setLoading} />
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}
export default reduxForm({
  form: 'StoreCreateForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(StoreCreateForm)
