//PAGE CATEGORY POSITION TYPES

export const MAIN_PAGE_MAIN_CATEGORIES = 'MAIN_PAGE_MAIN_CATEGORIES'
export const MAIN_PAGE_TOP_SLIDER = 'MAIN_PAGE_TOP_SLIDER'
export const TOP_NAV_BAR_CATEGORIES = 'TOP_NAV_BAR_CATEGORIES'
export const MOBILE_MAIN_CATEGORIES = 'MOBILE_MAIN_CATEGORIES'
export const TELEGRAM_BOT_MAIN_CATEGORIES = 'TELEGRAM_BOT_MAIN_CATEGORIES'
export const STORE_MAIN_PAGE_MAIN_CATEGORIES = 'STORE_MAIN_PAGE_MAIN_CATEGORIES'
export const STORE_TOP_NAV_BAR_CATEGORIES = 'STORE_TOP_NAV_BAR_CATEGORIES'
export const STORE_MAIN_PAGE_TOP_SLIDER = 'STORE_MAIN_PAGE_TOP_SLIDER'
export const MOBILE_STORE_MAIN_CATEGORIES = 'MOBILE_STORE_MAIN_CATEGORIES'

export const STORE_STATUS_PENDING = 0
export const STORE_STATUS_ACTIVE = 1
export const STORE_STATUS_DECLINED = -1
export const STORE_STATUS_ARCHIVED = -2
export const STORE_STATUS_BLOCKED = -3
export const STORE_STATUS_NOT_PAID = -4
