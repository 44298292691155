import actionType, {
  ADVERT,
  ADVERT_ACTIVATE,
  ADVERT_UPDATE_STATUS,
  ADVERT_DELETE,
  ADVERT_CREATE,
} from './actionTypes'

export const advert = payload => ({
  type: actionType[ADVERT].request,
  payload,
})

export const advertClear = payload => ({
  type: actionType[ADVERT].clear,
  payload,
})

export const advertCreate = payload => ({
  type: actionType[ADVERT_CREATE].request,
  payload,
})

export const advertUpdateStatus = payload => ({
  type: actionType[ADVERT_UPDATE_STATUS].request,
  payload,
})

export const advertDelete = payload => ({
  type: actionType[ADVERT_DELETE].request,
  payload,
})

export const advertActivate = payload => ({
  type: actionType[ADVERT_ACTIVATE].request,
  payload,
})
