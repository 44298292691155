import React, { Fragment } from 'react'
import { reduxForm, Field, FormSection } from 'redux-form'
import {
  Form,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { CheckboxField, InputField, SelectField } from '../Form'
import { FIELD_TYPE_OPTIONS } from '../../constants/fieldTypeOptions'
import { map, path } from 'ramda'

function SubCategoryFieldCreateForm(props) {
  const {
    currentFieldType,
    optionsList,
    onAddOptionClicked,
    onEditOptionClicked,
    onTypeChange,
    typeOptions,
  } = props

  const options = map(item => {
    const titleRu = path(['title', 'ru'], item)
    return (
      <li>
        <DropdownItem
          style={{ cursor: 'default' }}
          onClick={e => e.preventDefault()}
          className='align-content-center'
        >
          {titleRu}
          <a
            onClick={e => {
              e.preventDefault()
              onEditOptionClicked(item)
            }}
          >
            <i style={{ cursor: 'pointer' }} className='fas fa-pen text-success ml-3' />
          </a>
        </DropdownItem>
      </li>
    )
  }, optionsList || [])

  return (
    <Fragment>
      <Form>
        <div className='pl-lg-4'>
          <Row>
            <Col lg='4'>
              <Field
                name='name'
                component={InputField}
                onKeyPress={event => {
                  const isValid =
                    (event.charCode >= 48 && event.charCode <= 57) ||
                    (event.charCode >= 97 && event.charCode <= 122) ||
                    event.charCode === 95
                  if (!isValid) event.preventDefault()
                }}
                placeholder='Ключ (уникальный)'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Ключ (уникальный)
                  </label>
                }
              />
            </Col>
            <Col lg='4'>
              <Field
                name='type'
                component={SelectField}
                placeholder='Тип'
                onChange={onTypeChange}
                options={FIELD_TYPE_OPTIONS}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Тип
                  </label>
                }
              />
            </Col>

            {(currentFieldType === 'SINGLE_CHOICE' ||
              currentFieldType === 'MULTIPLE_CHOICE' ||
              currentFieldType === 'NUMERIC_WITH_TYPE' ||
              currentFieldType === 'TEXT_WITH_TYPE' ||
              currentFieldType === 'DOUBLE_WITH_TYPE') && (
              <Col lg='4'>
                <label className='form-control-label' htmlFor='input-username'>
                  Тип
                </label>
                <br />
                <UncontrolledDropdown>
                  <DropdownToggle color='secondary'>Варианты</DropdownToggle>
                  <DropdownMenu>
                    <li>
                      <DropdownItem
                        style={{ cursor: 'pointer' }}
                        onClick={e => {
                          e.preventDefault()
                          onAddOptionClicked()
                        }}
                      >
                        Добавить
                        <a onClick={() => {}}>
                          <i className='fas fa-plus-square text-success ml-3' />
                        </a>
                      </DropdownItem>
                    </li>
                    {options}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            )}
          </Row>
          <FormSection name='title'>
            <Row>
              <Col>
                <Field
                  name='ru'
                  component={InputField}
                  placeholder='Название (RU)'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название (RU)
                    </label>
                  }
                />
              </Col>
              <Col>
                <Field
                  name='uz'
                  component={InputField}
                  placeholder='Название (UZ)'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название (UZ)
                    </label>
                  }
                />
              </Col>
              <Col>
                <Field
                  name='en'
                  component={InputField}
                  placeholder='Название (EN)'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название (EN)
                    </label>
                  }
                />
              </Col>
            </Row>
          </FormSection>
          <Row>
            <Col lg='6'>
              <Field
                name='min_length'
                component={InputField}
                onKeyPress={event => {
                  const isValid =
                    (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46 // Only numbers and dot
                  if (!isValid) event.preventDefault()
                }}
                placeholder='Минимальное значение'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Минимальное значение
                  </label>
                }
              />
            </Col>
            <Col lg='6'>
              <Field
                name='max_length'
                component={InputField}
                onKeyPress={event => {
                  const isValid =
                    (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46 // Only numbers and dot
                  if (!isValid) event.preventDefault()
                }}
                placeholder='Максимальное значение'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Максимальное значение
                  </label>
                }
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '24px', marginBottom: '24px' }}>
            <Col lg='5'>
              <Field
                name='visibility_type'
                component={SelectField}
                placeholder='Тип рубрики'
                options={typeOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Тип рубрики
                  </label>
                }
              />
            </Col>
            <Col lg='3' className='d-flex flex-column justify-content-center ml-3'>
              <Field name='optional' component={CheckboxField} label='Опционально' id='optional' />
              <Field name='disabled' component={CheckboxField} label='Отключено' id='disabled' />
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'SubCategoryFieldCreateForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SubCategoryFieldCreateForm)
