import { compose, path, prop, map, propOr, find, propEq } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  CardFooter,
} from 'reactstrap'
import Header from '../components/Headers/Header'
import Table from '../components/Table'
import TableSearch from '../components/Table/TableSearch'
import * as actions from '../redux/actions'
import { SocketApi } from '../helpers/httpClients'
import { css } from '@emotion/core'
import ClipLoader from 'react-spinners/ClipLoader'
import TablePagination from '../components/Pagination'
import LoadingContent from '../components/Placeholder/LoadingContent'
import StoreModal from '../components/Modal/StoreModal'
import {
  STORE_STATUS_ACTIVE,
  STORE_STATUS_ARCHIVED,
  STORE_STATUS_BLOCKED,
  STORE_STATUS_DECLINED,
  STORE_STATUS_PENDING,
} from '../constants/enums'
import { COLOR_BLUE, COLOR_GREEN, COLOR_RED } from '../constants/colors'
import { submit } from 'redux-form'

const tableHeader = [
  {
    title: 'ID',
  },
  {
    title: 'Название',
  },
  {
    title: 'Ссылка',
  },
  {
    title: 'Статус',
  },
  {
    title: 'Логотип',
  },
  {
    title: 'Подписка',
  },
  {
    title: 'Действие',
  },
]

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

const enhance = compose(
  connect(({ storesList, storeUpdateStatus, socket, router, form, rubricActiveList }) => {
    const list = prop('data', storesList)
    const listContent = prop('content', list)
    const listLength = prop('total_elements', list)
    const isLastPage = prop('last', list)
    const pageSize = path(['pageable', 'pageSize'], list)

    const storeUpdateStatusLoading = prop('loading', storeUpdateStatus)

    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    const socketData = prop('data', socket)

    return {
      pathname,
      listContent,
      storeUpdateStatusLoading,
      listLength,
      isLastPage,
      pageSize,
      query,
      socketData,
      rubricActiveList,
      form,
    }
  })
)

function StoresList(props) {
  const {
    dispatch,
    history,
    pathname,
    listContent,
    storeUpdateStatusLoading,
    listLength,
    isLastPage,
    pageSize,
    query,
    socketData,
    rubricActiveList,
    form,
  } = props
  const currentPage = propOr(1, 'page', query)

  const tableStyle = {
    cursor: 'pointer',
  }

  const [subscribed, setSubscribed] = useState(false)
  const [content, setContent] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (socketData != null) {
    const { subscriptionUrl } = SocketApi()
    if (!subscribed) {
      socketData.subscribe(subscriptionUrl, message => {
        const messageBody = message['body']
        if (messageBody) {
          const messageJson = JSON.parse(messageBody)
          const messageData = messageJson['data']

          dispatch(actions.storeNotification(messageData))

          const notificationType = messageJson['notification_type']
          if (notificationType === 'STORE_CREATED') {
            dispatch(actions.storeNotification(messageData))
            // handlePagination(currentPage)
          }
        }
      })
      setSubscribed(true)
    }
  }

  useEffect(() => {
    dispatch(
      actions.storesList({
        page: currentPage - 1,
        search: prop('search', query),
      })
    )
  }, [query])

  useEffect(() => {
    dispatch(actions.rubricActiveList())
  }, [])

  function handlePagination(page) {
    dispatch(
      actions.storesList({
        page: page - 1,
      })
    )

    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        page: page,
      }),
    })
  }

  function handleItemClick(id = 0) {
    // history.push({
    //     pathname: route.ADMIN_PAGE,
    //     search: queryString.stringify({
    //         id,
    //     }),
    //     state: {
    //         from: pathname,
    //     }
    // })
  }

  function handleUpdateStatus(id, status) {
    dispatch(
      actions.storeUpdateStatus({
        id,
        status,
      })
    )

    const updatedIndex = listContent.findIndex(function (c) {
      return c.id === id
    })
    listContent[updatedIndex]['loading'] = true
  }

  function getStatusItem(status) {
    let iClassName = 'bg-success'
    let statusText = 'N/A'

    switch (status) {
      case 1: {
        iClassName = 'bg-success'
        statusText = 'активен'
        break
      }
      case 0: {
        iClassName = 'bg-yellow'
        statusText = 'на модерации'
        break
      }
      case -1: {
        iClassName = 'bg-danger'
        statusText = 'отклонен'
        break
      }
      case -2: {
        iClassName = 'bg-orange'
        statusText = 'архивирован'
        break
      }
      case -3: {
        iClassName = 'bg-default'
        statusText = 'заблокирован'
        break
      }
      case -4: {
        iClassName = 'bg-default'
        statusText = 'не оплачен'
        break
      }
    }

    return (
      <span className='badge badge-dot mr-4'>
        <i className={iClassName} style={{ width: '0.65rem', height: '0.65rem' }} />
        <span className='status'>{statusText}</span>
      </span>
    )
  }

  function handleAdvert(id) {
    const item = find(propEq('id', id), listContent)

    if (item) {
      setContent(item)
      setIsModalOpen(true)
    }
  }

  function getActionDropdown(item) {
    const status = prop('status', item)
    const id = prop('id', item)
    const loading = prop('loading', item) || false

    return (
      <td className='text-right'>
        <UncontrolledDropdown>
          <DropdownToggle
            className='btn-icon-only text-light'
            role='button'
            size='sm'
            color=''
            onClick={e => e.preventDefault()}
          >
            {(loading && (
              <ClipLoader css={override} size={16} color={'#123abc'} loading={true} />
            )) || <i className='fas fa-ellipsis-v' />}
          </DropdownToggle>

          <DropdownMenu className='dropdown-menu-arrow' right>
            <DropdownItem
              href='#pablo'
              style={tableStyle}
              onClick={e => {
                handleAdvert(id)
              }}
            >
              Редактировать
            </DropdownItem>
            {status !== STORE_STATUS_ACTIVE && (
              <DropdownItem
                href='#pablo'
                onClick={e => {
                  e.preventDefault()
                  handleUpdateStatus(id, STORE_STATUS_ACTIVE)
                }}
              >
                Активировать
              </DropdownItem>
            )}
            {status !== STORE_STATUS_PENDING && (
              <DropdownItem
                href='#pablo'
                onClick={e => {
                  e.preventDefault()
                  handleUpdateStatus(id, STORE_STATUS_PENDING)
                }}
              >
                На модерацию
              </DropdownItem>
            )}
            {status !== STORE_STATUS_DECLINED && (
              <DropdownItem
                href='#pablo'
                onClick={e => {
                  e.preventDefault()
                  handleUpdateStatus(id, STORE_STATUS_DECLINED)
                }}
              >
                Отклонить
              </DropdownItem>
            )}
            {status !== STORE_STATUS_ARCHIVED && (
              <DropdownItem
                href='#pablo'
                onClick={e => {
                  e.preventDefault()
                  handleUpdateStatus(id, STORE_STATUS_ARCHIVED)
                }}
              >
                Архивировать
              </DropdownItem>
            )}
            {status !== STORE_STATUS_BLOCKED && (
              <DropdownItem
                href='#pablo'
                onClick={e => {
                  e.preventDefault()
                  handleUpdateStatus(id, STORE_STATUS_BLOCKED)
                }}
              >
                Заблокировать
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    )
  }

  function getSubsText(item) {
    const lastSubs = prop('last_subs', item)
    const unlimited = prop('unlimited', item)
    const formatter = Intl.DateTimeFormat('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

    if (lastSubs) {
      const startDate = prop('start_date', lastSubs)
      const endDate = prop('end_date', lastSubs)
      return (
        <span style={{ color: COLOR_GREEN }}>
          {formatter.format(startDate) + ' - ' + formatter.format(endDate)}
        </span>
      )
    } else if (unlimited) {
      return <span style={{ color: COLOR_BLUE }}>VIP</span>
    } else {
      return <span style={{ color: COLOR_RED }}>Нет подписки</span>
    }
  }

  function handleStoreSuccess() {
    dispatch(submit('StoreCreateForm'))
  }

  const createTableRow = (item, index) => {
    return (
      <tr key={index} style={tableStyle} onClick={() => handleItemClick(prop('id', item))}>
        <th scope='row'>{prop('id', item)}</th>
        <td>{path(['store_name', 'ru'], item)}</td>
        <td>{prop('store_username', item)}</td>
        <td>{getStatusItem(prop('status', item))}</td>
        <td>
          {(prop('brand_image', item) === null && <p>Нет лого</p>) || (
            <img
              src={path(['brand_image', 'url'], item)}
              style={{
                width: '52px',
                height: '52px',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          )}
        </td>
        <td>{getSubsText(item)}</td>
        <td>{getActionDropdown(item)}</td>
      </tr>
    )
  }

  let tableContent = map((item, index) => {
    return createTableRow(item, index)
  }, listContent || [])

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Row>
          <div className='col'>
            <Card className='shadow'>
              <CardHeader className='border-0'>
                <Row className='align-items-center'>
                  <Col xs='9'>
                    <h3 className='mb-0'>Магазины</h3>
                  </Col>

                  <Col className='text-right' xs='3'>
                    <TableSearch history={history} pathname={pathname} query={query} />
                  </Col>
                </Row>
              </CardHeader>

              <Table
                header={tableHeader}
                content={
                  (tableContent.length > 0 && tableContent) || (
                    <tr>
                      <td colSpan='5' align='center'>
                        {(storeUpdateStatusLoading === false && 'Нету элементов') || (
                          <LoadingContent minHeight={'56px'} color={'#24bc79'} />
                        )}
                      </td>
                    </tr>
                  )
                }
              />

              {content && (
                <StoreModal
                  content={content}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  onStoreSaved={() => {
                    dispatch(actions.storesListClear())
                    setTimeout(function () {
                      dispatch(
                        actions.storesList({
                          page: currentPage - 1,
                        })
                      )
                    }, 500)
                  }}
                  rubricActiveList={rubricActiveList}
                  form={form}
                  handleStoreSuccess={handleStoreSuccess}
                />
              )}

              <CardFooter className='py-4'>
                <nav aria-label='...'>
                  <TablePagination
                    totalElements={listLength}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    last={isLastPage}
                    onChange={handlePagination}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default enhance(StoresList)
