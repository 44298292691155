import createAction from '../../../helpers/createAction'
import {
  CATEGORY_FIELD_CREATE,
  CATEGORY_FIELD_DELETE,
  CATEGORY_FIELD_COPY,
} from '../../actionTypes'

export default {
  [CATEGORY_FIELD_CREATE]: createAction(CATEGORY_FIELD_CREATE),
  [CATEGORY_FIELD_DELETE]: createAction(CATEGORY_FIELD_DELETE),
  [CATEGORY_FIELD_COPY]: createAction(CATEGORY_FIELD_COPY),
}

export { CATEGORY_FIELD_CREATE, CATEGORY_FIELD_DELETE, CATEGORY_FIELD_COPY }
