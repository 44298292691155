import { prop, equals, length, dissoc } from 'ramda'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InputGroup, InputGroupAddon, Input, Button } from 'reactstrap'
import queryString from 'query-string'

function TableSearch({ history, pathname, query }) {
  const [state, setState] = useState(prop('search', query))
  const stateLength = length(state)

  function handleOnChange(event) {
    const value = event.target.value

    setState(value)
  }

  function handleSearch() {
    if (equals(stateLength, 0)) {
      return history.push({
        pathname,
        search: queryString.stringify({
          ...dissoc('search', query),
        }),
      })
    }

    return history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        search: state,
      }),
    })
  }

  return (
    <InputGroup>
      <Input
        placeholder='Поиск по названию'
        defaultValue={prop('search', query)}
        onChange={handleOnChange}
        type='text'
      />

      <InputGroupAddon addonType='append'>
        <Button color='primary' onClick={handleSearch}>
          Поиск
        </Button>
      </InputGroupAddon>
    </InputGroup>
  )
}

TableSearch.propTypes = {
  history: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  query: PropTypes.object,
}

export default TableSearch
