import { Button, Modal } from 'reactstrap'
import React from 'react'
import UpdateProfileForm from '../Profile/UpdateProfileForm'

function UpdateProfileModal(props) {
  const { modalIsOpened, setModalClose, handleSubmit, handleSuccess } = props

  return (
    <Modal
      className='modal-dialog-centered'
      size='xl'
      style={{ maxWidth: '600px', width: '90%' }}
      isOpen={modalIsOpened}
      toggle={() => setModalClose()}
    >
      <div className='modal-header'>
        <h4 className='modal-title' id='modal-title-default'>
          Изменить пароль
        </h4>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={() => setModalClose()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className='modal-body'>
        <UpdateProfileForm initialValues={{}} onSubmit={handleSubmit} />
      </div>
      <div className='modal-footer'>
        <Button color='primary' className='ml-auto' type='button' onClick={handleSuccess}>
          Сохранить
        </Button>
      </div>
    </Modal>
  )
}

export default UpdateProfileModal
