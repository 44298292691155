import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { createOne, updateOne, deleteOne, copyFromRubric } from '../../../api/rubric_field'
import actionType, {
  RUBRIC_FIELD_CREATE,
  RUBRIC_FIELD_DELETE,
  RUBRIC_FIELD_COPY,
} from './actionTypes'
import * as actions from '../../rubric/actions'

function* rubricFieldCreateRequest() {
  yield takeEvery(actionType[RUBRIC_FIELD_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? updateOne : createOne
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[RUBRIC_FIELD_CREATE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[RUBRIC_FIELD_CREATE].error,
        error,
      })
    }
  })
}

function* rubricFieldCreateSuccess() {
  yield takeEvery(actionType[RUBRIC_FIELD_CREATE].success, function* ({ args }) {
    const parent_id = prop('parent_id', args)
    yield put(
      actions.rubric({
        id: parent_id,
      })
    )
  })
}

function* rubricFieldDeleteRequest() {
  yield takeEvery(actionType[RUBRIC_FIELD_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(deleteOne, payload)

      yield put({
        type: actionType[RUBRIC_FIELD_DELETE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[RUBRIC_FIELD_DELETE].error,
        error,
      })
    }
  })
}

function* rubricFieldDeleteSuccess() {
  yield takeEvery(actionType[RUBRIC_FIELD_DELETE].success, function* ({ args }) {
    const rubric_id = prop('rubric_id', args)
    yield put(
      actions.rubric({
        id: rubric_id,
      })
    )
  })
}

function* rubricFieldCopyRequest() {
  yield takeEvery(actionType[RUBRIC_FIELD_COPY].request, function* ({ payload }) {
    try {
      const { data } = yield call(copyFromRubric, payload)

      yield put({
        type: actionType[RUBRIC_FIELD_COPY].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[RUBRIC_FIELD_COPY].error,
        error,
      })
    }
  })
}

function* rubricFieldCopySuccess() {
  yield takeEvery(actionType[RUBRIC_FIELD_COPY].success, function* ({ args }) {
    const rubric_id = prop('rubric_id', args)
    yield put(
      actions.rubric({
        id: rubric_id,
      })
    )
  })
}

export default function* rootSaga() {
  yield all([
    fork(rubricFieldCreateRequest),
    fork(rubricFieldCreateSuccess),
    fork(rubricFieldDeleteRequest),
    fork(rubricFieldDeleteSuccess),
    fork(rubricFieldCopyRequest),
    fork(rubricFieldCopySuccess),
  ])
}
