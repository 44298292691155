import { compose, path, prop, map, pathOr } from 'ramda'
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Container, Row, Col, Card, CardHeader, Button } from 'reactstrap'
import Header from '../components/Headers/Header'
import Table from '../components/Table'
import * as route from '../constants/routes'
import * as actions from '../redux/actions'
import { hasAnyRole } from '../helpers/get'
import { ADMIN, MODERATOR } from '../constants/userRole'
import LoadingContent from '../components/Placeholder/LoadingContent'

const tableHeader = [
  {
    title: 'ID',
  },
  {
    title: 'Наименование',
  },
  {
    title: 'Статус',
  },
]

const enhance = compose(
  connect(({ rubricList, userInfo, router }) => {
    const list = prop('data', rubricList)

    //paged data
    // const listContent = prop('content', list)

    //all data
    const listContent = list

    const loading = prop('loading', rubricList)

    const roles = pathOr([], ['data', 'roles'], userInfo)

    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    const refresh = prop('refresh', query) || 'false'

    return {
      list,
      listContent,
      loading,
      roles,
      pathname,
      query,
    }
  })
)

function RubricList(props) {
  const { history, pathname, query, loading, listContent, roles } = props

  const refresh = prop('listRefresh', query) || 'false'

  if (refresh === 'true') {
    props.dispatch(actions.rubricListClear())
    history.replace(route.ADMIN_RUBRIC_LIST)
  }

  useEffect(() => {
    props.dispatch(actions.rubricList())
  }, [])

  function handleRubric(id = 0) {
    history.push({
      pathname: route.ADMIN_RUBRIC,
      search: queryString.stringify({
        id,
      }),
      state: {
        from: pathname,
      },
    })
  }

  function getDeletedItem(deleted) {
    return (
      <span className='badge badge-dot mr-4'>
        <i className={(deleted && 'bg-danger') || 'bg-success'}></i>
        <span className='status'>{(deleted && 'отключено') || 'активен'}</span>
      </span>
    )
  }

  const tableContent = map(item => {
    const style = {
      cursor: 'pointer',
    }

    return (
      <tr key={prop('id', item)} style={style} onClick={() => handleRubric(prop('id', item))}>
        <th scope='row'>{prop('id', item)}</th>
        <td>{path(['title', 'ru'], item)}</td>
        <td>{getDeletedItem(prop('deleted', item))}</td>
      </tr>
    )
  }, listContent || [])

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Row>
          <div className='col'>
            <Card className='shadow'>
              <CardHeader className='border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Рубрики</h3>
                  </Col>
                  <Col className='text-right' xs='4'>
                    {hasAnyRole(roles, [ADMIN, MODERATOR]) && (
                      <Button size='sm' color='primary' onClick={() => handleRubric()}>
                        Создать
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <Table
                header={tableHeader}
                content={
                  (tableContent.length > 0 && tableContent) || (
                    <tr>
                      <td colSpan='5' align='center'>
                        {(loading === false && 'Нету элементов') || (
                          <LoadingContent minHeight={'56px'} color={'#24bc79'} />
                        )}
                      </td>
                    </tr>
                  )
                }
              />
              {/*<Table*/}
              {/*  header={tableHeader}*/}
              {/*  content={*/}
              {/*    loading &&*/}
              {/*    <td colSpan='5' align='center'><LoadingContent*/}
              {/*      minHeight={'56px'}*/}
              {/*      color={"#24bc79"}*/}
              {/*    /></td> || tableContent.length > 0 && tableContent ||*/}
              {/*    <td colSpan='5' align='center'>Нету элементов</td>}*/}
              {/*/>*/}
            </Card>
          </div>
          }
        </Row>
      </Container>
    </Fragment>
  )
}

export default enhance(RubricList)
