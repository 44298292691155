import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import {
  createOne,
  updateOne,
  deleteOne,
  copyFromSubcategory,
} from '../../../api/subcategory_field'
import actionType, {
  SUBCATEGORY_FIELD_CREATE,
  SUBCATEGORY_FIELD_DELETE,
  SUBCATEGORY_FIELD_COPY,
} from './actionTypes'
import * as actions from '../../subcategory/actions'

function* subcategoryFieldCreateRequest() {
  yield takeEvery(actionType[SUBCATEGORY_FIELD_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? updateOne : createOne
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[SUBCATEGORY_FIELD_CREATE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[SUBCATEGORY_FIELD_CREATE].error,
        error,
      })
    }
  })
}

function* subcategoryFieldCreateSuccess() {
  yield takeEvery(actionType[SUBCATEGORY_FIELD_CREATE].success, function* ({ args }) {
    const parent_id = prop('parent_id', args)
    yield put(
      actions.subcategory({
        id: parent_id,
      })
    )
  })
}

function* subcategoryFieldDeleteRequest() {
  yield takeEvery(actionType[SUBCATEGORY_FIELD_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(deleteOne, payload)

      yield put({
        type: actionType[SUBCATEGORY_FIELD_DELETE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[SUBCATEGORY_FIELD_DELETE].error,
        error,
      })
    }
  })
}

function* subcategoryFieldDeleteSuccess() {
  yield takeEvery(actionType[SUBCATEGORY_FIELD_DELETE].success, function* ({ args }) {
    const subcategoryId = prop('subcategory_id', args)
    yield put(
      actions.subcategory({
        id: subcategoryId,
      })
    )
  })
}

function* subcategoryFieldCopyRequest() {
  yield takeEvery(actionType[SUBCATEGORY_FIELD_COPY].request, function* ({ payload }) {
    try {
      const { data } = yield call(copyFromSubcategory, payload)

      yield put({
        type: actionType[SUBCATEGORY_FIELD_COPY].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[SUBCATEGORY_FIELD_COPY].error,
        error,
      })
    }
  })
}

function* subcategoryFieldCopySuccess() {
  yield takeEvery(actionType[SUBCATEGORY_FIELD_COPY].success, function* ({ args }) {
    const subcategoryId = prop('subcategory_id', args)

    yield put(
      actions.subcategory({
        id: subcategoryId,
      })
    )
  })
}

export default function* rootSaga() {
  yield all([
    fork(subcategoryFieldCreateRequest),
    fork(subcategoryFieldCreateSuccess),
    fork(subcategoryFieldDeleteRequest),
    fork(subcategoryFieldDeleteSuccess),
    fork(subcategoryFieldCopyRequest),
    fork(subcategoryFieldCopySuccess),
  ])
}
