import createAction from '../../helpers/createAction'
import { STORES_LIST, STORES_LIST_GET, STORES_LIST_NOTIFICATION } from '../actionTypes'

export default {
  [STORES_LIST]: createAction(STORES_LIST),
  [STORES_LIST_GET]: createAction(STORES_LIST_GET),
  [STORES_LIST_NOTIFICATION]: createAction(STORES_LIST_NOTIFICATION),
}

export { STORES_LIST, STORES_LIST_GET, STORES_LIST_NOTIFICATION }
