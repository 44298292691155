import actionType, { COUNTRY, COUNTRY_CREATE, COUNTRY_DELETE } from './actionTypes'

export const country = payload => ({
  type: actionType[COUNTRY].request,
  payload,
})

export const countryClear = payload => ({
  type: actionType[COUNTRY].clear,
  payload,
})

export const countryCreate = payload => ({
  type: actionType[COUNTRY_CREATE].request,
  payload,
})

export const countryDelete = payload => ({
  type: actionType[COUNTRY_DELETE].request,
  payload,
})
