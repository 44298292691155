import { prop, dissoc } from 'ramda'
import { AuthApi } from '../helpers/httpClients'
import { PAGE } from '../constants/api'

export const getAll = data => AuthApi().get(PAGE + '/all', data)

//Without deleted ones
// export const getAll= data => AuthApi()
//   .get(PAGE + '/', data)

export const getOne = data => AuthApi().get(`${PAGE}/${prop('id', data)}`, dissoc('id', data))

export const createOne = data => AuthApi().post(PAGE + '/', data)

export const updateOne = data => AuthApi().put(`${PAGE}/${prop('id', data)}`, data)

export const deleteOne = data => AuthApi().delete(`${PAGE}/${prop('id', data)}`)

export const activateOne = data => AuthApi().put(`${PAGE}/activate/${prop('id', data)}`)
