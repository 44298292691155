import { Button, Modal } from 'reactstrap'
import SubCategoryFieldOptionCreateForm from '../Categories/SubCategoryFieldOptionCreateForm'
import React from 'react'

function FieldOptionsModal(props) {
  const {
    optionModalIsOpened,
    setOptionModalIsOpened,
    fieldOptionInitialValues,
    handleFieldOptionSubmit,
    fieldOptionFormValues,
    setCurrentOptions,
    currentOptions,
    handleFieldOptionSuccess,
  } = props

  function onDeleteOptionClicked() {
    const optionId = fieldOptionFormValues['id']
    if (optionId) {
      setCurrentOptions(currentOptions.filter(option => option.id !== optionId))
    }
    setOptionModalIsOpened(false)
  }

  return (
    <Modal
      className='modal-dialog-centered'
      size='sm'
      isOpen={optionModalIsOpened}
      toggle={() => setOptionModalIsOpened(false)}
    >
      <div className='modal-header'>
        <h4 className='modal-title' id='modal-title-default'>
          Вариант
        </h4>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={() => setOptionModalIsOpened(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className='modal-body'>
        <SubCategoryFieldOptionCreateForm
          initialValues={fieldOptionInitialValues}
          onSubmit={handleFieldOptionSubmit}
        />
      </div>
      <div className='modal-footer'>
        <Button color='danger' type='button' onClick={onDeleteOptionClicked}>
          Удалить
        </Button>
        <Button
          color='primary'
          className='ml-auto'
          type='button'
          onClick={handleFieldOptionSuccess}
        >
          Сохранить
        </Button>
      </div>
    </Modal>
  )
}

export default FieldOptionsModal
