import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import createState from '../helpers/createState'
import * as actionTypes from './actionTypes'
import * as state from './state'

const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    toastr: toastrReducer,
    [state.TOKEN]: createState(actionTypes.TOKEN),
    [state.USER_INFO]: createState(actionTypes.USER_INFO),
    [state.COUNTRY]: createState(actionTypes.COUNTRY),
    [state.COUNTRY_LIST]: createState(actionTypes.COUNTRY_LIST),
    [state.REGION]: createState(actionTypes.REGION),
    [state.REGION_LIST]: createState(actionTypes.REGION_LIST),
    [state.CITY]: createState(actionTypes.CITY),
    [state.CITY_LIST]: createState(actionTypes.CITY_LIST),
    [state.DISTRICT]: createState(actionTypes.DISTRICT),
    [state.DISTRICT_LIST]: createState(actionTypes.DISTRICT_LIST),
    [state.RUBRIC]: createState(actionTypes.RUBRIC),
    [state.RUBRIC_LIST]: createState(actionTypes.RUBRIC_LIST),
    [state.RUBRIC_FIELD]: createState(actionTypes.RUBRIC_FIELD_CREATE),
    [state.RUBRIC_ACTIVE_LIST]: createState(actionTypes.RUBRIC_ACTIVE_LIST),
    [state.CATEGORY]: createState(actionTypes.CATEGORY),
    [state.CATEGORY_LIST]: createState(actionTypes.CATEGORY_LIST),
    [state.CATEGORY_FIELD]: createState(actionTypes.CATEGORY_FIELD_CREATE),
    [state.PAGE_CATEGORY_LIST]: createState(actionTypes.PAGE_CATEGORY_LIST),
    [state.PAGE_CATEGORY]: createState(actionTypes.PAGE_CATEGORY),
    [state.PAGES_LIST]: createState(actionTypes.PAGES_LIST),
    [state.PAGE]: createState(actionTypes.PAGE),
    [state.SUBCATEGORY]: createState(actionTypes.SUBCATEGORY),
    [state.SUBCATEGORY_FIELD]: createState(actionTypes.SUBCATEGORY_FIELD_CREATE),
    [state.ADVERTS_LIST]: createState(actionTypes.ADVERTS_LIST),
    [state.ADVERT]: createState(actionTypes.ADVERT),
    [state.ADVERT_UPDATE_STATUS]: createState(actionTypes.ADVERT_UPDATE_STATUS),
    [state.STORES_LIST]: createState(actionTypes.STORES_LIST),
    [state.STORE]: createState(actionTypes.STORE),
    [state.STORE_CREATE]: createState(actionTypes.STORE_CREATE),
    [state.STORE_UPDATE_STATUS]: createState(actionTypes.STORE_UPDATE_STATUS),
    [state.SOCKET]: createState(actionTypes.SOCKET),
    [state.COMPLAIN_LIST]: createState(actionTypes.COMPLAIN_LIST),
    [state.USERS_ALL]: createState(actionTypes.USERS_ALL),
  })

export default reducers
