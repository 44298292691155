import { pipe, prop, map, prepend } from 'ramda'
import React, { Fragment } from 'react'
import { FormGroup, InputGroup, Input } from 'reactstrap'
import NumberFormat from 'react-number-format'

function NumberField(props) {
  const {
    input,
    placeholder,
    defaultValue,
    addon,
    addonAfter,
    options,
    disabled,
    inputFormat,
    inputMask,
    formGroupClassName,
    formGroupStyle,
    inputGroup,
    inputGroupClassName,
    inputClassName,
  } = props

  // inputGroupClassName: input-group-alternative
  // inputClassName: form-control-alternative
  const option = pipe(
    prepend({ value: 'default', key: 0 }),
    map(item => (
      <option key={prop('key', item)} value={prop('key', item)}>
        {prop('value', item)}
      </option>
    ))
  )(options || [])

  return (
    <Fragment>
      <FormGroup style={formGroupStyle} className={formGroupClassName}>
        {inputGroup && (
          <InputGroup className={inputGroupClassName || 'input-group-alternative'}>
            {addon}
            <NumberFormat
              {...input}
              customInput={Input}
              format={inputFormat}
              mask={inputMask}
              disabled={disabled}
              className={inputClassName || 'form-control-alternative'}
              value={defaultValue || prop('value', input)}
              placeholder={placeholder}
              onValueChange={prop('onChange', input)}
            >
              {options && option}
            </NumberFormat>
            {addonAfter}
            {props.meta.touched && props.meta.error && (
              <p style={{ color: 'red', fontSize: '12px' }}> {props.meta.error} </p>
            )}
          </InputGroup>
        )}

        {!inputGroup && (
          <Fragment>
            {addon}
            <NumberFormat
              {...input}
              customInput={Input}
              format='+998 (##) ###-##-##'
              mask='_'
              disabled={disabled}
              className={inputClassName || 'form-control-alternative'}
              value={defaultValue || prop('value', input)}
              placeholder={placeholder}
              onValueChange={prop('onChange', input)}
            >
              {options && option}
            </NumberFormat>
            {addonAfter}
            {props.meta.touched && props.meta.error && (
              <p style={{ color: 'red', fontSize: '12px' }}> {props.meta.error} </p>
            )}
          </Fragment>
        )}
      </FormGroup>
    </Fragment>
  )
}

export default NumberField
