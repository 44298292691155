// import React from "react";
import ControlledEditor from './ControlledEditor'

// const EditorFieldComponent = props => {
//     const { placeholder, input: { onChange, value }, disabled, id } = props;
//
//     return (
//         <ControlledEditor
//             id={id}
//             disabled={disabled}
//             placeholder={placeholder}
//             onChange={onChange}
//             value={value}
//         />
//     );
// };
//
// export default EditorFieldComponent;

import { pipe, prop, map, prepend } from 'ramda'
import React, { Fragment } from 'react'
import { FormGroup } from 'reactstrap'
import { ContentState, convertFromHTML, EditorState } from 'draft-js'

function EditorFieldComponent(props) {
  const {
    placeholder,
    input: { onChange, value },
    id,
    initialValue,
    addon,
    addonAfter,
    disabled,
    formGroupClassName,
    formGroupStyle,
  } = props

  return (
    <Fragment>
      <FormGroup style={formGroupStyle} className={formGroupClassName}>
        <Fragment>
          {addon}
          <ControlledEditor
            id={id}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            initialValue={initialValue}
          />

          {addonAfter}
        </Fragment>
      </FormGroup>
    </Fragment>
  )
}

export default EditorFieldComponent
