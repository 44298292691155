import { pipe, prop, map, prepend } from 'ramda'
import React, { Fragment } from 'react'
import { FormGroup, InputGroup, Input } from 'reactstrap'
import { Field } from 'redux-form'

function InputField(props) {
  const {
    input,
    onKeyPress,
    type,
    placeholder,
    defaultValue,
    rows,
    addon,
    addonAfter,
    options,
    multiple,
    disabled,
    formGroupClassName,
    formGroupStyle,
    inputGroup,
    inputGroupClassName,
    inputClassName,
  } = props

  // inputGroupClassName: input-group-alternative
  // inputClassName: form-control-alternative
  const option = pipe(
    prepend({ value: 'default', key: 0 }),
    map(item => (
      <option key={prop('key', item)} value={prop('key', item)}>
        {prop('value', item)}
      </option>
    ))
  )(options || [])

  return (
    <Fragment>
      <FormGroup style={formGroupStyle} className={formGroupClassName}>
        {inputGroup && (
          <InputGroup className={inputGroupClassName || 'input-group-alternative'}>
            {addon}
            <Input
              {...input}
              pattern='[0-9\.]+'
              disabled={disabled}
              value={defaultValue || prop('value', input)}
              onKeyPress={onKeyPress}
              type={type}
              rows={rows}
              multiple={multiple}
              placeholder={placeholder}
              onChange={prop('onChange', input)}
            >
              {options && option}
            </Input>
            {addonAfter}
          </InputGroup>
        )}

        {!inputGroup && (
          <Fragment>
            {addon}
            <Input
              {...input}
              disabled={disabled}
              onKeyPress={onKeyPress}
              className={inputClassName || 'form-control-alternative'}
              value={defaultValue || prop('value', input)}
              type={type}
              rows={rows}
              multiple={multiple}
              placeholder={placeholder}
              onChange={prop('onChange', input)}
            >
              {options && option}
            </Input>
            {addonAfter}
          </Fragment>
        )}

        {props.meta.touched && props.meta.error && (
          <p style={{ color: 'red', fontSize: '12px' }}> {props.meta.error} </p>
        )}
      </FormGroup>
    </Fragment>
  )
}

export default InputField
