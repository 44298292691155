export const TOKEN = 'token'
export const SIGN_IN = 'signIn'
export const USER_INFO = 'userInfo'

export const COUNTRY = 'country'
export const COUNTRY_LIST = 'countryList'

export const REGION = 'region'
export const REGION_LIST = 'regionList'

export const CITY = 'city'
export const CITY_LIST = 'cityList'

export const DISTRICT = 'district'
export const DISTRICT_LIST = 'districtList'

export const RUBRIC = 'rubric'
export const RUBRIC_LIST = 'rubricList'
export const RUBRIC_ACTIVE_LIST = 'rubricActiveList'
export const RUBRIC_FIELD = 'rubricField'
export const SUBCATEGORY = 'subcategory'
export const SUBCATEGORY_FIELD = 'subcategoryField'
export const CATEGORY = 'category'
export const CATEGORY_LIST = 'categoryList'
export const CATEGORY_FIELD = 'categoryField'

export const PAGE_CATEGORY_LIST = 'pageCategoryList'
export const PAGE_CATEGORY = 'pageCategory'

export const ADVERTS_LIST = 'advertsList'
export const ADVERT = 'advert'
export const ADVERT_UPDATE_STATUS = 'advertUpdateStatus'

export const STORES_LIST = 'storesList'
export const STORE = 'store'
export const STORE_CREATE = 'store-create'
export const STORE_UPDATE_STATUS = 'storeUpdateStatus'

export const USERS_ALL = 'usersList'

export const PAGES_LIST = 'pagesList'
export const PAGE = 'page'

export const SOCKET = 'socket'

export const COMPLAIN_LIST = 'complainList'
