import createReducer from './createReducer'

export const defaultState = {
  data: null,
  error: null,
  loading: false,
  failed: false,
}

const createState = (actionName, state = defaultState) => {
  return createReducer(defaultState, {
    [`${actionName}_REQUEST`](state, action) {
      return {
        ...state,
        loading: true,
      }
    },
    [`${actionName}_SUCCESS`](state, action) {
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false,
        failed: false,
      }
    },
    [`${actionName}_ERROR`](state, action) {
      return {
        ...state,
        data: null,
        error: action.payload,
        loading: false,
        failed: true,
      }
    },
    [`${actionName}_CLEAR`]() {
      return defaultState
    },
  })
}

export default createState
