import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { getAll } from '../../api/pageCategory'
import actionType, { PAGE_CATEGORY_LIST } from './actionTypes'

function* getAllRequest() {
  yield takeEvery(actionType[PAGE_CATEGORY_LIST].request, function* ({ payload }) {
    try {
      const { data } = yield call(getAll, payload)

      yield put({
        type: actionType[PAGE_CATEGORY_LIST].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[PAGE_CATEGORY_LIST].error,
        error,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(getAllRequest)])
}
