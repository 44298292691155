//Release
export const API_HOST = process.env.API_HOST || 'baraka.uz'
export const API_HOST_WS = process.env.API_HOST || 'baraka.uz'

//Localhost
// export const API_HOST = process.env.API_HOST || '192.168.100.60:9011'
// export const API_HOST_WS = process.env.API_HOST || '192.168.100.60:9010'

export const API_PROJECT = 'baraka'
export const API_ROOT = 'api'
export const API_VERSION = 'v1'
export const API_URL = `https://${API_HOST}/${API_PROJECT}/${API_ROOT}/${API_VERSION}`
// export const API_URL = `/${API_PROJECT}/${API_ROOT}/${API_VERSION}`

// Socket
// export const SOCKET_HOST = process.env.SOCKET_HOST || '6d3ecbe2.ngrok.io'
// export const SOCKET_PROJECT = 'baraka'
export const SOCKET_ROOT = 'socket/messages'
export const SOCKET_URL = `wss://${API_HOST_WS}/${SOCKET_ROOT}`
export const SOCKET_SUBSCRIPTION_URL = '/topic/notification'


// Adverts
export const ADVERT = 'admin/product'


// Attachment
export const FILE = '/media'
export const FILE_UPLOAD = `${FILE}/react/upload`
export const FILE_UPLOAD_SINGLE = `${FILE_UPLOAD}/single`
export const FILE_DOWNLOAD = `${FILE}/download`
export const FILE_DELETE = `${FILE}/delete`

// New Attachment
export const NEW_FILE_UPLOAD_REACT = '/media/upload/react'
export const NEW_FILE_UPLOAD_REACT_URL = `${API_URL}${NEW_FILE_UPLOAD_REACT}`

export const FILE_UPLOAD_REACT = `${FILE}/react/upload/`
export const FILE_UPLOAD_MULTIPLE = `${FILE_UPLOAD_REACT}multiple`
export const FILE_UPLOAD_REACT_URL = `${API_URL}${FILE_UPLOAD_REACT}`
export const FILE_UPLOAD_REACT_SINGLE_URL = `${API_URL}${FILE_UPLOAD_SINGLE}`
export const FILE_UPLOAD_REACT_MULTIPLE_URL = `${API_URL}${FILE_UPLOAD_MULTIPLE}`
export const FILE_UPDATE = `${API_URL}/media/rotate`

// Authorization
export const SIGN_IN = '/signin'

// User
export const USER = 'user'
export const USER_ADMIN = `${USER}/admin`
export const USER_USER = `${USER}/user`
export const USER_ME_INFO = 'admin/me/info'
export const USER_UPDATE_PASSWORD = `${USER}/password/update`

// Country
export const COUNTRY = 'country'

// Region
export const REGION = 'region'

// District
export const DISTRICT = 'district'

// City
export const CITY = 'city'

// Rubric
export const RUBRIC = 'rubric'

export const RUBRIC_FIELD = `${RUBRIC}/field`

// Category
export const CATEGORY = 'category'

// Category
export const CATEGORY_FIELD = `${CATEGORY}/field`

// Subcategory
export const SUBCATEGORY = 'subcategory'

// Subcategory
export const SUBCATEGORY_FIELD = `${SUBCATEGORY}/field`

// Page Category (Collections)
export const PAGE_CATEGORY = 'page/category'

// Page (Html content pages)
export const PAGE = 'page'

// Store
export const STORE = 'admin/store'

// Complain
export const COMPLAIN = 'admin/complain/'

//Users

export const USERS_ALL = 'admin/user/all'
export const GET_USER = 'admin/user/'
export const USER_BLOCK = 'admin/user/block/'
export const USER_UNBLOCK = 'admin/user/unblock/'
export const USER_DELETE_PERMANENT = 'admin/user/delete/permanent/'
