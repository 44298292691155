import actionType, { RUBRIC_LIST, RUBRIC_ACTIVE_LIST } from './actionTypes'
import { RUBRIC } from '../actionTypes'

export const rubricList = payload => ({
  type: actionType[RUBRIC_LIST].request,
  payload,
})

export const rubricListClear = payload => ({
  type: actionType[RUBRIC_LIST].clear,
  payload,
})

export const rubricActiveList = payload => ({
  type: actionType[RUBRIC_ACTIVE_LIST].request,
  payload,
})
