import actionType, {
  CATEGORY,
  CATEGORY_ACTIVATE,
  CATEGORY_CREATE,
  CATEGORY_DELETE,
} from './actionTypes'

export const category = payload => ({
  type: actionType[CATEGORY].request,
  payload,
})

export const categoryClear = payload => ({
  type: actionType[CATEGORY].clear,
  payload,
})

export const categoryCreate = payload => ({
  type: actionType[CATEGORY_CREATE].request,
  payload,
})

export const categoryDelete = payload => ({
  type: actionType[CATEGORY_DELETE].request,
  payload,
})

export const categoryActivate = payload => ({
  type: actionType[CATEGORY_ACTIVATE].request,
  payload,
})
