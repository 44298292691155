import { prop } from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'

const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
  ],
  // toolbar: {
  //   container: "#toolbar",
  //   handlers: {
  //     insertStar: insertStar
  //   }
  // },
}

const CustomButton = () => <span className='octicon octicon-star' />

/*
 * Custom toolbar component including insertStar button and dropdowns
 */
// const CustomToolbar = () => (
//   <div id="toolbar">
//     <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
//       <option value="1"/>
//       <option value="2"/>
//       <option selected/>
//     </select>
//     <button className="ql-bold"/>
//     <button className="ql-italic"/>
//     <button className="ql-font"/>
//     <button className="ql-script"/>
//
//
//     <select className="ql-color">
//       <option value="red"/>
//       <option value="green"/>
//       <option value="blue"/>
//       <option value="orange"/>
//       <option value="violet"/>
//       <option value="#d0d1d2"/>
//       <option selected/>
//     </select>
//     <button className="ql-insertStar">
//       <CustomButton/>
//     </button>
//   </div>
// );

function insertStar() {
  const cursorPosition = this.quill.getSelection().index
  this.quill.insertText(cursorPosition, '★')
  this.quill.setSelection(cursorPosition + 1)
}

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]

const editorStyleObj = {
  border: '0',
  fontSize: '0.875rem',
  boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
  borderRadius: '0.375rem',
  backgroundColor: '#ffffff',
  lineHeight: '1.5',
  marginBottom: '1.5rem',
  transition: 'box-shadow .15s ease',
  padding: '0.625rem 0.75rem',
  backgroundClip: 'padding-box',
}

function EditorField(props) {
  const { input, theme, addon } = props

  return (
    <div>
      {addon}
      {/*<CustomToolbar/>*/}
      <ReactQuill
        theme={theme}
        value={prop('value', input)}
        onChange={prop('onChange', input)}
        modules={quillModules}
        formats={quillFormats}
      />
    </div>
  )
}

EditorField.defaultProps = {
  theme: 'snow',
}

EditorField.propTypes = {
  input: PropTypes.object.isRequired,

  theme: PropTypes.string,
}

export default EditorField
