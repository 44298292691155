import { compose, path, prop, map } from 'ramda'
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Container, Row, Col, Card, CardHeader, Button } from 'reactstrap'
import Header from '../components/Headers/Header'
import Table from '../components/Table'
import * as route from '../constants/routes'
import * as actions from '../redux/actions'

const tableHeader = [
  {
    title: 'id',
  },
  {
    title: 'name',
  },
]

const enhance = compose(
  connect(({ districtList, router }) => {
    const list = prop('data', districtList)
    const loading = prop('loading', districtList)

    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    return {
      list,
      loading,
      pathname,
      query,
    }
  })
)

function DistrictList(props) {
  const { history, pathname, list } = props

  useEffect(() => {
    props.dispatch(actions.districtList())
  }, [])

  function handleDistrict(id = 0) {
    history.push({
      pathname: route.ADMIN_DISTRICT,
      search: queryString.stringify({
        id,
      }),
      state: {
        from: pathname,
      },
    })
  }

  const tableContent = map(item => {
    const style = {
      cursor: 'pointer',
    }

    return (
      <tr key={prop('id', item)} style={style} onClick={() => handleDistrict(prop('id', item))}>
        <th scope='row'>{prop('id', item)}</th>
        <td>{path(['name', 'ru'], item)}</td>
      </tr>
    )
  }, list || [])

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Row>
          <div className='col'>
            <Card className='shadow'>
              <CardHeader className='border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Districts</h3>
                  </Col>
                  <Col className='text-right' xs='4'>
                    <Button size='sm' color='primary' onClick={() => handleDistrict()}>
                      Создать
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table header={tableHeader} content={tableContent} />
            </Card>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default enhance(DistrictList)
