import { pipe, prop, length, map, find, propEq, dropLast, prepend, equals } from 'ramda'
import { all, fork, takeEvery, put, call, select } from 'redux-saga/effects'
import { getAll } from '../../api/advert'
import actionType, {
  ADVERTS_LIST,
  ADVERTS_LIST_GET,
  ADVERTS_LIST_NOTIFICATION,
} from './actionTypes'
import * as actions from '../actions'

function* advertListRequest() {
  yield takeEvery(actionType[ADVERTS_LIST].request, function* ({ payload }) {
    try {
      yield put({
        type: actionType[ADVERTS_LIST].success,
        payload,
      })
    } catch (error) {
      yield put({
        type: actionType[ADVERTS_LIST].error,
        error,
      })
    }
  })
}

function* advertListGetRequest() {
  yield takeEvery(actionType[ADVERTS_LIST_GET].request, function* ({ payload }) {
    try {
      const { data } = yield call(getAll, payload)

      yield put({
        type: actionType[ADVERTS_LIST_GET].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[ADVERTS_LIST_GET].error,
        error,
      })
    }
  })
}

function* advertListGetSuccess() {
  yield takeEvery(actionType[ADVERTS_LIST_GET].success, function* ({ payload }) {
    yield put(actions.advertListForSaga(payload))
  })
}

function* advertListNotificationRequest() {
  yield takeEvery(actionType[ADVERTS_LIST_NOTIFICATION].request, function* ({ payload }) {
    try {
      const {
        advertsList: { data },
      } = yield select()
      const list = prop('content', data)
      const listLength = length(list)

      const id = prop('id', payload)
      const inList = find(propEq('id', id), list)

      const addToList = pipe(dropLast(1), prepend(payload))(list)

      const dynamicAddToList = listLength < 5 ? [payload, ...list] : addToList

      const replaceItemToList = pipe(
        map(item => {
          if (equals(prop('id', item), id)) {
            return {
              ...payload,
            }
          }
          return item
        })
      )(list)

      const newContent = inList ? replaceItemToList : dynamicAddToList

      yield put({
        type: actionType[ADVERTS_LIST_NOTIFICATION].success,
        payload: {
          ...data,
          content: newContent,
        },
      })
    } catch (error) {
      yield put({
        type: actionType[ADVERTS_LIST_NOTIFICATION].error,
        error,
      })
    }
  })
}

function* advertListNotificationSuccess() {
  yield takeEvery(actionType[ADVERTS_LIST_NOTIFICATION].success, function* ({ payload }) {
    yield put(actions.advertListForSaga(payload))
  })
}

export default function* rootSaga() {
  yield all([
    fork(advertListRequest),
    fork(advertListGetRequest),
    fork(advertListGetSuccess),
    fork(advertListNotificationRequest),
    fork(advertListNotificationSuccess),
  ])
}
