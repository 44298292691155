import { prop, dissoc } from 'ramda'
import { NotAuthApi, AuthApi } from '../helpers/httpClients'
import { CITY } from '../constants/api'

export const cityList = data => AuthApi().get(CITY + '/', data)

export const cityGet = data => AuthApi().get(`${CITY}/${prop('id', data)}`, dissoc('id', data))

export const cityCreate = data => AuthApi().post(CITY + '/', data)

export const cityUpdate = data => AuthApi().put(`${CITY}/${prop('id', data)}`, data)

export const cityDelete = data => AuthApi().delete(`${CITY}/${prop('id', data)}`)
