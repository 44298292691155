import createAction from '../../helpers/createAction'
import { DISTRICT, DISTRICT_CREATE, DISTRICT_DELETE } from '../actionTypes'

export default {
  [DISTRICT]: createAction(DISTRICT),
  [DISTRICT_CREATE]: createAction(DISTRICT_CREATE),
  [DISTRICT_DELETE]: createAction(DISTRICT_DELETE),
}

export { DISTRICT, DISTRICT_CREATE, DISTRICT_DELETE }
