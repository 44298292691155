import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'
import { userMeInfo, updateUserPassword } from '../../api/users'
import * as ROUTE from '../../constants/routes'
import actionType, { USER_INFO, USER_UPDATE_PASSWORD } from './actionTypes'
import { removeToken } from '../../helpers/tokenStorage'

function* userInfoRequest() {
  yield takeEvery(actionType[USER_INFO].request, function* ({ payload }) {
    try {
      const { data } = yield call(userMeInfo)

      yield put({
        type: actionType[USER_INFO].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[USER_INFO].error,
        error,
      })
    }
  })
}

function* userInfoSuccess() {
  yield takeEvery(actionType[USER_INFO].success, function* ({ payload, args }) {
    const status = prop('status', payload)
    const type = prop('type', args)

    if (type === 'login') {
      yield put(push(ROUTE.ADMIN_DASHBOARD))
    }

    if (status === 0) {
      removeToken()
      toastr.error('Ошибка', 'Ваш аккаунт временно недоступен')
      yield put(push(ROUTE.AUTH_LOGIN))
    }

    if (status === -1) {
      removeToken()
      toastr.error('Ошибка', 'Ваш аккаунт заблокирован администратором')
      yield put(push(ROUTE.AUTH_LOGIN))
    }
  })
}

function* updatePasswordRequest() {
  yield takeEvery(actionType[USER_UPDATE_PASSWORD].request, function* ({ payload }) {
    try {
      const { data } = yield call(updateUserPassword, payload)

      yield put({
        type: actionType[USER_UPDATE_PASSWORD].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[USER_UPDATE_PASSWORD].error,
        error,
      })
    }
  })
}

function* updatePasswordSuccess() {
  yield takeEvery(actionType[USER_UPDATE_PASSWORD].success, function* ({ payload, args }) {
    removeToken()
    toastr.success('Пароль изменен', 'Ваш пароль успешно изменен. Войдите заново с новым паролем')
    yield put(push(ROUTE.AUTH_LOGIN))
  })
}

function* updatePasswordError() {
  yield takeEvery(actionType[USER_UPDATE_PASSWORD].error, function* ({ payload, args }) {
    toastr.error('Неверный пароль', 'Старый пароль, который вы ввели - неверный! Попробуйте снова')
  })
}

export default function* rootSaga() {
  yield all([
    fork(userInfoRequest),
    fork(userInfoSuccess),
    fork(updatePasswordRequest),
    fork(updatePasswordSuccess),
    fork(updatePasswordError),
  ])
}
