import { compose, path, prop } from 'ramda'
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap'
import Header from '../components/Headers/Header'
import CreateForm from '../components/Country/CountryCreateForm'
import * as route from '../constants/routes'
import * as actions from '../redux/actions'

const enhance = compose(
  connect(({ country, form, router }) => {
    const item = prop('data', country)
    const loading = prop('loading', country)

    const formValues = path(['CountryForm', 'values'], form)

    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    return {
      item,
      loading,
      formValues,
      pathname,
      query,
    }
  })
)

function Country(props) {
  const { history, pathname, query, item, formValues } = props
  const id = prop('id', query)

  useEffect(() => {
    if (!id) {
      history.push({
        pathname: route.ADMIN_COUNTRY_LIST,
        state: {
          from: pathname,
        },
      })
    }

    if (+id > 0) {
      props.dispatch(
        actions.country({
          id,
        })
      )
    }

    return () => {
      props.dispatch(actions.countryClear())
    }
  }, [])

  function handleSubmit() {
    props.dispatch(
      actions.countryCreate({
        id: +id > 0 ? id : undefined,
        ...formValues,
      })
    )
  }

  function handleSuccess() {
    props.dispatch(submit('CountryForm'))
  }

  function handleDelete() {
    props.dispatch(
      actions.countryDelete({
        id,
      })
    )
  }

  function handleBack() {
    history.push({
      pathname: route.ADMIN_COUNTRY_LIST,
      state: {
        from: pathname,
      },
    })
  }

  const initialValues = {
    name: prop('name', item),
    country_code: prop('country_code', item),
    phone_code: prop('phone_code', item),
  }

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Страна</h3>
                  </Col>
                  <Col xs='4' className='text-right'>
                    {+id > 0 && (
                      <Button size='sm' color='danger' onClick={handleDelete}>
                        Удалить
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <CreateForm initialValues={initialValues} onSubmit={handleSubmit} />
              </CardBody>

              <CardFooter className='text-right'>
                <Button onClick={handleBack}>Назад</Button>

                <Button color='primary' onClick={handleSuccess}>
                  Сохранить
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default enhance(Country)
