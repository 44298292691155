export const ROOT = '/'

export const AUTH = '/auth'
export const AUTH_LOGIN = `${AUTH}/login`
export const AUTH_REGISTER = `${AUTH}/register`

export const ADMIN = '/admin'
export const ADMIN_DASHBOARD = `${ADMIN}/dashboard`
export const ADMIN_COUNTRY = `${ADMIN}/country`
export const ADMIN_COUNTRY_LIST = `${ADMIN}/country-list`
export const ADMIN_REGION = `${ADMIN}/region`
export const ADMIN_REGION_LIST = `${ADMIN}/region-list`
export const ADMIN_CITY = `${ADMIN}/city`
export const ADMIN_CITY_LIST = `${ADMIN}/city-list`
export const ADMIN_DISTRICT = `${ADMIN}/district`
export const ADMIN_DISTRICT_LIST = `${ADMIN}/district-list`
export const ADMIN_RUBRIC = `${ADMIN}/rubric`
export const ADMIN_RUBRIC_LIST = `${ADMIN}/rubric-list`
export const ADMIN_CATEGORY = `${ADMIN}/category`
export const ADMIN_PAGE_CATEGORY = `${ADMIN}/page/category`
export const ADMIN_PAGE_CATEGORY_LIST = `${ADMIN}/page/category-list`
export const ADMIN_PRODUCTS_LIST = `${ADMIN}/products-list`
export const ADMIN_STORES_LIST = `${ADMIN}/stores-list`
export const ADMIN_PAGES_LIST = `${ADMIN}/pages-list`
export const ADMIN_PAGE = `${ADMIN}/page`
export const ADMIN_SUBCATEGORY = `${ADMIN}/subcategory`
export const ADMIN_COMPLAIN_LIST = `${ADMIN}/complain-list`
export const ADMIN_USERS_LIST = `${ADMIN}/users-list`
