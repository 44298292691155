import createAction from '../../helpers/createAction'
import { PAGE, PAGE_CREATE, PAGE_DELETE, PAGE_ACTIVATE } from '../actionTypes'

export default {
  [PAGE]: createAction(PAGE),
  [PAGE_CREATE]: createAction(PAGE_CREATE),
  [PAGE_DELETE]: createAction(PAGE_DELETE),
  [PAGE_ACTIVATE]: createAction(PAGE_ACTIVATE),
}

export { PAGE, PAGE_CREATE, PAGE_DELETE, PAGE_ACTIVATE }
