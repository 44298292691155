import React, { useState } from 'react'
import { Alert, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { Image as ImageKonva } from 'react-konva/lib/ReactKonvaCore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faRedoAlt, faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import Skeleton from 'react-loading-skeleton'

// const Image = props => {
//   const { deg, offsetY, offsetX, width, height, image, x, y } = props
//   return (
//     <ImageKonva
//       image={image}
//       x={x}
//       y={y}
//       rotation={deg}
//       offsetY={offsetY}
//       offsetX={offsetX}
//       width={width}
//       height={height}
//       listening={false}
//     />
//   )
// }

const ImgEditor = props => {
  const {
    modalIsOpened,
    img,
    setModalIsOpened,
    stageRef,
    saveRotatedImg,
    rotateUp,
    rotationData,
    loading,
    setLoading,
    url,
    alert,
    degree,
    setDegree,
    setAlert,
    setIndex,
  } = props

  // useEffect(() => {
  //   if (!img) {
  //     setLoading(true)
  //   } else {
  //     setLoading(false)
  //   }
  // }, [img])

  function handleRotation(deg) {
    const newDeg = degree + deg
      setDegree(newDeg < -360 ? -90 : newDeg > 360 ? 90 : newDeg)
  }

  return (
    <Modal
      contentClassName='custom__modal-width'
      isOpen={modalIsOpened}
      toggle={() => {
        setModalIsOpened(false)
        setDegree(0)
        loading && setLoading(false)
        alert && setAlert(false)
        setIndex(null)
      }}
    >
      <ModalBody className='custom-modal-body d-flex justify-content-center align-items-center'>
        {loading ? (
          <Skeleton height='100%' />
        ) : (
            <img src={url} alt='' style={{ transform: `rotate(${degree}deg)`, maxWidth: '320px', maxHeight: '320px' }} />
          // <Stage
          //   width={img && prop('stageW', rotationData)}
          //   height={img && prop('stageH', rotationData)}
          //   className='custom__canvas-wrapper '
          //   ref={stageRef}
          //   listening={false}
          // >
          //   <Layer listening={false}>
          //     <Image
          //       deg={img && prop('deg', rotationData)}
          //       x={img && prop('x', rotationData)}
          //       y={img && prop('y', rotationData)}
          //       offsetY={img && prop('offsetY', rotationData)}
          //       offsetX={img && prop('offsetX', rotationData)}
          //       width={img && prop('width', rotationData)}
          //       height={img && prop('height', rotationData)}
          //       degree={degree}
          //       image={img}
          //     />
          //   </Layer>
          // </Stage>
        )}
      </ModalBody>

      <div className='mb-4 mr-4 d-flex justify-content-end'>
        <div className='custom__faSyncAlt'>
          <FontAwesomeIcon
            icon={faUndoAlt}
            className='test'
            // color='black'
            onClick={() => {
              if (!loading) {
                handleRotation(-90)
              }
            }}
            style={{
              marginRight: '1em',
              marginTop: '0',
              cursor: 'pointer',
              width: '100%',
              height: '100%',
            }}
          />
          <FontAwesomeIcon
            icon={faRedoAlt}
            className='test'
            // color='black'
            onClick={() => {
              if (!loading) {
                handleRotation(90)
              }
            }}
            style={{
              marginLeft: '0.4em',
              marginTop: '0',
              cursor: 'pointer',
              width: '100%',
              height: '100%',
            }}
          />
        </div>

        <div className='custom__faSyncAlt'>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className='test'
            // color='black'
            onClick={e => {
              if (!loading) {
                setLoading(true)
                saveRotatedImg(degree)
              }
            }}
            style={{
              marginLeft: '0.4em',
              marginTop: '0',
              cursor: 'pointer',
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </div>
      {
        alert ? (
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Row style={{ width: '100%', flex: '1' }}>
              <Col sm={12}>
                <Alert color='danger' style={{ padding: '0.5rem 1rem' }}>
                  Ошибка
                </Alert>
              </Col>
            </Row>
          </ModalFooter>
        ) : null
      }
    </Modal>
  )
}

export default reduxForm({
  form: 'ImgEditor',
  enableReinitialize: true,
})(ImgEditor)
