import React, { Fragment } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Form, Row, Col, Container } from 'reactstrap'
import { InputField } from '../Form'

function UserEditForm() {
  return (
    <Fragment>
      <Form>
        <Container>
          <Row className='  '>
            <Col md={6}>
              <label>Имя</label>
              <Field name='first_name' component={InputField} />
            </Col>
            <Col md={6}>
              <label>Фамилия</label>
              <Field name='last_name' component={InputField} />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Номер телефона</label>
              <Field name='phone_number' component={InputField} />
            </Col>
            <Col>
              <label>E-mail</label>
              <Field name='email' component={InputField} />
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'UserEditForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(UserEditForm)
