import React, { Fragment } from 'react'
import { reduxForm, Field, FormSection } from 'redux-form'
import { Form, Row, Col } from 'reactstrap'
import { CheckboxField, InputField, SelectField } from '../Form'
import { prop } from 'ramda'
import EditorFieldComponent from '../DraftEditor/EditorFieldComponent'

function RubricCreateForm(props) {
  const { tabs, initialValues, typeOptions, handleSubmit } = props

  let displayContentRu = ''
  let displayContentUz = ''
  let displayContentEn = ''
  if (tabs === 1) {
    displayContentRu = ''
    displayContentUz = 'none'
    displayContentEn = 'none'
  } else if (tabs === 2) {
    displayContentUz = ''
    displayContentRu = 'none'
    displayContentEn = 'none'
  } else if (tabs === 3) {
    displayContentEn = ''
    displayContentRu = 'none'
    displayContentUz = 'none'
  }

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <div className='pl-lg-0'>
          <FormSection name='title'>
            <Row>
              {tabs === 1 && (
                <Col>
                  <Field
                    name='ru'
                    component={InputField}
                    placeholder='Название на русском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Название на русском
                      </label>
                    }
                  />
                </Col>
              )}

              {tabs === 2 && (
                <Col>
                  <Field
                    name='uz'
                    component={InputField}
                    placeholder='Название на узбекском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Название на узбекском
                      </label>
                    }
                  />
                </Col>
              )}

              {tabs === 3 && (
                <Col>
                  <Field
                    name='en'
                    component={InputField}
                    placeholder='Название на английском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Название на английском
                      </label>
                    }
                  />
                </Col>
              )}
            </Row>
          </FormSection>

          <Row style={{ marginBottom: '24px', marginTop: '24px' }}>
            <Col>
              <Field
                name='description_visible'
                component={CheckboxField}
                label='Отображать описание на сайте'
                id='description_visible'
              />
            </Col>
          </Row>

          <Row style={{ display: displayContentRu }}>
            <Col>
              <Field
                name='description_ru'
                component={EditorFieldComponent}
                placeholder=' Описание рубрики (на русском языке)'
                initialValue={initialValues['description_ru']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Описание рубрики (на русском языке)
                  </label>
                }
              />
            </Col>
          </Row>
          <Row style={{ display: displayContentUz }}>
            <Col>
              <Field
                name='description_uz'
                component={EditorFieldComponent}
                placeholder=' Описание рубрики (на узбекском языке)'
                initialValue={initialValues['description_uz']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Описание рубрики (на узбекском языке)
                  </label>
                }
              />
            </Col>
          </Row>
          <Row style={{ display: displayContentEn }}>
            <Col>
              <Field
                name='description_en'
                component={EditorFieldComponent}
                placeholder=' Описание рубрики (на английском языке)'
                initialValue={initialValues['description_en']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Описание рубрики (на английском языке)
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg='6'>
              <Field
                name='type'
                component={SelectField}
                placeholder='Тип рубрики'
                options={typeOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Тип рубрики
                  </label>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name='meta_title'
                component={InputField}
                placeholder='Мета-тег Title'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Мета-тег Title
                  </label>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name='meta_description'
                component={InputField}
                rows='3'
                type='textarea'
                placeholder='Мета-тег Description'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Мета-тег Description
                  </label>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name='meta_keywords'
                component={InputField}
                placeholder='Meta keywords (через запятую)'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Meta ключевые слова
                  </label>
                }
              />
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'RubricForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(RubricCreateForm)
