import React, { Fragment, useState } from 'react'
import { reduxForm, Field, change } from 'redux-form'
import { Form, Row, Col } from 'reactstrap'
import { InputField, SelectField } from '../Form'
import { nth, prop, propOr } from 'ramda'
import MultipleFileInput from '../Form/MultipleFileInput'
import ImgEditor from '../Form/ImgEditor'
import { useDispatch } from 'react-redux'
import { FILE_UPDATE } from '../../constants/api'
import { getToken } from '../../helpers/tokenStorage'

const validate = values => {
  const errors = {}
  if (!values.subcategory) {
    errors.subcategory = 'Обязательно'
  }

  if (!values.category) {
    errors.category = 'Обязательно'
  }

  if (!values.rubric) {
    errors.rubric = 'Обязательно'
  }

  if (!values.headline) {
    errors.headline = 'Обязательно'
  }

  // if (!values.description) {
  //   errors.description = 'Обязательно'
  // }

  return errors
}

function ProductCreateForm(props) {
  const {
    rubricOptions,
    onRubricChanged,
    categoryOptions,
    subCategoryOptions,
    onCategoryChanged,
    item,
    productFormValues,
  } = props

  // const stageRef = useRef()
  const dispatch = useDispatch()

  const [modalIsOpened, setModalIsOpened] = useState(false)
  const [url, setUrl] = useState('')
  const [alert, setAlert] = useState(false)
  const [degree, setDegree] = useState(0)

  // const [img] = useImage(url)
  // const [degree, setDegree] = useState(0)
  // const [rotationData, setRotationData] = useState({})
  const [index, setIndex] = useState(null)
  const [loading, setLoading] = useState(false)

  const photos = item && propOr([], 'photos_info', item)

  function saveRotatedImg(deg) {
    fetch(FILE_UPDATE, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        base_url: "https://baraka.uz/baraka/api/v1/uploads/media/",
        url: propOr('', 'url', nth(index, photos)),
        original_url: propOr('', 'original_url', nth(index, photos)),
        thumb_url: propOr('', 'thumb_url', nth(index, photos)),
        degree: deg,
      }),
    }).then((res) => {
      console.log('res', res)
      setLoading(false)
      setDegree(0)
      if (res.ok) {
        res.json().then(jsonResponse => {
          if (!photos || photos.length === 0) {
            dispatch(change('ProductCreateForm', 'photos', jsonResponse))
          } else if (modalIsOpened) {
            const newArr = photos.map((item, idxx) => {
              if (idxx === index) {
                item = jsonResponse
              }
              return item
            })
            dispatch(change('ProductCreateForm', 'photos', [...newArr]))
            setModalIsOpened(false)
            setIndex(null)
            setUrl('')
            alert && setAlert(false)
          }
        })
      } else {
        setAlert(true)
      }
    })
  }

  return (
    <Fragment>
      <Form>
        <div className='pl-lg-12' style={{ display: 'none' }}>
          <Row>
            <Col>
              <Field name='id' component={InputField} />
            </Col>
          </Row>
        </div>

        <div className='pl-lg-12'>
          <Row>
            <Col>
              <Field
                name='headline'
                component={InputField}
                placeholder='Заголовок'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Заголовок
                  </label>
                }
              />
            </Col>
          </Row>
        </div>

        <div className='pl-lg-12'>
          <Row>
            <Col>
              <Field
                name='description'
                component={InputField}
                rows='5'
                type='textarea'
                placeholder='Описание'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Описание
                  </label>
                }
              />
            </Col>
          </Row>
        </div>

        <div className='pl-lg-12'>
          <Row>
            <Col>
              <Field
                name='phone'
                component={InputField}
                placeholder='Номер телефона'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Номер телефона
                  </label>
                }
              />
            </Col>
            <Col>
              <Field
                name='youtube_url'
                component={InputField}
                placeholder='Ссылка Youtube'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Ссылка Youtube (только ID)
                  </label>
                }
              />
            </Col>
          </Row>
        </div>

        <div className='pl-lg-12'>
          <Row>
            <Col>
              <Field
                name='rubric'
                onChange={onRubricChanged}
                component={SelectField}
                placeholder='Рубрика'
                options={rubricOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Рубрика
                  </label>
                }
              />
            </Col>
            <Col>
              <Field
                name='category'
                onChange={onCategoryChanged}
                component={SelectField}
                placeholder='Категория'
                options={categoryOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Категория
                  </label>
                }
              />
            </Col>
            <Col>
              <Field
                name='subcategory'
                component={SelectField}
                placeholder='Подкатегория'
                options={subCategoryOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Подкатегория
                  </label>
                }
              />
            </Col>
          </Row>
        </div>

        <div className='pl-lg-12'>
          <Row>
            <Col>
              <Field
                name='photos'
                component={MultipleFileInput}
                limit={8}
                type={'ADVERT_WATERMARKED_PHOTO'}
                accept='image/*'
                item={item}
                modalIsOpened={modalIsOpened}
                setModalIsOpened={setModalIsOpened}
                setUrl={setUrl}
                setIndex={setIndex}
                productFormValues={productFormValues}
              />
            </Col>
          </Row>
        </div>
      </Form>

      {url && (
        <ImgEditor
          modalIsOpened={modalIsOpened}
          alert={alert}
          setAlert={setAlert}
          degree={degree}
          setDegree={setDegree}
          setIndex={setIndex}
          // img={img}
          url={url}
          setModalIsOpened={setModalIsOpened}
          // stageRef={stageRef}
          saveRotatedImg={saveRotatedImg}
          // rotateUp={rotateUp}
          // degree={degree}
          // rotationData={rotationData}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </Fragment>
  )
}

export default reduxForm({
  form: 'ProductCreateForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate,
})(ProductCreateForm)
