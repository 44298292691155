import { AuthApi } from '../helpers/httpClients'
import {
  USERS_ALL,
  GET_USER,
  USER_BLOCK,
  USER_UNBLOCK,
  USER_DELETE_PERMANENT,
} from '../constants/api'
import { prop } from 'ramda'

export const usersList = data =>
  AuthApi().get(
    USERS_ALL + `/?page=${prop('page', data)}&size=10&search=${prop('search', data)}`,
    data
  )

export const getUser = data => AuthApi().get(GET_USER, data)

export const userBlock = data => AuthApi().put(`${USER_BLOCK}${prop('id', data)}`, data)

export const userUnblock = data => AuthApi().put(`${USER_UNBLOCK}${prop('id', data)}`, data)

export const userDeletePermanent = data =>
  AuthApi().put(`${USER_DELETE_PERMANENT}${prop('id', data)}`, data)
