import actionType, {
  ADVERTS_LIST,
  ADVERTS_LIST_GET,
  ADVERTS_LIST_NOTIFICATION,
} from './actionTypes'

export const advertListForSaga = payload => ({
  type: actionType[ADVERTS_LIST].request,
  payload,
})

export const advertsList = payload => ({
  type: actionType[ADVERTS_LIST_GET].request,
  payload,
})

export const advertsListClear = () => ({
  type: actionType[ADVERTS_LIST].clear,
})

export const advertNotification = payload => ({
  type: actionType[ADVERTS_LIST_NOTIFICATION].request,
  payload,
})
