import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { rubricActiveList, rubricList } from '../../api/rubric'
import actionType, { RUBRIC_LIST, RUBRIC_ACTIVE_LIST } from './actionTypes'

function* rubricListRequest() {
  yield takeEvery(actionType[RUBRIC_LIST].request, function* ({ payload }) {
    try {
      const { data } = yield call(rubricList, payload)

      yield put({
        type: actionType[RUBRIC_LIST].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[RUBRIC_LIST].error,
        error,
      })
    }
  })
}

function* rubricActiveListRequest() {
  yield takeEvery(actionType[RUBRIC_ACTIVE_LIST].request, function* ({ payload }) {
    try {
      const { data } = yield call(rubricActiveList, payload)

      yield put({
        type: actionType[RUBRIC_ACTIVE_LIST].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[RUBRIC_ACTIVE_LIST].error,
        error,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(rubricListRequest), fork(rubricActiveListRequest)])
}
