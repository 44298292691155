import { prop, add, subtract } from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

function TablePagination(props) {
  const currentPage = +prop('currentPage', props)
  const pageSize = +prop('pageSize', props)
  const totalElements = +prop('totalElements', props)
  const isLastPage = +prop('last', props)
  const pagesTotalCount = Math.ceil(totalElements / pageSize)

  // console.log("Current: " + currentPage)
  // console.log("Last: " + isLastPage)
  // console.log("Page size: " + pageSize)
  // console.log("Total elements: " + totalElements)
  // console.log("Total pages: " + pagesTotalCount)

  return (
    <div style={{ textAlign: 'center' }}>
      <Pagination
        size='sm'
        aria-label='Page navigation example'
        style={{ marginBottom: '0px' }}
        className='pagination justify-content-end mb-0'
        listClassName='justify-content-end mb-0'
      >
        <PaginationItem className={classNames({ disabled: currentPage === 1 })}>
          <PaginationLink className='prev' onClick={() => props.onChange(subtract(currentPage, 1))}>
            <i className='fas fa-angle-left' />
          </PaginationLink>
        </PaginationItem>

        {/* {renderPages(sortPages(pages))} */}

        {currentPage > 2 && (
          <PaginationItem>
            <PaginationLink onClick={() => props.onChange(1)}>1</PaginationLink>
          </PaginationItem>
        )}

        {currentPage > 3 && (
          <PaginationItem>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        )}

        {currentPage === pagesTotalCount && pagesTotalCount > 3 && (
          <PaginationItem>
            <PaginationLink onClick={() => props.onChange(currentPage - 1)}>
              {currentPage - 2}
            </PaginationLink>
          </PaginationItem>
        )}

        {currentPage > 1 && (
          <PaginationItem>
            <PaginationLink onClick={() => props.onChange(currentPage - 1)}>
              {currentPage - 1}
            </PaginationLink>
          </PaginationItem>
        )}

        <PaginationItem active={true}>
          <PaginationLink>{currentPage}</PaginationLink>
        </PaginationItem>

        {currentPage !== pagesTotalCount && (
          <PaginationItem>
            <PaginationLink
              onClick={() => {
                if (!isLastPage) props.onChange(currentPage + 1)
              }}
            >
              {currentPage + 1}
            </PaginationLink>
          </PaginationItem>
        )}

        {pagesTotalCount - 1 !== currentPage && currentPage !== pagesTotalCount && currentPage < 2 && (
          <PaginationItem>
            <PaginationLink
              onClick={() => {
                if (!isLastPage) props.onChange(currentPage + 2)
              }}
            >
              {currentPage + 2}
            </PaginationLink>
          </PaginationItem>
        )}

        {pagesTotalCount - 1 !== currentPage &&
          currentPage !== pagesTotalCount &&
          pagesTotalCount > 4 && (
            <PaginationItem>
              <PaginationLink>...</PaginationLink>
            </PaginationItem>
          )}

        {pagesTotalCount > 4 && currentPage !== pagesTotalCount && (
          <PaginationItem>
            <PaginationLink onClick={() => props.onChange(pagesTotalCount)}>
              {pagesTotalCount}
            </PaginationLink>
          </PaginationItem>
        )}

        <PaginationItem
          className={classNames({
            disabled: currentPage === pagesTotalCount || !totalElements || isLastPage,
          })}
        >
          <PaginationLink className='prev' onClick={() => props.onChange(add(currentPage, 1))}>
            <i className='fas fa-angle-right' />
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  )
}

TablePagination.defaultProps = {
  currentPage: 1,
  pageSize: 10,
  totalElements: undefined,

  onChange: () => {},
}

TablePagination.propTypes = {
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalElements: PropTypes.number,

  onChange: PropTypes.func,
}

export default TablePagination
