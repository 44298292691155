import actionType, { PAGE, PAGE_ACTIVATE, PAGE_CREATE, PAGE_DELETE } from './actionTypes'

export const page = payload => ({
  type: actionType[PAGE].request,
  payload,
})

export const pageClear = payload => ({
  type: actionType[PAGE].clear,
  payload,
})

export const pageCreate = payload => ({
  type: actionType[PAGE_CREATE].request,
  payload,
})

export const pageDelete = payload => ({
  type: actionType[PAGE_DELETE].request,
  payload,
})

export const pageActivate = payload => ({
  type: actionType[PAGE_ACTIVATE].request,
  payload,
})
