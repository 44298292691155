import { compose, path, prop, map, find, propEq, pathOr, omit } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap'
import Header from '../components/Headers/Header'
import CreateForm from '../components/PageCategory/PageCategoryCreateForm'
import * as route from '../constants/routes'
import * as actions from '../redux/actions'
import { hasAnyRole } from '../helpers/get'
import { ADMIN, MODERATOR } from '../constants/userRole'
import {
  MAIN_PAGE_MAIN_CATEGORIES,
  MAIN_PAGE_TOP_SLIDER,
  STORE_MAIN_PAGE_MAIN_CATEGORIES,
  STORE_MAIN_PAGE_TOP_SLIDER,
  STORE_TOP_NAV_BAR_CATEGORIES,
  TELEGRAM_BOT_MAIN_CATEGORIES,
  TOP_NAV_BAR_CATEGORIES,
} from '../constants/enums'

const positionTypeOptions = [
  {
    label: 'Объявления - главные категории',
    value: MAIN_PAGE_MAIN_CATEGORIES,
  },
  {
    label: 'Объявления - топ слайдер',
    value: MAIN_PAGE_TOP_SLIDER,
  },
  {
    label: 'Объявления - верхнее меню',
    value: TOP_NAV_BAR_CATEGORIES,
  },
  {
    label: 'Магазин - главные категории',
    value: STORE_MAIN_PAGE_MAIN_CATEGORIES,
  },
  {
    label: 'Магазин - верхнее меню',
    value: STORE_TOP_NAV_BAR_CATEGORIES,
  },
  {
    label: 'Магазин - топ слайдер',
    value: STORE_MAIN_PAGE_TOP_SLIDER,
  },
  {
    label: 'Телеграм - главные категории',
    value: TELEGRAM_BOT_MAIN_CATEGORIES,
  },
  // {
  //     label: "Мобилка - главные категории",
  //     value: MOBILE_STORE_MAIN_CATEGORIES,
  // },
]

const categoryTypeOptions = [
  {
    label: 'Рубрика',
    value: 'RUBRIC',
  },
  {
    label: 'Категория',
    value: 'CATEGORY',
  },
  {
    label: 'Подкатегория',
    value: 'SUBCATEGORY',
  },
  {
    label: 'Все объявления',
    value: 'ALL_ADVERTS',
  },
  {
    label: 'Все магазины',
    value: 'ALL_STORES',
  },

  {
    label: 'Акция / Конкурс',
    value: 'CONTEST',
  },

  {
    label: 'Новости',
    value: 'NEWS',
  },
]

const enhance = compose(
  connect(({ pageCategory, userInfo, form, router, rubricActiveList }) => {
    const item = prop('data', pageCategory)
    const rubricsList = prop('data', rubricActiveList)
    const loading = prop('loading', pageCategory)

    const formValues = path(['PageCategoryCreateForm', 'values'], form)
    const roles = pathOr([], ['data', 'roles'], userInfo)

    const pathname = path(['location', 'pathname'], router)
    const state = path(['location', 'state'], router)
    const query = path(['location', 'query'], router)

    return {
      item,
      loading,
      formValues,
      roles,
      pathname,
      query,
      rubricsList,
      state,
    }
  })
)

function PageCategory(props) {
  const { history, pathname, query, item, formValues, roles, rubricsList, state } = props
  const id = prop('id', query)

  useEffect(() => {
    if (!id) {
      history.push({
        pathname: route.ADMIN_PAGE_CATEGORY_LIST,
        state: {
          from: pathname,
        },
      })
    }

    if (+id > 0) {
      props.dispatch(
        actions.pageCategory({
          id,
        })
      )
    }
    props.dispatch(
      actions.rubricActiveList({
        id,
      })
    )

    return () => {
      props.dispatch(actions.pageCategoryClear())
    }
  }, [])

  function handleSubmit() {
    const categoryType = path(['category_type', 'value'], formValues)

    let categoryId = null

    switch (categoryType) {
      case 'RUBRIC':
        categoryId = path(['rubric', 'value'], formValues)
        break
      case 'CATEGORY':
        categoryId = path(['category', 'value'], formValues)
        break
      case 'SUBCATEGORY':
        categoryId = path(['subcategory', 'value'], formValues)
        break
      default:
        break
    }

    props.dispatch(
      actions.pageCategoryCreate({
        id: +id > 0 ? id : undefined,
        ...omit(['icon', 'image'], formValues),
        position_type: path(['position_type', 'value'], formValues),
        category_type: path(['category_type', 'value'], formValues),
        category_id: categoryId,
        icon_src: pathOr(null, ['icon', 'src'], formValues),
        image_src: pathOr(null, ['image', 'src'], formValues),
        current_type: prop('current_type', state),
      })
    )
  }

  function handleSuccess() {
    props.dispatch(submit('PageCategoryCreateForm'))
  }

  function handleDeletePermanent() {
    props.dispatch(
      actions.pageCategoryDelete({
        id,
        permanent: true,
        current_type: prop('current_type', state),
      })
    )
  }

  function handleDelete() {
    props.dispatch(
      actions.pageCategoryDelete({
        id,
        current_type: prop('current_type', state),
      })
    )
  }

  function handleActivate() {
    props.dispatch(
      actions.pageCategoryActivate({
        id,
        current_type: prop('current_type', state),
      })
    )
  }

  function handleBack() {
    history.push({
      pathname: route.ADMIN_PAGE_CATEGORY_LIST,
      state: {
        from: pathname,
        current_type: prop('current_type', state),
      },
    })
  }

  function onCategoryTypeChange(option) {
    setCurrentCategoryType(option.value)
  }

  function onRubricChanged(option) {
    const chosenRubric = rubricsList.find(obj => obj.id === option.value)
    if (chosenRubric) setCategoriesList(chosenRubric['items'] || [])
  }

  function onCategoryChanged(option) {
    const chosenCategory = categoriesList.find(obj => obj.id === option.value)
    if (chosenCategory) setSubCategoriesList(chosenCategory['items'] || [])
  }

  const deleted = prop('deleted', item)
  const positionType = prop('position_type', item)
  const categoryType = prop('category_type', item)
  const rubricId = prop('rubric_id', item)
  const categoryId = prop('category_id', item)
  const subCategoryId = prop('subcategory_id', item)

  const [categoriesList, setCategoriesList] = useState(null)
  const [subCategoriesList, setSubCategoriesList] = useState(null)
  const [currentCategoryType, setCurrentCategoryType] = useState(null)

  if (currentCategoryType === null && categoryType) {
    setCurrentCategoryType(categoryType)
  }

  const rubricOptions = map(
    item => ({
      label: path(['title', 'ru'], item),
      value: prop('id', item),
    }),
    rubricsList || []
  )

  const categoryOptions =
    (categoriesList !== null &&
      map(
        item => ({
          label: path(['title', 'ru'], item),
          value: prop('id', item),
        }),
        categoriesList || []
      )) ||
    null

  const subCategoryOptions =
    (subCategoriesList !== null &&
      map(
        item => ({
          label: path(['title', 'ru'], item),
          value: prop('id', item),
        }),
        subCategoriesList || []
      )) ||
    null

  useEffect(() => {
    if (rubricsList !== null && categoriesList === null && rubricId != null) {
      const chosenRubric = rubricsList.find(obj => obj.id === rubricId)
      if (chosenRubric) setCategoriesList(chosenRubric['items'] || [])
    }
  }, [rubricsList])

  useEffect(() => {
    if (categoriesList !== null && subCategoriesList === null) {
      const chosenCategory = categoriesList.find(obj => obj.id === categoryId)
      if (chosenCategory) setSubCategoriesList(chosenCategory['items'] || [])
    }
  }, [categoriesList])

  const initialValues = {
    title: prop('title', item),
    subtitle: prop('subtitle', item),
    priority: prop('priority', item),
    main_color: prop('main_color', item),
    position_type: find(propEq('value', positionType), positionTypeOptions),
    category_type: find(propEq('value', categoryType), categoryTypeOptions),
    rubric: find(propEq('value', rubricId), rubricOptions || []),
    category: find(propEq('value', categoryId), categoryOptions || []),
    subcategory: find(propEq('value', subCategoryId), subCategoryOptions || []),
    secondary_color: prop('secondary_color', item),
    icon: prop('icon_file', item),
    image: prop('image_file', item),
    key: prop('key', item),
  }

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <Row className='align-items-center'>
                  <Col xs='6'>
                    <div onClick={handleBack} style={{ cursor: 'pointer' }}>
                      <i className='ni ni-bold-left' />
                      <h3 style={{ display: 'inline' }}>Категории страниц</h3>
                    </div>
                  </Col>

                  <Col xs='6' className='text-right'>
                    {+id > 0 && hasAnyRole(roles, [ADMIN]) && (
                      <Button size='sm' color='danger' onClick={handleDeletePermanent}>
                        Удалить из базы
                      </Button>
                    )}
                    {+id > 0 && hasAnyRole(roles, [ADMIN, MODERATOR]) && (
                      <Button
                        size='sm'
                        color={(deleted && 'success') || 'danger'}
                        onClick={(deleted && handleActivate) || handleDelete}
                      >
                        {(deleted && 'Восстановить') || 'Отключить'}
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <CreateForm
                  categoryTypeOptions={categoryTypeOptions}
                  positionTypeOptions={positionTypeOptions}
                  onCategoryTypeChange={onCategoryTypeChange}
                  onRubricChanged={onRubricChanged}
                  onCategoryChanged={onCategoryChanged}
                  rubricOptions={rubricOptions}
                  categoryOptions={categoryOptions}
                  subCategoryOptions={subCategoryOptions}
                  currentCategoryType={currentCategoryType}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                />
              </CardBody>

              <CardFooter className='text-right'>
                <Button onClick={handleBack}>Назад</Button>

                <Button color={(+id > 0 && 'primary') || 'success'} onClick={handleSuccess}>
                  {(+id > 0 && 'Сохранить') || 'Создать'}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default enhance(PageCategory)
