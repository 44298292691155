import React, { Fragment } from 'react'
import { reduxForm, Field, FormSection } from 'redux-form'
import {
  Form,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { CheckboxField, InputField, SelectField } from '../Form'
import { map, path } from 'ramda'

function SubCategoryCreateForm(props) {
  const {
    tabs,
    onEditBrandClicked,
    onAddBrandClicked,
    brandsList,
    categoryOptions,
    typeOptions,
  } = props

  const brandOptions = map(item => {
    const titleRu = path(['title', 'ru'], item)
    return (
      <li>
        <DropdownItem
          style={{ cursor: 'default' }}
          onClick={e => e.preventDefault()}
          className='align-content-center'
        >
          {titleRu}
          <a
            onClick={e => {
              e.preventDefault()
              onEditBrandClicked(item)
            }}
          >
            <i style={{ cursor: 'pointer' }} className='fas fa-pen text-success ml-3' />
          </a>
        </DropdownItem>
      </li>
    )
  }, brandsList || [])

  return (
    <Fragment>
      <Form>
        <div className='pl-lg-4'>
          <FormSection name='title'>
            <Row>
              {tabs === 1 && (
                <Col>
                  <Field
                    name='ru'
                    component={InputField}
                    placeholder='Название на русском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Название на русском
                      </label>
                    }
                  />
                </Col>
              )}
              {tabs === 2 && (
                <Col>
                  <Field
                    name='uz'
                    component={InputField}
                    placeholder='Название на узбекском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Название на узбекском
                      </label>
                    }
                  />
                </Col>
              )}
              {tabs === 3 && (
                <Col>
                  <Field
                    name='en'
                    component={InputField}
                    placeholder='Название  на английском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Название на английском
                      </label>
                    }
                  />
                </Col>
              )}
            </Row>
          </FormSection>

          <Row style={{ marginBottom: '24px', marginTop: '24px' }}>
            <Col>
              <Field
                name='description_visible'
                component={CheckboxField}
                label='Отображать описание на сайте'
                id='description_visible'
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: '24px', marginTop: '24px' }}>
            <Col>
              <Field
                name='disable_watermark'
                component={CheckboxField}
                label='Не оставлять водяной знак в фотографиях объявлений'
                id='disable_watermark'
              />
            </Col>
          </Row>

          <FormSection name='description'>
            {tabs === 1 && (
              <Row>
                <Col>
                  <Field
                    name='ru'
                    component={InputField}
                    placeholder='Введите описание подкатегории (на русском языке)'
                    rows='3'
                    type='textarea'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Описание подкатегории (на русском языке)
                      </label>
                    }
                  />
                </Col>
              </Row>
            )}

            {tabs === 2 && (
              <Row>
                <Col>
                  <Field
                    name='uz'
                    component={InputField}
                    placeholder='Введите описание подкатегории (на узбекском языке)'
                    rows='3'
                    type='textarea'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Описание подкатегории (на узбекском языке)
                      </label>
                    }
                  />
                </Col>
              </Row>
            )}

            {tabs === 3 && (
              <Row>
                <Col>
                  <Field
                    name='en'
                    component={InputField}
                    placeholder='Введите описание подкатегории (на английском языке)'
                    rows='3'
                    type='textarea'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Описание подкатегории (на английском языке)
                      </label>
                    }
                  />
                </Col>
              </Row>
            )}
          </FormSection>

          <Row>
            <Col lg='6'>
              <Field
                name='department_type'
                component={SelectField}
                placeholder='Тип рубрики'
                options={typeOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Тип рубрики
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name='meta_title'
                component={InputField}
                placeholder='Мета-тег Title'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Мета-тег Title
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name='meta_description'
                component={InputField}
                rows='3'
                type='textarea'
                placeholder='Мета-тег Description'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Мета-тег Description
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name='meta_keywords'
                component={InputField}
                placeholder='Meta keywords (через запятую)'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Meta ключевые слова
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg='6'>
              <Field
                name='category_id'
                component={SelectField}
                placeholder='Категория'
                options={categoryOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Категория
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg='4'>
              <label className='form-control-label' htmlFor='input-username'>
                Марки
              </label>
              <br />
              <UncontrolledDropdown>
                <DropdownToggle color='primary' caret>
                  Редактировать марки
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: 'auto',
                            maxHeight: '300px',
                          },
                        }
                      },
                    },
                  }}
                >
                  <li>
                    <DropdownItem
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        e.preventDefault()
                        onAddBrandClicked()
                      }}
                    >
                      Добавить
                      <a>
                        <i className='fas fa-plus-square text-success ml-3' />
                      </a>
                    </DropdownItem>
                  </li>
                  {brandOptions}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'SubCategoryCreateForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SubCategoryCreateForm)
