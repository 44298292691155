import React, { useEffect, useState } from 'react'
import { Button, Modal, Row } from 'reactstrap'
import StoreCreateForm from '../Store/StoreCreateForm'
import { prop, map, propOr, path, find, compose, head } from 'ramda'
import { change } from 'redux-form'
import { useDispatch } from 'react-redux'
import * as actions from '../../redux/actions'

const StoreModal = props => {
  const {
    content,
    isModalOpen,
    setIsModalOpen,
    onStoreSaved,
    rubricActiveList,
    form,
    handleStoreSuccess,
  } = props

  const dispatch = useDispatch()
  const creator = prop('owner', content)
  const formValues = path(['StoreCreateForm', 'values'], form) || {}
  const { data } = rubricActiveList
  const [categoryOpts, setCategoryOpts] = useState([])
  const [rubricOpts, setRubricOpts] = useState([])
  const id = prop('id', content)
  const rubricId = path(['rubric', 'value'], formValues)

  let initialValues = {
    address: propOr('', 'address', content),
    rubric: {
      label: path(['rubric', 'title', 'ru'], content),
      value: path(['rubric', 'id'], content),
    },
    category: {
      label: path(['category', 'title', 'ru'], content),
      value: path(['category', 'id'], content),
    },
    list_of_phones: propOr('', 'list_of_phones', content),
    store_username: propOr('', 'store_username', content),
    brand_image: [prop('brand_image', content)],
    top_image: [prop('top_image', content)],
    mobile_top_image: [prop('mobile_top_image', content)],
    left_image: [prop('left_image', content)],
    right_image: [prop('right_image', content)],
    store_name_ru: path(['store_name', 'ru'], content),
    store_name_uz: path(['store_name', 'uz'], content),
    store_name_en: path(['store_name', 'en'], content),
    description_en: path(['description', 'en'], content),
    description_uz: path(['description', 'uz'], content),
    description_ru: path(['description', 'ru'], content),
  }

  useEffect(() => {
    if (rubricId) {
      const list = compose(
        map(item => {
          return {
            label: path(['title', 'ru'], item),
            value: prop('id', item),
          }
        }),
        prop('items'),
        find(item => rubricId === propOr('', 'id', item))
      )(data)

      setCategoryOpts([...list])
    }
  }, [rubricId])

  useEffect(() => {
    setRubricOpts(() => {
      return map(item => {
        return {
          label: path(['title', 'ru'], item),
          value: prop('id', item),
        }
      })(propOr([], 'data', rubricActiveList))
    })
  }, [rubricActiveList])

  const handleRubricChange = () => {
    dispatch(change('StoreCreateForm', 'category', null))
  }

  function handleSubmit() {
    const listOfPhones = prop('list_of_phones', formValues) || []

    const brandImages = head(prop('brand_image', formValues) || [])
    const topImages = head(prop('top_image', formValues) || [])
    const mobileTopImages = head(prop('mobile_top_image', formValues) || [])
    const leftImages = head(prop('left_image', formValues) || [])
    const rightImages = head(prop('right_image', formValues) || [])

    dispatch(
      actions.storeCreate({
        id: id,
        list_of_phones: listOfPhones,
        address: prop('address', formValues),
        store_username: prop('store_username', formValues),
        brand_image_src: prop('src', brandImages),
        top_image_src: prop('src', topImages),
        mobile_top_image_src: prop('src', mobileTopImages),
        left_image_src: prop('src', leftImages),
        right_image_src: prop('src', rightImages),
        category: path(['category', 'value'], formValues),
        description: {
          ru: prop('description_ru', formValues),
          en: prop('description_en', formValues),
          uz: prop('description_uz', formValues),
        },
        store_name: {
          ru: prop('store_name_ru', formValues),
          en: prop('store_name_en', formValues),
          uz: prop('store_name_uz', formValues),
        },
      })
    )

    setIsModalOpen(false)
    onStoreSaved()
  }

  return (
    <Modal
      className='modal-dialog-centered'
      size='xl'
      style={{ maxWidth: '1000px', width: '90%' }}
      isOpen={isModalOpen}
    >
      <div className='modal-header'>
        <h4 className='modal-title' id='modal-title-default'>
          Редактировать магазин
        </h4>

        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={() => setIsModalOpen(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className='modal-body'>
        <div style={{ padding: '8px', marginBottom: '16px' }}>
          <Row>
            <a className='avatar rounded-circle' onClick={e => e.preventDefault()}>
              <img alt='...' src={prop('photo_url', creator)} />
            </a>
            <span style={{ marginTop: '12px', marginLeft: '8px' }}>
              {prop('first_name', creator) +
                ' ' +
                prop('last_name', creator) +
                ' (ID: ' +
                prop('id', creator) +
                ', Номер: ' +
                prop('phone', creator) +
                ')'}
            </span>
          </Row>
        </div>

        <StoreCreateForm
          rubricOpts={rubricOpts}
          categoryOpts={categoryOpts}
          initialValues={initialValues}
          handleRubricChange={handleRubricChange}
          onSubmit={handleSubmit}
        />
      </div>

      <div className='modal-footer'>
        <Button color='primary' className='ml-auto' type='button' onClick={handleStoreSuccess}>
          Сохранить
        </Button>
      </div>
    </Modal>
  )
}

export default StoreModal
