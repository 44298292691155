import { Button, Modal, Row } from 'reactstrap'
import SubCategoryFieldCreateForm from '../Categories/SubCategoryFieldCreateForm'
import SubCategoryFieldOptionCreateForm from '../Categories/SubCategoryFieldOptionCreateForm'
import React from 'react'
import { prop } from 'ramda'

function FieldsModal(props) {
  const {
    modalIsOpened,
    setModalIsOpened,
    currentFieldId,
    currentFieldType,
    fieldInitialValues,
    setFieldOptionInitialValues,
    setOptionModalIsOpened,
    setCurrentFieldType,
    currentOptions,
    handleFieldSubmit,
    handleFieldSuccess,
    typeOptions,
  } = props

  function onAddOptionClicked() {
    setFieldOptionInitialValues({})
    setOptionModalIsOpened(true)
  }

  function onEditOptionClicked(item) {
    setFieldOptionInitialValues({
      title: prop('title', item),
      priority: prop('priority', item),
      id: prop('id', item),
    })
    setOptionModalIsOpened(true)
  }

  function onTypeChange(option) {
    setCurrentFieldType(option.value)
  }

  return (
    <Modal
      className='modal-dialog-centered'
      size='xl'
      style={{ maxWidth: '1000px', width: '90%' }}
      toggle={() => {
        setModalIsOpened(false)
      }}
      isOpen={modalIsOpened}
    >
      <div className='modal-header'>
        <h4 className='modal-title' id='modal-title-default'>
          {(currentFieldId === 0 && 'Создать поле') || 'Редактировать поле'}
        </h4>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={() => setModalIsOpened(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className='modal-body'>
        <SubCategoryFieldCreateForm
          typeOptions={typeOptions}
          currentFieldType={currentFieldType}
          initialValues={fieldInitialValues}
          onEditOptionClicked={onEditOptionClicked}
          onAddOptionClicked={onAddOptionClicked}
          onTypeChange={onTypeChange}
          optionsList={currentOptions}
          onSubmit={handleFieldSubmit}
        />
      </div>
      <div className='modal-footer'>
        <Button
          color='primary'
          className='ml-auto'
          type='button'
          onClick={() => {
            setModalIsOpened(false)
            handleFieldSuccess()
          }}
        >
          {(currentFieldId === 0 && 'Создать') || 'Сохранить'}
        </Button>
      </div>
    </Modal>
  )
}

export default FieldsModal
