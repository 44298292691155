import React, { Fragment } from 'react'
import { reduxForm, Field, FormSection } from 'redux-form'
import { Form, Row, Col } from 'reactstrap'
import { InputField, EditorFieldNew } from '../Form'
// import CustomReactQuill from '../Form/CustomReactQuill'

function PageCreateForm(props) {
  const { tabs, initialValues, handleSubmit } = props

  let displayContentRu = ''
  let displayContentUz = ''
  let displayContentEn = ''

  if (tabs === 1) {
    displayContentRu = ''
    displayContentUz = 'none'
    displayContentEn = 'none'
  } else if (tabs === 2) {
    displayContentUz = ''
    displayContentRu = 'none'
    displayContentEn = 'none'
  } else if (tabs === 3) {
    displayContentEn = ''
    displayContentRu = 'none'
    displayContentUz = 'none'
  }

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <div className='pl-lg-0'>
          <Row>
            <Col>
              <Field
                name='key'
                component={InputField}
                onKeyPress={event => {
                  const isValid =
                    (event.charCode >= 48 && event.charCode <= 57) ||
                    (event.charCode >= 97 && event.charCode <= 122)
                  if (!isValid) event.preventDefault()
                }}
                placeholder='Идентификатор для страницы (только латинские буквы и цифры)'
                addon={
                  <label className='form-control-label' htmlFor='input-key'>
                    Идентификатор для страницы (должен быть уникальным)
                  </label>
                }
              />
            </Col>
          </Row>

          <FormSection name='title'>
            <Row>
              {tabs === 1 && (
                <Col>
                  <Field
                    name='ru'
                    component={InputField}
                    placeholder='Заголовок страницы на русском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Заголовок страницы на русском
                      </label>
                    }
                  />
                </Col>
              )}

              {tabs === 2 && (
                <Col>
                  <Field
                    name='uz'
                    component={InputField}
                    placeholder='Заголовок страницы на узбекском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Заголовок страницы на узбекском
                      </label>
                    }
                  />
                </Col>
              )}

              {tabs === 3 && (
                <Col>
                  <Field
                    name='en'
                    theme='snow'
                    component={InputField}
                    placeholder='Заголовок страницы на английском'
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Заголовок страницы на английском
                      </label>
                    }
                  />
                </Col>
              )}
            </Row>
          </FormSection>

          <Row style={{ display: displayContentRu }}>
            <Col>
              <Field
                name='main_content'
                component={EditorFieldNew}
                placeholder='Основное содержимое'
                initialValue={initialValues['main_content']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Основное содержимое
                  </label>
                }
                theme='snow'
              />
              {/*<CustomReactQuill*/}
              {/*  placeholder='Test'*/}
              {/*/>*/}
            </Col>
          </Row>

          <Row style={{ display: displayContentRu }}>
            <Col>
              <Field
                name='header_content'
                component={EditorFieldNew}
                placeholder='Содержимое заголовка (header)'
                initialValue={initialValues['header_content']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Содержимое заголовка (header)
                  </label>
                }
              />
            </Col>
          </Row>

          <Row style={{ display: displayContentRu }}>
            <Col>
              <Field
                name='footer_content'
                component={EditorFieldNew}
                placeholder='Содержимое футера (footer)'
                initialValue={initialValues['footer_content']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Содержимое футера (footer)
                  </label>
                }
              />
            </Col>
          </Row>

          <Row style={{ display: displayContentUz }}>
            <Col>
              <Field
                name='main_content_uz'
                component={EditorFieldNew}
                placeholder='Основное содержимое'
                initialValue={initialValues['main_content_uz']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Основное содержимое (на узбекском)
                  </label>
                }
              />
            </Col>
          </Row>

          <Row style={{ display: displayContentUz }}>
            <Col>
              <Field
                name='header_content_uz'
                component={EditorFieldNew}
                placeholder='Содержимое заголовка (header)'
                initialValue={initialValues['header_content_uz']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Содержимое заголовка (header) на узбекском
                  </label>
                }
              />
            </Col>
          </Row>

          <Row style={{ display: displayContentUz }}>
            <Col>
              <Field
                name='footer_content_uz'
                component={EditorFieldNew}
                placeholder='Содержимое футера (footer)'
                initialValue={initialValues['footer_content_uz']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Содержимое футера (footer) на узбекском
                  </label>
                }
              />
            </Col>
          </Row>

          <Row style={{ display: displayContentEn }}>
            <Col>
              <Field
                name='main_content_en'
                component={EditorFieldNew}
                placeholder='Основное содержимое'
                initialValue={initialValues['main_content_en']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Основное содержимое (на английском)
                  </label>
                }
              />
            </Col>
            }
          </Row>

          <Row style={{ display: displayContentEn }}>
            <Col>
              <Field
                name='header_content_en'
                component={EditorFieldNew}
                placeholder='Содержимое заголовка (header)'
                initialValue={initialValues['header_content_en']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Содержимое заголовка (header) на английском
                  </label>
                }
              />
            </Col>
          </Row>

          <Row style={{ display: displayContentEn }}>
            <Col>
              <Field
                name='footer_content_en'
                component={EditorFieldNew}
                placeholder='Содержимое футера (footer)'
                initialValue={initialValues['footer_content_en']}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Содержимое футера (footer) на английском
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name='meta_title'
                component={InputField}
                placeholder='Мета-тег Title'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Мета-тег Title
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name='meta_description'
                component={InputField}
                rows='3'
                type='textarea'
                placeholder='Мета-тег Description'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Мета-тег Description
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name='meta_keywords'
                component={InputField}
                placeholder='Meta keywords (через запятую)'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Meta ключевые слова
                  </label>
                }
              />
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'PageCreateForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(PageCreateForm)
