import { prop, path } from 'ramda'
import React, { Fragment } from 'react'

function CheckboxField(props) {
  const { input, label, id } = props

  function handleOnChange(event) {
    const checked = path(['target', 'checked'], event)

    input.onChange(checked)
  }

  return (
    <Fragment>
      <div className='custom-control custom-control-alternative custom-checkbox'>
        <input
          className='custom-control-input'
          id={` ${id || 'customCheckLogin'}`}
          type='checkbox'
          checked={prop('value', input)}
          onChange={handleOnChange}
        />
        {label && (
          <label className='custom-control-label' htmlFor={` ${id || 'customCheckLogin'}`}>
            <span className='text-muted'>{label}</span>
          </label>
        )}
        {props.meta.touched && props.meta.error && (
          <p style={{ color: 'red', fontSize: '12px' }}> {props.meta.error} </p>
        )}
      </div>
    </Fragment>
  )
}

export default CheckboxField
