import actionType, { USER, USERS_ALL, USER_BLOCK, USER_DELETE, USER_UNBLOCK } from './actionTypes'

export const getUser = payload => ({
  type: actionType[USER].request,
  payload,
})

export const getUsersAll = payload => ({
  type: actionType[USERS_ALL].request,
  payload,
})

export const blockUser = payload => ({
  type: actionType[USER_BLOCK].request,
  payload,
})

export const deleteUser = payload => ({
  type: actionType[USER_DELETE].request,
  payload,
})

export const activateUser = payload => ({
  type: actionType[USER_UNBLOCK].request,
  payload,
})
