import React, { Component } from 'react'
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { unemojify } from 'node-emoji'
import htmlToDraft from 'html-to-draftjs'

export default class ControlledEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editorState: EditorState.createEmpty(),
      changedInitial: false,
    }
    this.props.onChange(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())))
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.changedInitial) {
      if (props.initialValue) {
        let html = props.initialValue

        const contentBlock = htmlToDraft(html)
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
          const editorState = EditorState.createWithContent(contentState)
          const changedInitial = true

          const { onChange } = props
          onChange(html)

          return {
            editorState,
            changedInitial,
          }
        }
      }
    }
  }

  // componentDidMount() {
  //     setTimeout(() => {
  //         console.log("After delay: ")
  //         console.log(this.state)
  //         console.log(this.props)
  //         if(!this.state.changedInitial) {
  //             if(this.props.initialValue) {
  //                 const html = this.props.initialValue;
  //                 const contentBlock = htmlToDraft(html);
  //                 if (contentBlock) {
  //                     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //                     const editorState = EditorState.createWithContent(contentState);
  //                     const changedInitial = true;
  //
  //                     console.log("After delay 2: " + html)
  //                     this.setState( {
  //                         editorState,
  //                         changedInitial
  //                     });
  //                 }
  //             }
  //
  //         }
  //     }, 500)
  // }

  onEditorStateChange = editorState => {
    const { onChange, value } = this.props
    const newValue = unemojify(draftToHtml(convertToRaw(editorState.getCurrentContent())))

    if (value !== newValue) {
      onChange(newValue)
    }

    this.setState({
      editorState,
    })
  }

  render() {
    const { editorState } = this.state
    const editorStyleObj = {
      border: '0',
      fontSize: '0.875rem',
      boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
      borderRadius: '0.375rem',
      backgroundColor: '#ffffff',
      lineHeight: '1.5',
      marginBottom: '1.5rem',
      transition: 'box-shadow .15s ease',
      padding: '0.625rem 0.75rem',
      backgroundClip: 'padding-box',
    }
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName='demo-wrapper'
          editorClassName='demo-editor'
          editorStyle={editorStyleObj}
          onEditorStateChange={this.onEditorStateChange}
          localization={{ locale: 'ru' }}
          toolbar={{
            inline: { inDropdown: false },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />
      </div>
    )
  }
}
