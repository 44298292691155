import createAction from '../../../helpers/createAction'
import { RUBRIC_FIELD_CREATE, RUBRIC_FIELD_DELETE, RUBRIC_FIELD_COPY } from '../../actionTypes'

export default {
  [RUBRIC_FIELD_CREATE]: createAction(RUBRIC_FIELD_CREATE),
  [RUBRIC_FIELD_DELETE]: createAction(RUBRIC_FIELD_DELETE),
  [RUBRIC_FIELD_COPY]: createAction(RUBRIC_FIELD_COPY),
}

export { RUBRIC_FIELD_CREATE, RUBRIC_FIELD_DELETE, RUBRIC_FIELD_COPY }
