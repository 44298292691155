import actionType, {
  RUBRIC_FIELD_CREATE,
  RUBRIC_FIELD_DELETE,
  RUBRIC_FIELD_COPY,
} from './actionTypes'

export const rubricFieldCreate = payload => ({
  type: actionType[RUBRIC_FIELD_CREATE].request,
  payload,
})

export const rubricFieldDelete = payload => ({
  type: actionType[RUBRIC_FIELD_DELETE].request,
  payload,
})

export const rubricFieldCopy = payload => ({
  type: actionType[RUBRIC_FIELD_COPY].request,
  payload,
})
