import actionType, {
  SUBCATEGORY_FIELD_CREATE,
  SUBCATEGORY_FIELD_DELETE,
  SUBCATEGORY_FIELD_COPY,
} from './actionTypes'

export const subcategoryFieldCreate = payload => ({
  type: actionType[SUBCATEGORY_FIELD_CREATE].request,
  payload,
})

export const subcategoryFieldDelete = payload => ({
  type: actionType[SUBCATEGORY_FIELD_DELETE].request,
  payload,
})

export const subcategoryFieldCopy = payload => ({
  type: actionType[SUBCATEGORY_FIELD_COPY].request,
  payload,
})
