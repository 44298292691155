function createAction(action) {
  return {
    request: `${action}_REQUEST`,
    success: `${action}_SUCCESS`,
    error: `${action}_ERROR`,
    clear: `${action}_CLEAR`,
  }
}

export default createAction
