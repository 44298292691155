//PAGE CATEGORY POSITION TYPES

export const MAIN_PAGE_MAIN_CATEGORIES = 'MAIN_PAGE_MAIN_CATEGORIES'
export const MAIN_PAGE_TOP_SLIDER = 'MAIN_PAGE_TOP_SLIDER'
export const TOP_NAV_BAR_CATEGORIES = 'TOP_NAV_BAR_CATEGORIES'
export const MOBILE_MAIN_CATEGORIES = 'MOBILE_MAIN_CATEGORIES'
export const TELEGRAM_BOT_MAIN_CATEGORIES = 'TELEGRAM_BOT_MAIN_CATEGORIES'
export const STORE_MAIN_PAGE_MAIN_CATEGORIES = 'STORE_MAIN_PAGE_MAIN_CATEGORIES'
export const STORE_TOP_NAV_BAR_CATEGORIES = 'STORE_TOP_NAV_BAR_CATEGORIES'
export const STORE_MAIN_PAGE_TOP_SLIDER = 'STORE_MAIN_PAGE_TOP_SLIDER'
export const MOBILE_STORE_MAIN_CATEGORIES = 'MOBILE_STORE_MAIN_CATEGORIES'

export const pageCategoriesMap = [
  {
    key: MAIN_PAGE_MAIN_CATEGORIES,
    title: 'Объявления - основные категории',
  },
  {
    key: MAIN_PAGE_TOP_SLIDER,
    title: 'Объявления - слайдер',
  },
  {
    key: TOP_NAV_BAR_CATEGORIES,
    title: 'Объявления - верхнее меню',
  },
  {
    key: STORE_MAIN_PAGE_MAIN_CATEGORIES,
    title: 'Магазины - основные категории',
  },
  {
    key: STORE_MAIN_PAGE_TOP_SLIDER,
    title: 'Магазины - слайдер',
  },
  {
    key: STORE_TOP_NAV_BAR_CATEGORIES,
    title: 'Магазины - верхнее меню',
  },
]
