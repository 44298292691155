import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import {
  usersList,
  getUser,
  userBlock,
  userUnblock,
  userDeletePermanent,
} from '../../api/usersList'
import actionType, { USER, USERS_ALL, USER_BLOCK, USER_DELETE, USER_UNBLOCK } from './actionTypes'
import * as actions from '../actions'

function* UserRequest() {
  yield takeEvery(actionType[USER].request, function* ({ payload }) {
    try {
      const { data } = yield call(getUser, payload)
      yield put({
        type: actionType[USER].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[USER].error,
        error,
      })
    }
  })
}
function* UsersAllRequest() {
  yield takeEvery(actionType[USERS_ALL].request, function* ({ payload }) {
    try {
      const { data } = yield call(usersList, payload)

      yield put({
        type: actionType[USERS_ALL].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[USERS_ALL].error,
        error,
      })
    }
  })
}
function* UserBlockRequest() {
  yield takeEvery(actionType[USER_BLOCK].request, function* ({ payload }) {
    try {
      const data = yield call(userBlock, payload)
      yield put({
        type: actionType[USER_BLOCK].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[USER_BLOCK].error,
        error,
      })
    }
  })
}
function* UserUnblockRequest() {
  yield takeEvery(actionType[USER_UNBLOCK].request, function* ({ payload }) {
    try {
      const data = yield call(userUnblock, payload)

      yield put({
        type: actionType[USER_UNBLOCK].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[USER_UNBLOCK].error,
        error,
      })
    }
  })
}
function* UserDeleteRequest() {
  yield takeEvery(actionType[USER_DELETE].request, function* ({ payload }) {
    try {
      const data = yield call(userDeletePermanent, payload)

      yield put({
        type: actionType[USER_DELETE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[USER_DELETE].error,
        error,
      })
    }
  })
}

function* UserBlockRequestSuccess() {
  yield takeEvery(actionType[USER_BLOCK].success, function* () {
    yield put(actions.getUsersAll())
  })
}
function* UserUnblockRequestSuccess() {
  yield takeEvery(actionType[USER_UNBLOCK].success, function* () {
    yield put(actions.getUsersAll())
  })
}
function* UserDeleteRequestSuccess() {
  yield takeEvery(actionType[USER_DELETE].success, function* () {
    yield put(actions.getUsersAll())
  })
}
export default function* rootSaga() {
  yield all([
    fork(UserRequest),
    fork(UsersAllRequest),
    fork(UserBlockRequest),
    fork(UserUnblockRequest),
    fork(UserDeleteRequest),
    fork(UserBlockRequestSuccess),
    fork(UserUnblockRequestSuccess),
    fork(UserDeleteRequestSuccess),
  ])
}
