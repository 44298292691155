import createAction from '../../helpers/createAction'
import { RUBRIC, RUBRIC_CREATE, RUBRIC_DELETE, RUBRIC_ACTIVATE } from '../actionTypes'

export default {
  [RUBRIC]: createAction(RUBRIC),
  [RUBRIC_CREATE]: createAction(RUBRIC_CREATE),
  [RUBRIC_DELETE]: createAction(RUBRIC_DELETE),
  [RUBRIC_ACTIVATE]: createAction(RUBRIC_ACTIVATE),
}

export { RUBRIC, RUBRIC_CREATE, RUBRIC_DELETE, RUBRIC_ACTIVATE }
