import {
  prop,
  propOr,
  propEq,
  map,
  filter,
  find,
  equals,
  uniq,
  pluck,
  intersection,
  isEmpty,
} from 'ramda'
import React, { Fragment, Component } from 'react'
import ReactDom from 'react-dom'
import { FormGroup } from 'reactstrap'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import Gallery from 'react-fine-uploader'
import { API_URL, FILE_UPLOAD, FILE_DELETE, NEW_FILE_UPLOAD_REACT_URL } from '../../constants/api'
import { getToken } from '../../helpers/tokenStorage'
import 'react-fine-uploader/gallery/gallery.css'

class UploadField extends Component {
  constructor(props) {
    super(props)

    this.uploader = new FineUploaderTraditional({
      options: {
        deleteFile: {
          enabled: true,
          endpoint: `${API_URL}/${FILE_DELETE}`,
          customHeaders: {
            Authorization: `Bearer ${getToken()}`,
          },
          params: {
            attachment_type: prop('uploadType', props),
          },
        },
        request: {
          endpoint: `${API_URL}/${NEW_FILE_UPLOAD_REACT_URL}`,
          customHeaders: {
            Authorization: `Bearer ${getToken()}`,
          },
          params: {
            attachment_type: prop('uploadType', props),
          },
          inputName: 'file',
          filenameParam: 'name',
          totalFileSizeName: 'size',
          uuidName: 'uuid',
        },
        validation: {
          allowedExtensions: prop('validation', props) || false,
        },
      },
    })

    this.handleFileUpload = this.handleFileUpload.bind(this)
  }

  componentDidMount() {
    this.uploader.on('complete', (id, name, responseJSON) => {
      this.handleFileUpload(responseJSON)

      this.props.onComplete(responseJSON, id, name)
    })

    this.uploader.on('onDelete', id => {
      const { value } = this.props.input
      const filename = this.uploader.methods.getUuid(id)
      const file = find(propEq('filename', filename), value || [])

      this.props.input.onChange(
        filter(item => !equals(prop('filename', item), filename), value || [])
      )
      this.props.onDelete(id, filename, file)
    })

    this.uploader.on('onCancel', id => {
      const { value } = this.props.input
      const filename = this.uploader.methods.getUuid(id)
      const file = find(propEq('filename', filename), value || [])

      this.props.input.onChange(
        filter(item => !equals(prop('filename', item), filename), value || [])
      )
      this.props.onCancel(id, filename, file)
    })

    this.uploader.on(
      'onUpload',
      id =>
        new Promise((resolve, reject) => {
          if (this.props.onBeforeUpload) {
            this.setState({ upload: true })

            this.onBeforeUploadSuccess = () => {
              this.setState({ upload: false })
              resolve(id)
            }

            this.onBeforeUploadError = () => {
              this.setState({ upload: false })
              reject(id)
            }
          } else {
            resolve(id)
          }
        })
    )

    this.uploader.on(
      'onCancel',
      id =>
        new Promise((resolve, reject) => {
          if (this.props.onBeforeDelete) {
            this.setState({ delete: true })

            this.onBeforeDeleteSuccess = () => {
              this.setState({ delete: false })
              resolve(id)
            }

            this.onBeforeDeleteError = () => {
              this.setState({ delete: false })
              reject(id)
            }
          } else {
            resolve(id)
          }
        })
    )

    this.uploader.on(
      'onSubmitDelete',
      id =>
        new Promise((resolve, reject) => {
          if (this.props.onBeforeDelete) {
            this.setState({ delete: true })

            this.onBeforeDeleteSuccess = () => {
              this.setState({ delete: false })
              resolve(id)
            }

            this.onBeforeDeleteError = () => {
              this.setState({ delete: false })
              reject(id)
            }
          } else {
            resolve(id)
          }
        })
    )
  }

  componentDidUpdate(prevProps) {
    const thisInitial = this.props.initialValue
    const prevInitial = prevProps.initialValue

    if (!equals(thisInitial, prevInitial)) {
      const { initialValue, singleFile, hasNotThumb } = this.props

      if (singleFile === true) {
        const item = initialValue
        const value = [
          {
            id: propOr(0, 'id', initialValue),
            name: (
              <a href={prop('url', item)} target='_blank'>
                {prop('original_name', item)}.{prop('extension', item)}
              </a>
            ),
            uuid: prop('filename', item),
            size: prop('fileSize', item),
            url: prop('url', item),
            thumbnailUrl: (hasNotThumb === true && prop('thumb', item)) || prop('url', item),
          },
        ]

        // this.uploader.methods.reset()
        const uploads = this.uploader.methods.getUploads()

        if (isEmpty(intersection(pluck('uuid', uploads), pluck('uuid', value)))) {
          this.uploader.methods.addInitialFiles(uniq(value))
        }
      } else {
        const value = map(
          item => ({
            id: propOr(0, 'id', item),
            name: (
              <a href={prop('url', item)}>
                {prop('original_name', item)}.{prop('extension', item)}
              </a>
            ),
            uuid: prop('filename', item),
            size: prop('fileSize', item),
            url: prop('url', item),
            thumbnailUrl: prop('thumb', item),
          }),
          initialValue || []
        )

        // this.uploader.methods.reset()
        const uploads = this.uploader.methods.getUploads()

        if (isEmpty(intersection(pluck('uuid', uploads), pluck('uuid', value)))) {
          this.uploader.methods.addInitialFiles(uniq(value))
        }
      }
    }
  }

  handleFileUpload(responseJSON) {
    const { input } = this.props

    const value = prop('value', input)
    input.onChange([...value, responseJSON])
  }

  render() {
    const { placeholder, buttonText, addon } = this.props

    const node = ReactDom.findDOMNode(this)

    if (node instanceof HTMLElement && buttonText) {
      node.querySelector(
        '.react-fine-uploader-gallery-file-input-content'
      ).childNodes[0].innerText = buttonText
    }

    const statusTextOverride = {
      upload_successful: 'Success!',
    }

    return (
      <Fragment>
        <FormGroup>
          {addon}
          <Gallery
            fileInput-children={<span />}
            status-text={{ text: statusTextOverride }}
            style={{ minHeight: '150px' }}
            uploader={this.uploader}
          >
            <span className='react-fine-uploader-gallery-dropzone-content'>
              {placeholder || 'Drop files here'}
            </span>
          </Gallery>
        </FormGroup>
      </Fragment>
    )
  }
}

UploadField.defaultProps = {
  uploadUrl: FILE_UPLOAD,
  deleteUrl: FILE_DELETE,
  parentId: 0,
  onCancel: () => {},
  onComplete: () => {},
}

export default UploadField
