/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { compose, path } from 'ramda'
import React, { Fragment, Component } from "react";
import { connect } from 'react-redux'
import { reduxForm, Field, submit } from 'redux-form'
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  // FormGroup,
  Form,
  // Input,
  InputGroupAddon,
  InputGroupText,
  // InputGroup,
  Row,
  Col
} from "reactstrap";
import { signIn } from '../../redux/actions'
import { InputField, CheckboxField } from '../../components/Form'

const enhance = compose(
  connect(({ form }) => {
    const formValues = path(['LoginForm', 'values'], form)

    return {
      formValues,
    }
  }),
  reduxForm({
    form: 'LoginForm'
  })
)

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
  }

  handleSubmit(event) {
    const {
      formValues,
    } = this.props

    this.props.dispatch(signIn({
      ...formValues,
      type: 'EMAIL',
    }))
  }

  handleSuccess() {
    this.props.dispatch(submit('LoginForm'))
  }

  render() {
    return (
      <Fragment>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            {/*<CardHeader className="bg-transparent pb-5">*/}
            {/*  <div className="text-muted text-center mt-2 mb-3">*/}
            {/*    <small>Sign in with</small>*/}
            {/*  </div>*/}
            {/*  <div className="btn-wrapper text-center">*/}
            {/*    <Button*/}
            {/*      className="btn-neutral btn-icon"*/}
            {/*      color="default"*/}
            {/*      href="#pablo"*/}
            {/*      onClick={e => e.preventDefault()}*/}
            {/*    >*/}
            {/*      <span className="btn-inner--icon">*/}
            {/*        <img*/}
            {/*          alt="..."*/}
            {/*          src={require("assets/img/icons/common/github.svg")}*/}
            {/*        />*/}
            {/*      </span>*/}
            {/*      <span className="btn-inner--text">Github</span>*/}
            {/*    </Button>*/}
            {/*    <Button*/}
            {/*      className="btn-neutral btn-icon"*/}
            {/*      color="default"*/}
            {/*      href="#pablo"*/}
            {/*      onClick={e => e.preventDefault()}*/}
            {/*    >*/}
            {/*      <span className="btn-inner--icon">*/}
            {/*        <img*/}
            {/*          alt="..."*/}
            {/*          src={require("assets/img/icons/common/google.svg")}*/}
            {/*        />*/}
            {/*      </span>*/}
            {/*      <span className="btn-inner--text">Google</span>*/}
            {/*    </Button>*/}
            {/*  </div>*/}
            {/*</CardHeader>*/}
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h4>Введите логин и пароль, чтобы войти</h4>
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <Field
                  name='username'
                  component={InputField}
                  inputGroup={true}
                  addon={
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                  }
                />

                <Field
                  name='password'
                  component={InputField}
                  type='password'
                  inputGroup={true}
                  addon={
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                  }
                />

                {/*<Field*/}
                {/*  name='remember'*/}
                {/*  component={CheckboxField}*/}
                {/*  label='Remember me'*/}
                {/*/>*/}

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={this.handleSubmit}
                  >
                    Войти
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          {/*<Row className="mt-3">*/}
          {/*  <Col xs="6">*/}
          {/*    <a*/}
          {/*      className="text-light"*/}
          {/*      href="#pablo"*/}
          {/*      onClick={e => e.preventDefault()}*/}
          {/*    >*/}
          {/*      <small>Forgot password?</small>*/}
          {/*    </a>*/}
          {/*  </Col>*/}
          {/*  <Col className="text-right" xs="6">*/}
          {/*    <a*/}
          {/*      className="text-light"*/}
          {/*      href="#pablo"*/}
          {/*      onClick={e => e.preventDefault()}*/}
          {/*    >*/}
          {/*      <small>Create new account</small>*/}
          {/*    </a>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Col>
      </Fragment>
    );
  }
}

export default enhance(Login);
