import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { createOne, updateOne, deleteOne, copyFromCategory } from '../../../api/category_field'
import actionType, {
  CATEGORY_FIELD_CREATE,
  CATEGORY_FIELD_DELETE,
  CATEGORY_FIELD_COPY,
} from './actionTypes'
import * as actions from '../actions'

function* categoryFieldCreateRequest() {
  yield takeEvery(actionType[CATEGORY_FIELD_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? updateOne : createOne
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[CATEGORY_FIELD_CREATE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[CATEGORY_FIELD_CREATE].error,
        error,
      })
    }
  })
}

function* categoryFieldCreateSuccess() {
  yield takeEvery(actionType[CATEGORY_FIELD_CREATE].success, function* ({ args }) {
    const parent_id = prop('parent_id', args)

    yield put(
      actions.category({
        id: parent_id,
      })
    )
  })
}

function* categoryFieldDeleteRequest() {
  yield takeEvery(actionType[CATEGORY_FIELD_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(deleteOne, payload)

      yield put({
        type: actionType[CATEGORY_FIELD_DELETE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[CATEGORY_FIELD_DELETE].error,
        error,
      })
    }
  })
}

function* categoryFieldDeleteSuccess() {
  yield takeEvery(actionType[CATEGORY_FIELD_DELETE].success, function* ({ args }) {
    const categoryId = prop('category_id', args)
    yield put(
      actions.category({
        id: categoryId,
      })
    )
  })
}

function* categoryFieldCopyRequest() {
  yield takeEvery(actionType[CATEGORY_FIELD_COPY].request, function* ({ payload }) {
    try {
      const { data } = yield call(copyFromCategory, payload)

      yield put({
        type: actionType[CATEGORY_FIELD_COPY].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[CATEGORY_FIELD_COPY].error,
        error,
      })
    }
  })
}

function* categoryFieldCopySuccess() {
  yield takeEvery(actionType[CATEGORY_FIELD_COPY].success, function* ({ args }) {
    const categoryId = prop('category_id', args)

    yield put(
      actions.category({
        id: categoryId,
      })
    )
  })
}

export default function* rootSaga() {
  yield all([
    fork(categoryFieldCreateRequest),
    fork(categoryFieldCreateSuccess),
    fork(categoryFieldDeleteRequest),
    fork(categoryFieldDeleteSuccess),
    fork(categoryFieldCopyRequest),
    fork(categoryFieldCopySuccess),
  ])
}
