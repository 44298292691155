import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { countryGet, countryCreate, countryUpdate, countryDelete } from '../../api/country'
import actionType, { COUNTRY, COUNTRY_CREATE, COUNTRY_DELETE } from './actionTypes'
import * as route from '../../constants/routes'

function* countryRequest() {
  yield takeEvery(actionType[COUNTRY].request, function* ({ payload }) {
    try {
      const { data } = yield call(countryGet, payload)

      yield put({
        type: actionType[COUNTRY].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[COUNTRY].error,
        error,
      })
    }
  })
}

function* countryCreateRequest() {
  yield takeEvery(actionType[COUNTRY_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? countryUpdate : countryCreate
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[COUNTRY_CREATE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[COUNTRY_CREATE].error,
        error,
      })
    }
  })
}

function* countryCreateSuccess() {
  yield takeEvery(actionType[COUNTRY_CREATE].success, function* () {
    yield put(push(route.ADMIN_COUNTRY_LIST))
  })
}

function* countryDeleteRequest() {
  yield takeEvery(actionType[COUNTRY_DELETE].request, function* ({ payload }) {
    try {
      const { data } = yield call(countryDelete, payload)

      yield put({
        type: actionType[COUNTRY_DELETE].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[COUNTRY_DELETE].error,
        error,
      })
    }
  })
}

function* countryDeleteSuccess() {
  yield takeEvery(actionType[COUNTRY_DELETE].success, function* () {
    yield put(push(route.ADMIN_COUNTRY_LIST))
  })
}

export default function* rootSaga() {
  yield all([
    fork(countryRequest),
    fork(countryCreateRequest),
    fork(countryCreateSuccess),
    fork(countryDeleteRequest),
    fork(countryDeleteSuccess),
  ])
}
