import { compose, path, prop, propOr, map, find, propEq, pathOr } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import Header from '../components/Headers/Header'
import CreateForm from '../components/Categories/CategoryCreateForm'
import * as route from '../constants/routes'
import * as actions from '../redux/actions'
import Table from '../components/Table'
import queryString from 'query-string'
import { hasAnyRole } from '../helpers/get'
import { ADMIN, MODERATOR } from '../constants/userRole'
import LoadingContent from '../components/Placeholder/LoadingContent'
import { Russia, UnitedKingdom, Uzbekistan } from 'react-flat-flags'
import classnames from 'classnames'
import FieldsTable from '../components/Table/FieldsTable'
import FieldsModal from '../components/Modal/FieldsModal'
import FieldOptionsModal from '../components/Modal/FieldOptionsModal'
import CopyFieldsModal from '../components/Modal/CopyFieldsModal'
import SubCategoryFieldCreateForm from '../components/Categories/SubCategoryFieldCreateForm'
import SubCategoryFieldOptionCreateForm from '../components/Categories/SubCategoryFieldOptionCreateForm'

const enhance = compose(
  connect(({ category, rubricList, userInfo, form, router }) => {
    const rubricOptions = map(
      item => ({
        label: path(['title', 'ru'], item),
        value: prop('id', item),
      }),
      propOr([], 'data', rubricList)
    )

    const item = prop('data', category)
    const loading = prop('loading', category)
    const itemsList = prop('items', item)

    const fieldsList = prop('fields', item)
    const formValues = path(['CategoryCreateForm', 'values'], form)
    const fieldFormValues = path(['SubCategoryFieldCreateForm', 'values'], form)
    const fieldOptionFormValues = path(['SubCategoryFieldOptionCreateForm', 'values'], form)

    const roles = pathOr([], ['data', 'roles'], userInfo)
    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    const categoriesList = path(['data', 'rubric', 'category'], category)
    const state = path(['location', 'state'], router)
    const defaultOptionId = prop('default_option_id', state)

    return {
      roles,
      item,
      loading,
      itemsList,
      rubricOptions,
      defaultOptionId,
      formValues,
      pathname,
      query,
      //FIELDS
      categoriesList,
      fieldsList,
      fieldFormValues,
      fieldOptionFormValues,
    }
  })
)

function Category(props) {
  const {
    roles,
    history,
    pathname,
    query,
    item,
    loading,
    itemsList,
    rubricOptions,
    defaultOptionId,
    formValues,
    //FIELDS
    fieldFormValues,
    fieldOptionFormValues,
    categoriesList,
    fieldsList,
  } = props
  const id = prop('id', query)
  const refresh = prop('categoryRefresh', query) || 'false'

  // <<<--- FIELDS START --->>>
  const [copyFieldModalOpened, setCopyFieldModalOpened] = useState(false)
  const [currentCopyCategoryId, setCurrentCopyCategoryId] = useState(null)
  const [deleteFieldModalOpened, setDeleteFieldModalOpened] = useState(false)
  const [currentDeleteFieldId, setCurrentDeleteFieldId] = useState(null)
  const [modalIsOpened, setModalIsOpened] = useState(false)
  const [optionModalIsOpened, setOptionModalIsOpened] = useState(false)
  const [currentFieldId, setCurrentFieldId] = useState(null)
  const [currentFieldType, setCurrentFieldType] = useState(null)
  const [fieldInitialValues, setFieldInitialValues] = useState({})
  const [fieldOptionInitialValues, setFieldOptionInitialValues] = useState({})
  const [currentOptions, setCurrentOptions] = useState(null)

  if (refresh === 'true') {
    props.dispatch(actions.categoryClear())
    if (+id > 0) {
      props.dispatch(
        actions.category({
          id,
        })
      )
    }
    history.push({
      pathname: route.ADMIN_CATEGORY,
      search: queryString.stringify({
        id,
      }),
    })
  }
  const typeOptions = [
    {
      label: 'Только для объявлений',
      value: 'FOR_ADVERT_ONLY',
    },
    {
      label: 'Только для магазина',
      value: 'FOR_STORE_ONLY',
    },
    {
      label: 'Для объявлений и магазинов',
      value: 'FOR_ADVERT_STORE',
    },
  ]
  const categoryTypes = {
    FOR_ADVERT_ONLY: 'Только для объявлений',
    FOR_STORE_ONLY: 'Только для магазина',
    FOR_ADVERT_STORE: 'Для объявлений и магазинов',
  }

  function handleFieldSubmit() {
    let options = null
    if (
      currentFieldType === 'SINGLE_CHOICE' ||
      currentFieldType === 'MULTIPLE_CHOICE' ||
      currentFieldType === 'NUMERIC_WITH_TYPE' ||
      currentFieldType === 'TEXT_WITH_TYPE' ||
      currentFieldType === 'DOUBLE_WITH_TYPE'
    ) {
      options = currentOptions
    }
    props.dispatch(
      actions.categoryFieldCreate({
        id: +currentFieldId > 0 ? currentFieldId : undefined,
        ...fieldFormValues,
        parent_id: id,
        options: options,
        type: path(['type', 'value'], fieldFormValues),
        visibility_type: path(['visibility_type', 'value'], fieldFormValues),
      })
    )

    setCurrentOptions(null)
    setModalIsOpened(false)
    setCurrentFieldId(null)
    setFieldOptionInitialValues(null)
  }

  function handleFieldOptionSubmit() {
    const optionId = fieldOptionFormValues['id']
    const title = fieldOptionFormValues['title']
    const priority = fieldOptionFormValues['priority']
    if (optionId) {
      currentOptions.find(option => option.id === optionId)['title'] = title
      currentOptions.find(option => option.id === optionId)['priority'] = priority
      setCurrentOptions(currentOptions)
    } else {
      const existingOptions = currentOptions || []
      setCurrentOptions([fieldOptionFormValues, ...existingOptions])
    }
    setOptionModalIsOpened(false)
  }

  function handleCopyFieldClicked() {
    props.dispatch(
      actions.categoryFieldCopy({
        category_id: id,
        from_id: currentCopyCategoryId,
        to_id: id,
      })
    )
    setCopyFieldModalOpened(false)
  }

  function handleFieldDelete(fieldId) {
    props.dispatch(
      actions.categoryFieldDelete({
        id: fieldId,
        category_id: id,
      })
    )
  }

  function handleFieldSuccess() {
    props.dispatch(submit('SubCategoryFieldCreateForm'))
  }

  function handleFieldOptionSuccess() {
    props.dispatch(submit('SubCategoryFieldOptionCreateForm'))
  }

  const categoryOptions = map(item => {
    return <option value={prop('id', item)}>{path(['title', 'ru'], item)}</option>
  }, categoriesList || [])

  // <<<--- FIELDS END --->>>

  useEffect(() => {
    if (!id) {
      history.push({
        pathname: route.ADMIN_RUBRIC_LIST,
        state: {
          from: pathname,
        },
      })
    }

    if (+id > 0) {
      props.dispatch(
        actions.category({
          id,
        })
      )
    }

    props.dispatch(actions.rubricList())

    return () => {
      props.dispatch(actions.categoryClear())
    }
  }, [])

  function handleSubmit() {
    props.dispatch(
      actions.categoryCreate({
        id: +id > 0 ? id : undefined,
        ...formValues,
        rubric_id: path(['rubric_id', 'value'], formValues),
        department_type: path(['department_type', 'value'], formValues),
      })
    )
  }

  function handleSuccess() {
    props.dispatch(submit('CategoryCreateForm'))
  }

  function handleDelete() {
    props.dispatch(
      actions.categoryDelete({
        id,
        rubricId,
      })
    )
  }

  function handleActivate() {
    props.dispatch(
      actions.categoryActivate({
        id,
        rubricId,
      })
    )
  }

  function handleSubCategory(id = 0) {
    const categories = prop('categories', item)
    history.push({
      pathname: route.ADMIN_SUBCATEGORY,
      search: queryString.stringify({
        id,
      }),
      state: {
        categories: categories,
        default_category_id: prop('id', item),
        from: pathname,
      },
    })
  }

  function handleBack() {
    history.push({
      pathname: route.ADMIN_RUBRIC_LIST,
      state: {
        from: pathname,
      },
    })
  }

  const tableHeader = [
    {
      title: 'ID',
    },
    {
      title: 'Наименование',
    },
    {
      title: 'Статус',
    },
  ]

  function getDeletedItem(deleted) {
    return (
      <span className='badge badge-dot mr-4'>
        <i className={(deleted && 'bg-danger') || 'bg-success'}></i>
        <span className='status'>{(deleted && 'отключено') || 'активен'}</span>
      </span>
    )
  }

  const innerItemsTableContent = map(item => {
    const style = {
      cursor: 'pointer',
    }

    return (
      <tr key={prop('id', item)} style={style} onClick={() => handleSubCategory(prop('id', item))}>
        <th scope='row'>{prop('id', item)}</th>
        <td>{path(['title', 'ru'], item)}</td>
        <td>{getDeletedItem(prop('deleted', item))}</td>
      </tr>
    )
  }, itemsList || [])

  const title = prop('title', item)
  const deleted = prop('deleted', item)
  const titleRu = prop('ru', title)
  const rubricId = path(['rubric', 'id'], item) || defaultOptionId
  const initialValues = {
    title: prop('title', item),
    description_visible: prop('description_visible', item),
    description: prop('description', item),
    rubric_id: find(propEq('value', rubricId), rubricOptions),
    meta_title: prop('meta_title', item),
    meta_description: prop('meta_description', item),
    meta_keywords: prop('meta_keywords', item),
    department_type: {
      label: prop(prop('department_type', item), categoryTypes),
      value: prop('department_type', item),
    },
  }

  const toggleNavs = (e, index) => {
    e.preventDefault()
    setTabs(index)
  }

  const [tabs, setTabs] = useState(1)

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <div className='nav-wrapper'>
              <Nav
                className='nav-fill flex-column flex-md-row'
                id='tabs-icons-text'
                pills
                role='tablist'
              >
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames('mb-sm-3 mb-md-0', {
                      active: tabs === 1,
                    })}
                    onClick={e => toggleNavs(e, 1)}
                    role='tab'
                  >
                    <Russia size={18} style={{ marginRight: '8px' }} />
                    Русский
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 2}
                    className={classnames('mb-sm-3 mb-md-0', {
                      active: tabs === 2,
                    })}
                    onClick={e => toggleNavs(e, 2)}
                    role='tab'
                  >
                    <Uzbekistan size={18} style={{ marginRight: '8px' }} />
                    Узбекский
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 3}
                    className={classnames('mb-sm-3 mb-md-0', {
                      active: tabs === 3,
                    })}
                    onClick={e => toggleNavs(e, 3)}
                    role='tab'
                  >
                    <UnitedKingdom size={18} style={{ marginRight: '8px' }} />
                    Английский
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Категория</h3>
                  </Col>
                  <Col xs='4' className='text-right'>
                    {+id > 0 && hasAnyRole(roles, [ADMIN, MODERATOR]) && (
                      <Button
                        size='sm'
                        color={(deleted && 'success') || 'danger'}
                        onClick={(deleted && handleActivate) || handleDelete}
                      >
                        {(deleted && 'Восстановить') || 'Отключить'}
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                {(loading === false && (
                  <CreateForm
                    typeOptions={typeOptions}
                    tabs={tabs}
                    initialValues={initialValues}
                    rubricOptions={rubricOptions}
                    onSubmit={handleSubmit}
                  />
                )) || <LoadingContent minHeight={'56px'} color={'#24bc79'} />}
              </CardBody>

              <CardFooter className='text-right'>
                <Button onClick={handleBack}>Назад</Button>

                <Button color={(+id > 0 && 'primary') || 'success'} onClick={handleSuccess}>
                  {(+id > 0 && 'Сохранить') || 'Создать'}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>

        {+id > 0 && (
          <Row>
            <div className='col'>
              <Card
                className='shadow'
                style={{
                  marginTop: '15px',
                }}
              >
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <Col xs='8'>
                      <h3 className='mb-0'>Подкатегории в категории "{titleRu}"</h3>
                    </Col>
                    <Col className='text-right' xs='4'>
                      {+id > 0 && hasAnyRole(roles, [ADMIN, MODERATOR]) && (
                        <Button size='sm' color='primary' onClick={() => handleSubCategory()}>
                          Создать
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>

                <Table
                  header={tableHeader}
                  content={
                    (innerItemsTableContent.length > 0 && innerItemsTableContent) || (
                      <tr>
                        <td colSpan='5' align='center'>
                          {(loading === false && 'Нету элементов') || (
                            <LoadingContent minHeight={'56px'} color={'#24bc79'} />
                          )}
                        </td>
                      </tr>
                    )
                  }
                />
              </Card>
            </div>
          </Row>
        )}

        {+id > 0 && (
          <FieldsTable
            parentIdKey='category_id'
            parentId={id}
            setFieldInitialValues={setFieldInitialValues}
            typeOptions={typeOptions}
            roles={roles}
            setCurrentFieldType={setCurrentFieldType}
            setModalIsOpened={setModalIsOpened}
            setCurrentFieldId={setCurrentFieldId}
            setCurrentDeleteFieldId={setCurrentDeleteFieldId}
            setCurrentOptions={setCurrentOptions}
            setDeleteFieldModalOpened={setDeleteFieldModalOpened}
            deleteFieldModalOpened={deleteFieldModalOpened}
            currentDeleteFieldId={currentDeleteFieldId}
            fieldsList={fieldsList}
            setCopyFieldModalOpened={setCopyFieldModalOpened}
            copyItemsList={categoriesList}
            setCurrentCopyItemId={setCurrentCopyCategoryId}
            handleFieldDelete={handleFieldDelete}
          />
        )}

        <FieldsModal
          modalIsOpened={modalIsOpened}
          typeOptions={typeOptions}
          setModalIsOpened={setModalIsOpened}
          currentFieldId={currentFieldId}
          currentFieldType={currentFieldType}
          fieldInitialValues={fieldInitialValues}
          setFieldOptionInitialValues={setFieldOptionInitialValues}
          setOptionModalIsOpened={setOptionModalIsOpened}
          setCurrentFieldType={setCurrentFieldType}
          currentOptions={currentOptions}
          handleFieldSubmit={handleFieldSubmit}
          handleFieldSuccess={handleFieldSuccess}
        />

        <FieldOptionsModal
          optionModalIsOpened={optionModalIsOpened}
          setOptionModalIsOpened={setOptionModalIsOpened}
          fieldOptionInitialValues={fieldOptionInitialValues}
          fieldOptionFormValues={fieldOptionFormValues}
          setCurrentOptions={setCurrentOptions}
          currentOptions={currentOptions}
          handleFieldOptionSubmit={handleFieldOptionSubmit}
          handleFieldOptionSuccess={handleFieldOptionSuccess}
        />

        {/*Copy modal*/}
        <CopyFieldsModal
          copyFieldModalOpened={copyFieldModalOpened}
          setCopyFieldModalOpened={setCopyFieldModalOpened}
          setCurrentCopyItemId={setCurrentCopyCategoryId}
          itemOptions={categoryOptions}
          handleCopyFieldClicked={handleCopyFieldClicked}
        />
      </Container>
    </Fragment>
  )
}

export default enhance(Category)
