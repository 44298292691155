import { Button, Modal, Row } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { find, map, path, prop, propEq } from 'ramda'
import { change } from 'redux-form'
import ProductCreateForm from '../Product/ProductCreateForm'
import { useDispatch } from 'react-redux'

function ProductModal(props) {
  const {
    modalIsOpened,
    setModalIsOpened,
    initialValues,
    handleSubmit,
    handleSuccess,
    item,
    rubricId,
    categoryId,
    rubricsList,
    listContent,
    productFormValues,
  } = props

  const creator = prop('creator', item)

  const [categoriesList, setCategoriesList] = useState(null)
  const [subCategoriesList, setSubCategoriesList] = useState(null)

  useEffect(() => {
    const rubric = (rubricsList && rubricsList.find(obj => obj.id === rubricId)) || {}
    const categories = rubric['items'] || []
    const category = categories.find(obj => obj.id === categoryId) || {}
    const subCategories = category['items'] || []

    setCategoriesList(categories)
    setSubCategoriesList(subCategories)
  }, [item])

  const dispatch = useDispatch()

  function onRubricChanged(option) {
    const chosenRubric = rubricsList.find(obj => obj.id === option.value)

    if (chosenRubric) {
      setCategoriesList(chosenRubric['items'] || [])
      dispatch(change('ProductCreateForm', 'category', null))
      dispatch(change('ProductCreateForm', 'subcategory', null))
    }
  }

  function onCategoryChanged(option) {
    const chosenCategory = categoriesList.find(obj => obj.id === option.value)

    if (chosenCategory) {
      setSubCategoriesList(chosenCategory['items'] || [])
      dispatch(change('ProductCreateForm', 'subcategory', null))
    }
  }

  const rubricOptions = map(
    item => ({
      label: path(['title', 'ru'], item),
      value: prop('id', item),
    }),
    rubricsList || []
  )

  const categoryOptions =
    (categoriesList !== null &&
      map(
        item => ({
          label: path(['title', 'ru'], item),
          value: prop('id', item),
        }),
        categoriesList || []
      )) ||
    []

  const subCategoryOptions =
    (subCategoriesList !== null &&
      map(
        item => ({
          label: path(['title', 'ru'], item),
          value: prop('id', item),
        }),
        subCategoriesList || []
      )) ||
    []

  return (
    <Modal
      className='modal-dialog-centered'
      size='xl'
      style={{ maxWidth: '1000px', width: '90%' }}
      isOpen={modalIsOpened}
      toggle={() => setModalIsOpened(false)}
    >
      <div className='modal-header'>
        <h4 className='modal-title' id='modal-title-default'>
          Редактировать объявление
        </h4>

        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={() => setModalIsOpened(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className='modal-body'>
        <label style={{ fontWeight: '500' }}>Владелец:</label>

        <div style={{ padding: '8px', marginBottom: '16px' }}>
          <Row>
            <a className='avatar rounded-circle' onClick={e => e.preventDefault()}>
              <img alt='...' src={prop('photo_url', creator)} />
            </a>
            <span style={{ marginTop: '12px', marginLeft: '8px' }}>
              {prop('first_name', creator) +
                ' ' +
                prop('last_name', creator) +
                ' (ID: ' +
                prop('id', creator) +
                ', Номер: ' +
                prop('phone', creator) +
                ')'}
            </span>
          </Row>
        </div>

        <ProductCreateForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          rubricOptions={rubricOptions}
          onRubricChanged={onRubricChanged}
          categoryOptions={categoryOptions}
          subCategoryOptions={subCategoryOptions}
          onCategoryChanged={onCategoryChanged}
          item={item}
          productFormValues={productFormValues}
        />
      </div>

      <div className='modal-footer'>
        <Button color='primary' className='ml-auto' type='button' onClick={handleSuccess}>
          Сохранить
        </Button>
      </div>
    </Modal>
  )
}

export default ProductModal
