import { Button, Input, Modal, Row } from 'reactstrap'
import React from 'react'
import SubCategoryBrandCreateForm from '../Categories/SubCategoryBrandCreateForm'
import { prop } from 'ramda'

function BrandsModal(props) {
  const {
    brandModalIsOpened,
    setBrandModalIsOpened,
    brandInitialValues,
    handleBrandSubmit,
    brandFormValues,
    handleBrandSuccess,
    currentBrands,
    setCurrentBrands,
  } = props

  function onDeleteBrandClicked() {
    const optionId = brandFormValues['id']
    if (optionId) {
      setCurrentBrands(currentBrands.filter(option => option.id !== optionId))
    }
    setBrandModalIsOpened(false)
  }

  return (
    <Modal
      className='modal-dialog-centered'
      size='sm'
      isOpen={brandModalIsOpened}
      toggle={() => setBrandModalIsOpened(false)}
    >
      <div className='modal-header'>
        <h4 className='modal-title' id='modal-title-default'>
          Марка
        </h4>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={() => setBrandModalIsOpened(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className='modal-body'>
        <SubCategoryBrandCreateForm
          initialValues={brandInitialValues}
          onSubmit={handleBrandSubmit}
        />
      </div>
      <div className='modal-footer'>
        {brandFormValues && brandFormValues['id'] !== undefined && (
          <Button color='danger' type='button' onClick={onDeleteBrandClicked}>
            Удалить
          </Button>
        )}
        <Button color='primary' className='ml-auto' type='button' onClick={handleBrandSuccess}>
          Сохранить
        </Button>
      </div>
    </Modal>
  )
}

export default BrandsModal
