import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch, Redirect } from 'react-router-dom'
import ReduxToastr from 'react-redux-toastr'
import { configureStore, history } from './redux/store'
import AdminLayout from 'layouts/Admin'
import AuthLayout from 'layouts/Auth'
import * as routes from './constants/routes'

import 'assets/vendor/nucleo/css/nucleo.css'
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
import 'assets/scss/argon-dashboard-react.scss'
import 'react-notification-alert/dist/animate.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

ReactDOM.render(
  <Provider store={configureStore()}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={routes.ADMIN} render={props => <AdminLayout {...props} />} />
        <Route path={routes.AUTH} render={props => <AuthLayout {...props} />} />
        <Redirect from={routes.ROOT} to={routes.ADMIN_DASHBOARD} />
      </Switch>
    </ConnectedRouter>

    <ReduxToastr
      timeOut={3000}
      newestOnTop={false}
      preventDuplicates={true}
      position='top-right'
      transitionIn='fadeIn'
      transitionOut='fadeOut'
      progressBar
    />
  </Provider>,
  document.getElementById('root')
)
