import { pipe, find, map, path, prop, propEq } from 'ramda'
import { getFieldTypeText, hasAnyRole } from '../../helpers/get'
import { Button, Card, CardHeader, Col, Modal, Row } from 'reactstrap'
import React from 'react'
import { ADMIN, MODERATOR } from '../../constants/userRole'
import { FIELD_TYPE_OPTIONS } from '../../constants/fieldTypeOptions'
import Table from './index'

function FieldsTable(props) {
  const {
    parentIdKey,
    parentId,
    setFieldInitialValues,
    typeOptions,
    roles,
    setCurrentFieldType,
    setModalIsOpened,
    setCurrentFieldId,
    setCurrentDeleteFieldId,
    setCurrentOptions,
    setDeleteFieldModalOpened,
    deleteFieldModalOpened,
    handleFieldDelete,
    currentDeleteFieldId,
    fieldsList,
    setCopyFieldModalOpened,
    copyItemsList,
    setCurrentCopyItemId,
  } = props

  const fieldsTableHeader = [
    {
      title: 'ID',
    },
    {
      title: 'Ключ',
    },
    {
      title: 'Наименование',
    },
    {
      title: 'Тип',
    },
    {
      title: 'Удаление',
    },
  ]

  function onFieldRowClicked(field) {
    if (hasAnyRole(roles, [ADMIN, MODERATOR])) {
      const visibility_type = pipe(prop('visibility_type'), item =>
        find(propEq('value', item), typeOptions)
      )(field)

      setFieldInitialValues({
        parentIdKey: parentId,
        title: prop('title', field),
        name: prop('name', field),
        max_length: prop('max_length', field),
        min_length: prop('min_length', field),
        optional: prop('optional', field),
        disabled: prop('disabled', field),
        for_store_only: prop('for_store_only', field),
        type: find(propEq('value', prop('type', field)), FIELD_TYPE_OPTIONS),
        visibility_type,
      })
      setCurrentFieldType(prop('type', field))
      setCurrentOptions(prop('options', field))
      setCurrentFieldId(prop('id', field))
      setModalIsOpened(true)
    }
  }

  const fieldsTableContent = map(item => {
    const style = {
      cursor: 'pointer',
    }

    return (
      <tr key={prop('id', item)} style={style}>
        <th
          scope='row'
          onClick={() => {
            onFieldRowClicked(item)
          }}
        >
          {prop('id', item)}
        </th>
        <th
          scope='row'
          onClick={() => {
            onFieldRowClicked(item)
          }}
        >
          {prop('name', item)}
        </th>
        <td
          onClick={() => {
            onFieldRowClicked(item)
          }}
        >
          {path(['title', 'ru'], item)}
        </td>
        <td
          onClick={() => {
            onFieldRowClicked(item)
          }}
        >
          {getFieldTypeText(prop('type', item))}
        </td>
        <td>
          <Button
            size='sm'
            color='danger'
            onClick={() => {
              setCurrentDeleteFieldId(prop('id', item))
              setDeleteFieldModalOpened(true)
            }}
          >
            Удалить
          </Button>
        </td>
      </tr>
    )
  }, fieldsList || [])

  return (
    <div>
      <Row>
        <div className='col'>
          <Card
            className='shadow'
            style={{
              marginTop: '15px',
            }}
          >
            <CardHeader className='border-0'>
              <Row className='align-items-center'>
                <Col xs='6'>
                  <h3 className='mb-0'>Дополнительные поля</h3>
                </Col>
                <Col className='text-right' xs='6'>
                  {hasAnyRole(roles, [ADMIN, MODERATOR]) && (
                    <Button
                      size='sm'
                      color='info'
                      onClick={() => {
                        setCopyFieldModalOpened(true)
                      }}
                    >
                      Скопировать с подкатегории
                    </Button>
                  )}
                  {hasAnyRole(roles, [ADMIN, MODERATOR]) && (
                    <Button
                      size='sm'
                      color='primary'
                      onClick={() => {
                        setFieldInitialValues({})
                        setCurrentFieldType(null)
                        setCurrentOptions(null)
                        setCurrentFieldId(0)
                        setModalIsOpened(true)
                      }}
                    >
                      Создать
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <Table header={fieldsTableHeader} content={fieldsTableContent} />
          </Card>
        </div>

        <Modal
          className='modal-dialog-centered modal-danger'
          contentClassName='bg-gradient-danger'
          size='sm'
          toggle={() => {
            setDeleteFieldModalOpened(false)
          }}
          isOpen={deleteFieldModalOpened}
        >
          <div className='modal-header'>
            <h3 className='modal-title' id='modal-title-notification'>
              Удалить поле?
            </h3>
            <button
              aria-label='Close'
              className='close'
              data-dismiss='modal'
              type='button'
              onClick={() => {
                setDeleteFieldModalOpened(false)
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className='modal-body'>
            <div className='py-3 text-center'>
              <i className='ni ni-bell-55 ni-3x' />
              <h5 className='heading mt-4'>Вы уверены, что хотите удалить это поле?</h5>
            </div>
          </div>
          <div className='modal-footer'>
            <Button
              className='text-white ml-auto'
              color='link'
              type='button'
              data-dismiss='modal'
              onClick={() => {
                setDeleteFieldModalOpened(false)
              }}
            >
              Нет
            </Button>
            <Button
              className='btn-white'
              color='default'
              data-dismiss='modal'
              type='button'
              onClick={() => {
                setDeleteFieldModalOpened(false)
                handleFieldDelete(currentDeleteFieldId)
              }}
            >
              Да, удалить
            </Button>
          </div>
        </Modal>
      </Row>
    </div>
  )
}

export default FieldsTable
