import createAction from '../../helpers/createAction'
import { USER, USERS_ALL, USER_BLOCK, USER_DELETE, USER_UNBLOCK } from '../actionTypes'

export default {
  [USER]: createAction(USER),
  [USERS_ALL]: createAction(USERS_ALL),
  [USER_BLOCK]: createAction(USER_BLOCK),
  [USER_DELETE]: createAction(USER_DELETE),
  [USER_UNBLOCK]: createAction(USER_UNBLOCK),
}
export { USER, USERS_ALL, USER_BLOCK, USER_DELETE, USER_UNBLOCK }
