import { prop, dissoc } from 'ramda'
import { AuthApi } from '../helpers/httpClients'
import { PAGE_CATEGORY, RUBRIC } from '../constants/api'
import { PAGE_CATEGORY_DELETE } from '../redux/actionTypes'

export const getAll = data => AuthApi().get(PAGE_CATEGORY + '/all', data)

//Without deleted ones
// export const getAll = data => AuthApi()
//   .get(PAGE_CATEGORY + '/', data)

export const getOne = data =>
  AuthApi().get(`${PAGE_CATEGORY}/${prop('id', data)}`, dissoc('id', data))

export const createOne = data => AuthApi().post(PAGE_CATEGORY + '/', data)

export const updateOne = data => AuthApi().put(`${PAGE_CATEGORY}/${prop('id', data)}`, data)

export const deleteOne = data => {
  if (prop('permanent', data)) {
    AuthApi().delete(`${PAGE_CATEGORY}/permanent/${prop('id', data)}`)
  } else {
    AuthApi().delete(`${PAGE_CATEGORY}/${prop('id', data)}`)
  }
}

export const activateOne = data => AuthApi().put(`${PAGE_CATEGORY}/activate/${prop('id', data)}`)
