import actionType, { CITY, CITY_CREATE, CITY_DELETE } from './actionTypes'

export const city = payload => ({
  type: actionType[CITY].request,
  payload,
})

export const cityClear = payload => ({
  type: actionType[CITY].clear,
  payload,
})

export const cityCreate = payload => ({
  type: actionType[CITY_CREATE].request,
  payload,
})

export const cityDelete = payload => ({
  type: actionType[CITY_DELETE].request,
  payload,
})
