import React, { Fragment } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Form, Row, Col } from 'reactstrap'
import { InputField } from '../Form'

const validate = values => {
  const errors = {}
  if (!values.old_password) {
    errors.old_password = 'Обязательно'
  }
  if (!values.password) {
    errors.password = 'Обязательно'
  }

  if (!values.password_repeat) {
    errors.password_repeat = 'Обязательно'
  } else {
    if (values.password_repeat.length > 0 && values.password.length > 0) {
      if (values.password_repeat !== values.password) errors.password_repeat = 'Пароли не совпадают'
    }
  }

  return errors
}

function UpdateProfileForm(props) {
  return (
    <Fragment>
      <Form>
        <div className='pl-lg-12'>
          <Row>
            <Col>
              <Field
                name='old_password'
                component={InputField}
                type='password'
                placeholder='Старый пароль'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Старый пароль
                  </label>
                }
              />
            </Col>
          </Row>
        </div>{' '}
        <div className='pl-lg-12'>
          <Row>
            <Col>
              <Field
                name='password'
                type='password'
                component={InputField}
                placeholder='Новый пароль'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Новый пароль
                  </label>
                }
              />
            </Col>
            <Col>
              <Field
                name='password_repeat'
                type='password'
                component={InputField}
                placeholder='Повторите пароль'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Повторите пароль
                  </label>
                }
              />
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'UpdateProfileForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate,
})(UpdateProfileForm)
