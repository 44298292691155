import actionType, {
  PAGE_CATEGORY,
  PAGE_CATEGORY_ACTIVATE,
  PAGE_CATEGORY_CREATE,
  PAGE_CATEGORY_DELETE,
} from './actionTypes'

export const pageCategory = payload => ({
  type: actionType[PAGE_CATEGORY].request,
  payload,
})

export const pageCategoryClear = payload => ({
  type: actionType[PAGE_CATEGORY].clear,
  payload,
})

export const pageCategoryCreate = payload => ({
  type: actionType[PAGE_CATEGORY_CREATE].request,
  payload,
})

export const pageCategoryDelete = payload => ({
  type: actionType[PAGE_CATEGORY_DELETE].request,
  payload,
})

export const pageCategoryActivate = payload => ({
  type: actionType[PAGE_CATEGORY_ACTIVATE].request,
  payload,
})
