import React from 'react'
import { Button, Modal, Row } from 'reactstrap'
import { prop, propOr } from 'ramda'
import UserEditForm from '../UsersList/UserEditForm'

function UsersListModal(props) {
  const {
    modalIsOpened,
    setModalIsOpened,
    user,
    handleSuccess,
    initialValues,
    handleSubmit,
  } = props
  return (
    <Modal
      className='modal-dialog-centered'
      size='xl'
      style={{ maxWidth: '1000px', width: '90%' }}
      isOpen={modalIsOpened}
      toggle={() => setModalIsOpened(false)}
    >
      <div className='modal-header'>
        <h4 className='modal-title' id='modal-title-default'>
          Информация о пользователе
        </h4>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={() => setModalIsOpened(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className='modal-body'>
        <div style={{ padding: '8px', marginBottom: '16px' }}>
          <Row>
            <a className='avatar rounded-circle' onClick={e => e.preventDefault()}>
              <img
                style={{
                  height: '100%',
                }}
                alt='...'
                src={prop('profile_pic', user)}
              />
            </a>
            <span style={{ marginTop: '12px', marginLeft: '8px' }}>
              {propOr('', 'first_name', user) +
                ' ' +
                propOr('', 'last_name', user) +
                ' (ID: ' +
                propOr('', 'id', user) +
                ')'}
              &nbsp; status: {propOr('', 'status', user)}
            </span>
          </Row>
        </div>
        <UserEditForm initialValues={initialValues} onSubmit={handleSubmit} />

        <div className='modal-footer'>
          <Button color='primary' className='ml-auto' type='button' onClick={handleSuccess}>
            Сохранить
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default UsersListModal
