import React, { Fragment } from 'react'
import { reduxForm, Field, FormSection } from 'redux-form'
import {
  Form,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { CheckboxField, InputField, SelectField } from '../Form'
import { find, map, path, prop, propEq } from 'ramda'

function SubCategoryBrandCreateForm(props) {
  return (
    <Fragment>
      <Form>
        <div className='pl-lg-4'>
          <FormSection name='title'>
            <Row>
              <Col>
                <Field
                  name='ru'
                  component={InputField}
                  placeholder='Название (RU)'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название (RU)
                    </label>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name='uz'
                  component={InputField}
                  placeholder='Название (UZ)'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название (UZ)
                    </label>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name='en'
                  component={InputField}
                  placeholder='Название (EN)'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название (EN)
                    </label>
                  }
                />
              </Col>
            </Row>
          </FormSection>
        </div>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'SubCategoryBrandCreateForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SubCategoryBrandCreateForm)
