import React, { Fragment } from 'react'
import { reduxForm, Field, FormSection } from 'redux-form'
import { Form, Row, Col } from 'reactstrap'
import { InputField, SelectField } from '../Form'
import SingleImageUploader from '../Form/SingleImageUploader'

function PageCategoryCreateForm(props) {
  const {
    positionTypeOptions,
    categoryTypeOptions,
    rubricOptions,
    categoryOptions,
    subCategoryOptions,
    onCategoryTypeChange,
    onRubricChanged,
    onCategoryChanged,
    currentCategoryType,
    initialValues,
  } = props

  return (
    <Fragment>
      <Form>
        <div className='pl-lg-4'>
          <FormSection name='title'>
            <Row>
              <Col lg='4'>
                <Field
                  name='ru'
                  component={InputField}
                  placeholder='Заголовок на русском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Заголовок на русском *
                    </label>
                  }
                />
              </Col>

              <Col lg='4'>
                <Field
                  name='uz'
                  component={InputField}
                  placeholder='Заголовок на узбекском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Заголовок на узбекском
                    </label>
                  }
                />
              </Col>

              <Col lg='4'>
                <Field
                  name='en'
                  component={InputField}
                  placeholder='Заголовок  на английском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Заголовок на английском
                    </label>
                  }
                />
              </Col>
            </Row>
          </FormSection>

          <FormSection name='subtitle'>
            <Row>
              <Col lg='4'>
                <Field
                  name='ru'
                  component={InputField}
                  placeholder='Подзаголовок на русском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Подзаголовок на русском
                    </label>
                  }
                />
              </Col>

              <Col lg='4'>
                <Field
                  name='uz'
                  component={InputField}
                  placeholder='Подзаголовок на узбекском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Подзаголовок на узбекском
                    </label>
                  }
                />
              </Col>

              <Col lg='4'>
                <Field
                  name='en'
                  component={InputField}
                  placeholder='Подзаголовок  на английском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Подзаголовок на английском
                    </label>
                  }
                />
              </Col>
            </Row>
          </FormSection>

          <Row>
            <Col lg={4}>
              <Field
                name='priority'
                onKeyPress={event => {
                  const isValid = event.charCode >= 48 && event.charCode <= 57 // Only numbers
                  if (!isValid) event.preventDefault()
                }}
                component={InputField}
                placeholder='Приоритет'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Приоритет
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Field
                name='main_color'
                component={InputField}
                placeholder='Основной цвет (в hex)'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Основной цвет
                  </label>
                }
              />
            </Col>

            <Col lg={6}>
              <Field
                name='secondary_color'
                component={InputField}
                placeholder='Дополнительный цвет (необязательно)'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Дополнительный цвет (необязательно)
                  </label>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg='6'>
              <Field
                name='position_type'
                component={SelectField}
                placeholder='Расположение'
                options={positionTypeOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Расположение
                  </label>
                }
              />
            </Col>

            <Col lg='6'>
              <Field
                name='category_type'
                component={SelectField}
                placeholder='Тип'
                onChange={onCategoryTypeChange}
                options={categoryTypeOptions}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Тип
                  </label>
                }
              />
            </Col>
          </Row>

          {currentCategoryType !== null && (
            <Row>
              {(currentCategoryType === 'RUBRIC' ||
                currentCategoryType === 'CATEGORY' ||
                currentCategoryType === 'SUBCATEGORY') && (
                <Col lg='4'>
                  <Field
                    name='rubric'
                    onChange={onRubricChanged}
                    component={SelectField}
                    placeholder='Рубрика'
                    options={rubricOptions}
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Рубрика
                      </label>
                    }
                  />
                </Col>
              )}

              {(currentCategoryType === 'CATEGORY' || currentCategoryType === 'SUBCATEGORY') && (
                <Col lg='4'>
                  <Field
                    name='category'
                    onChange={onCategoryChanged}
                    component={SelectField}
                    placeholder='Категория'
                    options={categoryOptions}
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Категория
                      </label>
                    }
                  />
                </Col>
              )}

              {currentCategoryType === 'SUBCATEGORY' && (
                <Col lg='4'>
                  <Field
                    name='subcategory'
                    component={SelectField}
                    placeholder='Подкатегория'
                    options={subCategoryOptions}
                    addon={
                      <label className='form-control-label' htmlFor='input-username'>
                        Подкатегория
                      </label>
                    }
                  />
                </Col>
              )}
            </Row>
          )}

          <Row>
            <Col lg='6'>
              <Field
                name='icon'
                component={SingleImageUploader}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Иконка
                  </label>
                }
              />
            </Col>

            <Col lg='6'>
              <Field
                name='image'
                component={SingleImageUploader}
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Изображение
                  </label>
                }
              />
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'PageCategoryCreateForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(PageCategoryCreateForm)
