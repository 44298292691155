import createAction from '../../helpers/createAction'
import { COUNTRY, COUNTRY_CREATE, COUNTRY_DELETE } from '../actionTypes'

export default {
  [COUNTRY]: createAction(COUNTRY),
  [COUNTRY_CREATE]: createAction(COUNTRY_CREATE),
  [COUNTRY_DELETE]: createAction(COUNTRY_DELETE),
}

export { COUNTRY, COUNTRY_CREATE, COUNTRY_DELETE }
