import { compose, map, path, prop, length, equals, propOr, omit } from 'ramda'
import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Container, Card, CardHeader, CardFooter, Col, Row } from 'reactstrap'
import moment from 'moment'
import Header from '../components/Headers/Header'
import LoadingContent from '../components/Placeholder/LoadingContent'
import Table from '../components/Table'
import ComplainModal from '../components/Complain/complainModal'
import TablePagination from '../components/Pagination'
import ComplainFilterForm from '../components/Complain/complainFilterForm'
import * as actions from '../redux/actions'
import TableSearch from '../components/Table/TableSearch'

const tableHeader = [
  {
    title: 'ID',
  },
  {
    title: 'Статус',
  },
  {
    title: 'Объявление',
  },
  {
    title: 'Жалобщик',
  },
  {
    title: 'Дата создания',
  },
]

const enhance = compose(
  connect(({ complainList, router }) => {
    const data = prop('data', complainList)
    const loading = prop('loading', complainList)
    const list = prop('content', data)
    const listLength = prop('total_elements', data)
    const isLastPage = prop('last', data)
    const pageSize = path(['pageable', 'pageSize'], data)
    const totalPages = prop('total_pages', data)

    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    return {
      loading,
      list,
      listLength,
      isLastPage,
      pageSize,
      totalPages,
      pathname,
      query,
    }
  })
)

function ComplainList(props) {
  const {
    dispatch,
    history,
    pathname,
    query,
    loading,
    list,
    listLength,
    isLastPage,
    pageSize,
  } = props

  const currentPage = propOr(1, 'page', query)

  useEffect(() => {
    dispatch(actions.complainList({
      ...query,
      page: currentPage - 1,
      search: prop('search', query),
    }))
  }, [query])

  const [complainModal, setComplainModal] = useState({
    open: false,
    complain: undefined,
  })

  function handleOpenComplainModal(complain) {
    setComplainModal({
      open: true,
      complain,
    })
  }

  function handleCloseComplainModal() {
    setComplainModal({
      open: false,
      complain: undefined,
    })
  }

  const tableContent = map(item => {
    const style = {
      cursor: 'pointer',
    }

    return (
      <tr key={prop('id', item)} style={style} onClick={() => handleOpenComplainModal(item)}>
        <th scope='row'>{prop('id', item)}</th>
        <td>{prop('type', item)}</td>
        <td>{path(['ad', 'headline'], item)}</td>
        <td>
          {path(['user', 'first_name'], item)}
          &nbsp;
          {path(['user', 'last_name'], item)}
        </td>
        <td>{moment(prop('created_at', item)).format('DD.MM.YYYY HH:MM')}</td>
      </tr>
    )
  }, list || [])

  const Content = () => {
    if (loading) {
      return (
        <td colSpan='4' align='center'>
          <LoadingContent minHeight={'56px'} color={'#24bc79'} />
        </td>
      )
    }

    if (equals(length(list), 0)) {
      return (
        <td colSpan='4' align='center'>
          Нету элементов
        </td>
      )
    }

    return tableContent
  }

  function handlePagination(page) {
    props.dispatch(
      actions.complainList({
        ...query,
        page: page,
      })
    )

    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        page: page,
      }),
    })
  }

  function handleFilter(event) {
    const startDate = moment(prop('start_date', event)).valueOf()
    const endDate = moment(prop('end_date', event)).valueOf()

    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        start_date: startDate,
        end_date: endDate
      }),
    })
  }

  function handleFilterClear() {
    history.push({
      pathname,
      search: queryString.stringify({
        ...omit(['start_date', 'end_date'], query),
      }),
    })
  }

  function initialValues() {
    const startDate = prop('start_date', query)
    const endDate = prop('end_date', query)

    return {
      start_date: startDate ? moment(+startDate).format('YYYY-MM-DD') : null,
      end_date: endDate ? moment(+endDate).format('YYYY-MM-DD') : null,
    }
  }

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Card className='shadow'>
          <CardHeader className='border-0'>
            <Row className='align-items-center'>
              <Col xs='6'>
                <h3 className='mb-0'>Жалобы</h3>
              </Col>

              <Col className='text-right' xs='6'>
                <ComplainFilterForm
                  initialValues={initialValues()}
                  onSubmit={handleFilter}
                  onClear={handleFilterClear}
                />
              </Col>
            </Row>
          </CardHeader>

          <Table header={tableHeader} content={<Content />} />

          <CardFooter className='py-4'>
            <nav aria-label='...'>
              <TablePagination
                totalElements={listLength}
                currentPage={currentPage}
                pageSize={pageSize}
                last={isLastPage}
                onChange={handlePagination}
              />
            </nav>
          </CardFooter>
        </Card>
      </Container>

      <ComplainModal
        open={complainModal.open}
        close={handleCloseComplainModal}
        complain={complainModal.complain}
      />
    </Fragment>
  )
}

export default enhance(ComplainList)
