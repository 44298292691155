import React, { Fragment } from 'react'
import { reduxForm, Field, FormSection } from 'redux-form'
import { Form, Row, Col } from 'reactstrap'
import { InputField, SelectField } from '../Form'

function CityCreateForm(props) {
  const { handleSubmit, regionOptions } = props

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <div className='pl-lg-0'>
          <FormSection name='name'>
            <Row>
              <Col>
                <Field
                  name='ru'
                  component={InputField}
                  placeholder='название на русском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название на русском
                    </label>
                  }
                />
              </Col>

              <Col>
                <Field
                  name='uz'
                  component={InputField}
                  placeholder='название на русском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название на узбекском
                    </label>
                  }
                />
              </Col>

              <Col>
                <Field
                  name='en'
                  component={InputField}
                  placeholder='название на русском'
                  addon={
                    <label className='form-control-label' htmlFor='input-username'>
                      Название на английском
                    </label>
                  }
                />
              </Col>
            </Row>
          </FormSection>

          <Row>
            <Col lg='4'>
              <Field
                name='region_id'
                component={SelectField}
                options={regionOptions}
                placeholder='область'
                addon={
                  <label className='form-control-label' htmlFor='input-username'>
                    Область
                  </label>
                }
              />
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  )
}

export default reduxForm({
  form: 'CityForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(CityCreateForm)
