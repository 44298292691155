import actionType, { USER_INFO, USER_UPDATE_PASSWORD } from './actionTypes'

export const userInfo = payload => ({
  type: actionType[USER_INFO].request,
  payload,
})

export const updateUserPassword = payload => ({
  type: actionType[USER_UPDATE_PASSWORD].request,
  payload,
})
