import { prop, dissoc } from 'ramda'
import { AuthApi } from '../helpers/httpClients'
import { DISTRICT } from '../constants/api'

export const districtList = data => AuthApi().get(DISTRICT + '/', data)

export const districtGet = data =>
  AuthApi().get(`${DISTRICT}/${prop('id', data)}`, dissoc('id', data))

export const districtCreate = data => AuthApi().post(DISTRICT + '/', data)

export const districtUpdate = data => AuthApi().put(`${DISTRICT}/${prop('id', data)}`, data)

export const districtDelete = data => AuthApi().delete(`${DISTRICT}/${prop('id', data)}`)
