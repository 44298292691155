import createAction from '../../helpers/createAction'
import { REGION, REGION_CREATE, REGION_DELETE } from '../actionTypes'

export default {
  [REGION]: createAction(REGION),
  [REGION_CREATE]: createAction(REGION_CREATE),
  [REGION_DELETE]: createAction(REGION_DELETE),
}

export { REGION, REGION_CREATE, REGION_DELETE }
