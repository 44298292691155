import { compose, path, prop, map, find, propEq, pathOr } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import Header from '../components/Headers/Header'
import CreateForm from '../components/Categories/SubCategoriesCreateForm'
import * as route from '../constants/routes'
import * as actions from '../redux/actions'
import queryString from 'query-string'
import { hasAnyRole } from '../helpers/get'
import { ADMIN, MODERATOR } from '../constants/userRole'
import classnames from 'classnames'
import { Russia, UnitedKingdom, Uzbekistan } from 'react-flat-flags'
import SubCategoryFieldCreateForm from '../components/Categories/SubCategoryFieldCreateForm'
import SubCategoryFieldOptionCreateForm from '../components/Categories/SubCategoryFieldOptionCreateForm'
import SubCategoryBrandCreateForm from '../components/Categories/SubCategoryBrandCreateForm'
import FieldsModal from '../components/Modal/FieldsModal'
import FieldOptionsModal from '../components/Modal/FieldOptionsModal'
import BrandsModal from '../components/Modal/BrandsModal'
import CopyFieldsModal from '../components/Modal/CopyFieldsModal'
import FieldsTable from '../components/Table/FieldsTable'

const enhance = compose(
  connect(({ subcategory, category, userInfo, form, router }) => {
    const item = prop('data', subcategory)
    const loading = prop('loading', subcategory)
    const fieldsList = prop('fields', item)

    const state = path(['location', 'state'], router)
    const categories = prop('categories', state)
    const defaultCategoryId = prop('default_category_id', state)
    const roles = pathOr([], ['data', 'roles'], userInfo)
    const categoryOptions = map(
      item => ({
        label: path(['title', 'ru'], item),
        value: prop('id', item),
      }),
      categories || []
    )

    const formValues = path(['SubCategoryCreateForm', 'values'], form)
    const fieldFormValues = path(['SubCategoryFieldCreateForm', 'values'], form)
    const fieldOptionFormValues = path(['SubCategoryFieldOptionCreateForm', 'values'], form)
    const brandFormValues = path(['SubCategoryBrandCreateForm', 'values'], form)

    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    const subcategoriesList = path(['data', 'items'], category)
    const brandsList = prop('brands', item)

    return {
      item,
      categories,
      loading,
      brandsList,
      fieldsList,
      roles,
      categoryOptions,
      defaultCategoryId,
      formValues,
      fieldFormValues,
      fieldOptionFormValues,
      brandFormValues,
      subcategoriesList,
      pathname,
      query,
    }
  })
)

function Subcategory(props) {
  const {
    history,
    categories,
    pathname,
    query,
    item,
    roles,
    brandsList,
    fieldsList,
    categoryOptions,
    defaultCategoryId,
    formValues,
    fieldFormValues,
    fieldOptionFormValues,
    brandFormValues,
    subcategoriesList,
  } = props
  const id = prop('id', query)
  const refresh = prop('subcategoryRefresh', query) || 'false'
  const deleted = prop('deleted', item)
  const categoryId = prop('category_id', item) || defaultCategoryId
  const initialCategoryId = find(propEq('value', categoryId), categoryOptions)

  useEffect(() => {
    if (!id || !categories) {
      history.push({
        pathname: route.ADMIN_RUBRIC_LIST,
        state: {
          from: pathname,
        },
      })
    }

    if (+id > 0) {
      props.dispatch(
        actions.subcategory({
          id,
        })
      )
    }

    return () => {
      props.dispatch(actions.subcategoryClear())
    }
  }, [])

  // <<<--- FIELDS START --->>>
  const [copyFieldModalOpened, setCopyFieldModalOpened] = useState(false)
  const [currentCopySubCategoryId, setCurrentCopySubCategoryId] = useState(null)
  const [deleteFieldModalOpened, setDeleteFieldModalOpened] = useState(false)
  const [currentDeleteFieldId, setCurrentDeleteFieldId] = useState(null)
  const [modalIsOpened, setModalIsOpened] = useState(false)
  const [optionModalIsOpened, setOptionModalIsOpened] = useState(false)
  const [currentFieldId, setCurrentFieldId] = useState(null)
  const [currentFieldType, setCurrentFieldType] = useState(null)
  const [fieldInitialValues, setFieldInitialValues] = useState({})
  const [fieldOptionInitialValues, setFieldOptionInitialValues] = useState({})
  const [currentOptions, setCurrentOptions] = useState([null])
  const [brandModalIsOpened, setBrandModalIsOpened] = useState(false)
  const [brandInitialValues, setBrandInitialValues] = useState({})
  const [currentBrands, setCurrentBrands] = useState(null)

  const typeOptions = [
    {
      label: 'Только для объявлений',
      value: 'FOR_ADVERT_ONLY',
    },
    {
      label: 'Только для магазина',
      value: 'FOR_STORE_ONLY',
    },
    {
      label: 'Для объявлений и магазинов',
      value: 'FOR_ADVERT_STORE',
    },
  ]
  if (refresh === 'true') {
    props.dispatch(actions.subcategoryClear())
    if (+id > 0) {
      props.dispatch(
        actions.subcategory({
          id,
        })
      )
    }
    history.push({
      pathname: route.ADMIN_SUBCATEGORY,
      search: queryString.stringify({
        id,
      }),
    })
  }

  useEffect(() => {
    if (item) {
      const categoryId = prop('category_id', item)
      props.dispatch(
        actions.category({
          id: categoryId,
        })
      )
    }
  }, [item])

  function handleFieldSubmit() {
    let options = null
    if (
      currentFieldType === 'SINGLE_CHOICE' ||
      currentFieldType === 'MULTIPLE_CHOICE' ||
      currentFieldType === 'NUMERIC_WITH_TYPE' ||
      currentFieldType === 'TEXT_WITH_TYPE' ||
      currentFieldType === 'DOUBLE_WITH_TYPE'
    ) {
      options = currentOptions
    }

    props.dispatch(
      actions.subcategoryFieldCreate({
        id: +currentFieldId > 0 ? currentFieldId : undefined,
        ...fieldFormValues,
        parent_id: id,
        options: options,
        type: path(['type', 'value'], fieldFormValues),
      })
    )

    setCurrentOptions(null)
    setModalIsOpened(false)
    setCurrentFieldId(null)
    setFieldOptionInitialValues(null)
  }

  function handleFieldOptionSubmit() {
    const optionId = fieldOptionFormValues['id']
    const title = fieldOptionFormValues['title']
    const priority = fieldOptionFormValues['priority']
    if (optionId) {
      currentOptions.find(option => option.id === optionId)['title'] = title
      currentOptions.find(option => option.id === optionId)['priority'] = priority
      setCurrentOptions(currentOptions)
    } else {
      const existingOptions = currentOptions || []
      setCurrentOptions([fieldOptionFormValues, ...existingOptions])
    }
    setOptionModalIsOpened(false)
  }

  function handleCopyFieldClicked() {
    props.dispatch(
      actions.subcategoryFieldCopy({
        subcategory_id: id,
        from_id: currentCopySubCategoryId,
        to_id: id,
      })
    )
    setCopyFieldModalOpened(false)
  }

  function handleFieldDelete(fieldId) {
    props.dispatch(
      actions.subcategoryFieldDelete({
        id: fieldId,
        subcategory_id: id,
      })
    )
  }

  function handleFieldSuccess() {
    props.dispatch(submit('SubCategoryFieldCreateForm'))
  }

  function handleFieldOptionSuccess() {
    props.dispatch(submit('SubCategoryFieldOptionCreateForm'))
  }

  const subcategoryOptions = map(item => {
    return <option value={prop('id', item)}>{path(['title', 'ru'], item)}</option>
  }, subcategoriesList || [])

  // <<<--- FIELDS END --->>>

  useEffect(() => {
    if (brandsList) {
      setCurrentBrands(brandsList)
    }
  }, [brandsList])

  function handleSubmit() {
    props.dispatch(
      actions.subcategoryCreate({
        id: +id > 0 ? id : undefined,
        ...formValues,
        category_id: path(['category_id', 'value'], formValues),
        brands: currentBrands || [],
        department_type: path(['department_type', 'value'], formValues),
      })
    )
  }

  function handleBrandSubmit() {
    const optionId = brandFormValues['id']
    const title = brandFormValues['title']
    if (optionId) {
      currentBrands.find(option => option.id === optionId)['title'] = title
      setCurrentBrands(currentBrands)
    } else {
      setCurrentBrands([brandFormValues, ...currentBrands])
    }
    setBrandModalIsOpened(false)
  }

  function handleSuccess() {
    props.dispatch(submit('SubCategoryCreateForm'))
  }

  function handleBrandSuccess() {
    props.dispatch(submit('SubCategoryBrandCreateForm'))
  }

  function handleDelete() {
    props.dispatch(
      actions.subcategoryDelete({
        id,
        category_id: categoryId,
      })
    )
  }

  function handleActivate() {
    props.dispatch(
      actions.subcategoryActivate({
        id,
        category_id: categoryId,
      })
    )
  }

  function handleBack() {
    history.push({
      pathname: route.ADMIN_RUBRIC_LIST,
      state: {
        from: pathname,
      },
    })
  }

  function onAddBrandClicked() {
    if (currentBrands === null) {
      setCurrentBrands([])
    }
    setBrandInitialValues({})
    setBrandModalIsOpened(true)
  }

  function onEditBrandClicked(item) {
    setBrandInitialValues({
      title: prop('title', item),
      id: prop('id', item),
    })
    setBrandModalIsOpened(true)
  }
  const categoryTypes = {
    FOR_ADVERT_ONLY: 'Только для объявлений',
    FOR_STORE_ONLY: 'Только для магазина',
    FOR_ADVERT_STORE: 'Для объявлений и магазинов',
  }

  const initialValues = {
    title: prop('title', item),
    description_visible: prop('description_visible', item),
    disable_watermark: prop('disable_watermark', item),
    description: prop('description', item),
    category_id: initialCategoryId,
    meta_title: prop('meta_title', item),
    meta_description: prop('meta_description', item),
    meta_keywords: prop('meta_keywords', item),
    department_type: {
      label: prop(prop('department_type', item), categoryTypes),
      value: prop('department_type', item),
    },
  }

  const toggleNavs = (e, index) => {
    e.preventDefault()
    setTabs(index)
  }

  const [tabs, setTabs] = useState(1)

  return (
    <Fragment>
      <Header />

      <Container className=' mt--7' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <div className='nav-wrapper'>
              <Nav
                className='nav-fill flex-column flex-md-row'
                id='tabs-icons-text'
                pills
                role='tablist'
              >
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames('mb-sm-3 mb-md-0', {
                      active: tabs === 1,
                    })}
                    onClick={e => toggleNavs(e, 1)}
                    role='tab'
                  >
                    <Russia size={18} style={{ marginRight: '8px' }} />
                    Русский
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 2}
                    className={classnames('mb-sm-3 mb-md-0', {
                      active: tabs === 2,
                    })}
                    onClick={e => toggleNavs(e, 2)}
                    role='tab'
                  >
                    <Uzbekistan size={18} style={{ marginRight: '8px' }} />
                    Узбекский
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 3}
                    className={classnames('mb-sm-3 mb-md-0', {
                      active: tabs === 3,
                    })}
                    onClick={e => toggleNavs(e, 3)}
                    role='tab'
                  >
                    <UnitedKingdom size={18} style={{ marginRight: '8px' }} />
                    Английский
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Подкатегория</h3>
                  </Col>
                  <Col xs='4' className='text-right'>
                    {+id > 0 && hasAnyRole(roles, [ADMIN, MODERATOR]) && (
                      <Button
                        size='sm'
                        color={(deleted && 'success') || 'danger'}
                        onClick={(deleted && handleActivate) || handleDelete}
                      >
                        {(deleted && 'Восстановить') || 'Отключить'}
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <CreateForm
                  typeOptions={typeOptions}
                  tabs={tabs}
                  onAddBrandClicked={onAddBrandClicked}
                  onEditBrandClicked={onEditBrandClicked}
                  brandsList={currentBrands}
                  initialValues={initialValues}
                  categoryOptions={categoryOptions}
                  onSubmit={handleSubmit}
                />
              </CardBody>

              <CardFooter className='text-right'>
                <Button onClick={handleBack}>Назад</Button>

                <Button color='primary' onClick={handleSuccess}>
                  Сохранить
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        {+id > 0 && (
          <FieldsTable
            parentIdKey='subcategory_id'
            parentId={id}
            setFieldInitialValues={setFieldInitialValues}
            typeOptions={typeOptions}
            roles={roles}
            setCurrentFieldType={setCurrentFieldType}
            setModalIsOpened={setModalIsOpened}
            setCurrentFieldId={setCurrentFieldId}
            setCurrentDeleteFieldId={setCurrentDeleteFieldId}
            setCurrentOptions={setCurrentOptions}
            setDeleteFieldModalOpened={setDeleteFieldModalOpened}
            deleteFieldModalOpened={deleteFieldModalOpened}
            currentDeleteFieldId={currentDeleteFieldId}
            fieldsList={fieldsList}
            setCopyFieldModalOpened={setCopyFieldModalOpened}
            copyItemsList={subcategoriesList}
            setCurrentCopyItemId={setCurrentCopySubCategoryId}
            handleFieldDelete={handleFieldDelete}
          />
        )}

        <FieldsModal
          typeOptions={typeOptions}
          modalIsOpened={modalIsOpened}
          setModalIsOpened={setModalIsOpened}
          currentFieldId={currentFieldId}
          currentFieldType={currentFieldType}
          fieldInitialValues={fieldInitialValues}
          setFieldOptionInitialValues={setFieldOptionInitialValues}
          setOptionModalIsOpened={setOptionModalIsOpened}
          setCurrentFieldType={setCurrentFieldType}
          currentOptions={currentOptions}
          handleFieldSubmit={handleFieldSubmit}
          handleFieldSuccess={handleFieldSuccess}
        />

        <FieldOptionsModal
          optionModalIsOpened={optionModalIsOpened}
          setOptionModalIsOpened={setOptionModalIsOpened}
          fieldOptionInitialValues={fieldOptionInitialValues}
          fieldOptionFormValues={fieldOptionFormValues}
          setCurrentOptions={setCurrentOptions}
          currentOptions={currentOptions}
          handleFieldOptionSubmit={handleFieldOptionSubmit}
          handleFieldOptionSuccess={handleFieldOptionSuccess}
        />

        {/*Copy modal*/}
        <CopyFieldsModal
          copyFieldModalOpened={copyFieldModalOpened}
          setCopyFieldModalOpened={setCopyFieldModalOpened}
          setCurrentCopyItemId={setCurrentCopySubCategoryId}
          itemOptions={subcategoryOptions}
          handleCopyFieldClicked={handleCopyFieldClicked}
        />

        {/*Brands Modal*/}
        <BrandsModal
          brandModalIsOpened={brandModalIsOpened}
          setBrandModalIsOpened={setBrandModalIsOpened}
          brandInitialValues={brandInitialValues}
          brandFormValues={brandFormValues}
          currentBrands={currentBrands}
          setCurrentBrands={setCurrentBrands}
          setBrandInitialValues={setBrandInitialValues}
          handleBrandSubmit={handleBrandSubmit}
          handleBrandSuccess={handleBrandSuccess}
        />
      </Container>
    </Fragment>
  )
}

export default enhance(Subcategory)
