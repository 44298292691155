import { prop } from 'ramda'
import React, { Fragment } from 'react'
import Select from 'react-select'
import { FormGroup } from 'reactstrap'

const customStyles = {
  control: style => {
    return {
      ...style,
      padding: '4px',
      borderColor: 'none',
      borderStyle: 'none',
      ':hover': {
        boxShadow: 'none',
        borderColor: '#ffffff !important',
      },
      ':active': {
        boxShadow: 'none',
        borderColor: '#ffffff !important',
      },
    }
  },
}

function SelectField(props) {
  const { input, placeholder, options, isMulti, addon } = props

  // const changedOptions = map(item => ({
  //   label: prop('value', item),
  //   value: prop('key', item),
  // }), options || [])

  return (
    <Fragment>
      <FormGroup>
        {addon}
        <Select
          className='form-control-alternative'
          styles={customStyles}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          value={prop('value', input)}
          onChange={prop('onChange', input)}
        />
        {props.meta.touched && props.meta.error && (
          <p style={{ color: 'red', fontSize: '12px' }}> {props.meta.error} </p>
        )}
      </FormGroup>
    </Fragment>
  )
}

export default SelectField
