import actionType, {
  CATEGORY_FIELD_CREATE,
  CATEGORY_FIELD_DELETE,
  CATEGORY_FIELD_COPY,
} from './actionTypes'

export const categoryFieldCreate = payload => ({
  type: actionType[CATEGORY_FIELD_CREATE].request,
  payload,
})

export const categoryFieldDelete = payload => ({
  type: actionType[CATEGORY_FIELD_DELETE].request,
  payload,
})

export const categoryFieldCopy = payload => ({
  type: actionType[CATEGORY_FIELD_COPY].request,
  payload,
})
