import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { countryList } from '../../api/country'
import actionType, { COUNTRY_LIST } from './actionTypes'

function* countryListRequest() {
  yield takeEvery(actionType[COUNTRY_LIST].request, function* ({ payload }) {
    try {
      const { data } = yield call(countryList, payload)

      yield put({
        type: actionType[COUNTRY_LIST].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[COUNTRY_LIST].error,
        error,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(countryListRequest)])
}
