import { compose, path, prop, propOr, map, pathOr, find, propEq, addIndex } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import Avatar from 'react-avatar'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap'
import Header from '../components/Headers/Header'
import Table from '../components/Table'
import * as route from '../constants/routes'
import * as actions from '../redux/actions'
import { hasAnyRole } from '../helpers/get'
import { ADMIN, MODERATOR } from '../constants/userRole'
import LoadingContent from '../components/Placeholder/LoadingContent'
import { pageCategoriesMap } from '../constants/pageCategories'

const tableHeader = [
  {
    title: '№',
  },
  {
    title: 'Наименование',
  },
  {
    title: 'Иконка',
  },
  {
    title: 'Изображение',
  },
  {
    title: 'Тип',
  },
  {
    title: 'ID типа',
  },
  {
    title: 'Статус',
  },
  {
    title: 'Приоритет',
  },
]
const indexedMap = addIndex(map)

const enhance = compose(
  connect(({ pageCategoryList, userInfo, router }) => {
    const itemsList = prop('data', pageCategoryList)

    const loading = prop('loading', pageCategoryList)

    const roles = pathOr([], ['data', 'roles'], userInfo)

    const pathname = path(['location', 'pathname'], router)
    const state = path(['location', 'state'], router)
    const query = path(['location', 'query'], router)

    return {
      itemsList,
      loading,
      roles,
      pathname,
      query,
      state,
    }
  })
)

function PageCategory(props) {
  const { history, pathname, loading, itemsList, roles, state } = props

  useEffect(() => {
    props.dispatch(actions.pageCategoryList())
  }, [])

  const [currentType, setCurrentType] = useState(null)
  const [currentTypeTitle, setCurrentTypeTitle] = useState(null)
  const [listContent, setListContent] = useState(null)

  function handleOnItemClick(id = 0) {
    if (hasAnyRole(roles, [ADMIN, MODERATOR])) {
      history.push({
        pathname: route.ADMIN_PAGE_CATEGORY,
        search: queryString.stringify({
          id,
        }),
        state: {
          from: pathname,
          current_type: currentType,
        },
      })
    }
  }

  const typeOptions = []

  if (itemsList) {
    const itemKeys = Object.keys(itemsList) || []
    const availablePageCategories = []

    itemKeys.map(function (key) {
      const pageCategory = find(propEq('key', key), pageCategoriesMap || [])
      if (pageCategory) {
        availablePageCategories.push(pageCategory)
        typeOptions.push(
          <DropdownItem
            key={key}
            onClick={e => {
              setCurrentType(pageCategory['key'])
              setCurrentTypeTitle(pageCategory['title'])
              setListContent(itemsList[pageCategory['key']])
            }}
          >
            {pageCategory['title']}
          </DropdownItem>
        )
      }
    })

    if (currentType === null && availablePageCategories.length > 0) {
      const firstPageCategory = availablePageCategories[0]
      if (firstPageCategory) {
        setCurrentType(firstPageCategory['key'])
        setCurrentTypeTitle(firstPageCategory['title'])
        setListContent(itemsList[firstPageCategory['key']])
      }
    }
  }

  useEffect(() => {
    const initialType = state && prop('current_type', state)
    if (initialType && itemsList) {
      const pageCategory = find(propEq('key', initialType), pageCategoriesMap || [])
      if (pageCategory) {
        setCurrentType(pageCategory['key'])
        setCurrentTypeTitle(pageCategory['title'])
        if (itemsList) setListContent(itemsList[pageCategory['key']])
      }
    }

    const refresh = state && prop('refresh', state)
    if (refresh) {
      console.log('REFRESH')
    }
    return () => {
      props.dispatch(actions.pageCategoryListClear())
    }
  }, [state])

  function getIconAvatar(item) {
    const url = prop('icon', item)
    const mainColor = propOr('#535353', 'main_color', item)
    if (url.endsWith('.svg')) {
      return (
        <div
          className='row'
          style={{
            backgroundColor: mainColor,
            width: '48px',
            height: '48px',
            paddingLeft: '8px',
            paddingTop: '8px',
            borderRadius: '48px',
          }}
        >
          <Avatar src={url} size='32' />
        </div>
      )
    } else {
      return <Avatar src={url} size='36' />
    }
  }

  function getDeletedItem(deleted) {
    return (
      <span className='badge badge-dot mr-4'>
        <i className={(deleted && 'bg-danger') || 'bg-success'}></i>
        <span className='status'>{(deleted && 'отключено') || 'активен'}</span>
      </span>
    )
  }

  const tableContent = indexedMap((item, index) => {
    const style = {
      cursor: 'pointer',
    }

    return (
      <tr key={prop('id', item)} style={style} onClick={() => handleOnItemClick(prop('id', item))}>
        <th scope='row'>{index + 1}</th>
        <td>{path(['title', 'ru'], item)}</td>
        <td>{(prop('icon', item) === null && <small>Нету</small>) || getIconAvatar(item)}</td>
        <td>
          {(prop('image', item) === null && <p>Нету</p>) || (
            <img src={prop('image', item)} style={{ maxWidth: '52px' }} />
          )}
        </td>
        <td>{prop('category_type', item)}</td>
        <td>{path(['info', 'id'], item)}</td>
        <td>{getDeletedItem(prop('deleted', item))}</td>
        <td>{prop('priority', item)}</td>
      </tr>
    )
  }, listContent || [])

  return (
    <Fragment>
      <Header />

      <Container className=' mt--9' fluid>
        <Row>
          <div className='col'>
            <Card className='shadow'>
              <CardHeader className='border-0'>
                <Row className='align-items-center'>
                  <Col xs='4'>
                    <h3 className='mb-0'>Категории для страниц</h3>
                  </Col>
                  <Col xs='4'>
                    <UncontrolledDropdown>
                      <DropdownToggle caret color='info'>
                        {(currentTypeTitle === null && 'Выберите тип') || currentTypeTitle}
                      </DropdownToggle>
                      <DropdownMenu>{typeOptions}</DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                  <Col className='text-right' xs='4'>
                    {hasAnyRole(roles, [ADMIN, MODERATOR]) && (
                      <Button size='sm' color='primary' onClick={() => handleOnItemClick()}>
                        Создать
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <Table
                header={tableHeader}
                content={
                  (tableContent.length > 0 && tableContent) || (
                    <tr>
                      <td colSpan='9' align='center'>
                        {(loading === false && 'Нету элементов') || (
                          <LoadingContent minHeight={'56px'} color={'#24bc79'} />
                        )}
                      </td>
                    </tr>
                  )
                }
              />
            </Card>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default enhance(PageCategory)
