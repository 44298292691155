import { prop } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { getOne, createOne, updateOne, deleteOne, activateOne } from '../../api/pageCategory'
import actionType, {
  PAGE_CATEGORY,
  PAGE_CATEGORY_CREATE,
  PAGE_CATEGORY_DELETE,
  PAGE_CATEGORY_ACTIVATE,
} from './actionTypes'
import * as route from '../../constants/routes'
import { CATEGORY_FIELD_CREATE } from '../actionTypes'
import queryString from 'query-string'

function* getOneRequest() {
  yield takeEvery(actionType[PAGE_CATEGORY].request, function* ({ payload }) {
    try {
      const { data } = yield call(getOne, payload)

      yield put({
        type: actionType[PAGE_CATEGORY].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[PAGE_CATEGORY].error,
        error,
      })
    }
  })
}

function* createOneRequest() {
  yield takeEvery(actionType[PAGE_CATEGORY_CREATE].request, function* ({ payload }) {
    try {
      const api = prop('id', payload) ? updateOne : createOne
      const { data } = yield call(api, payload)

      yield put({
        type: actionType[PAGE_CATEGORY_CREATE].success,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[PAGE_CATEGORY_CREATE].error,
        error,
      })
    }
  })
}

function* createOneSuccess() {
  yield takeEvery(actionType[PAGE_CATEGORY_CREATE].success, function* ({ args }) {
    const current_type = prop('current_type', args)
    yield put(
      push({
        pathname: route.ADMIN_PAGE_CATEGORY_LIST,
        state: {
          current_type: current_type,
          refresh: true,
        },
      })
    )
  })
}

function* deleteOneRequest() {
  yield takeEvery(actionType[PAGE_CATEGORY_DELETE].request, function* ({ payload }) {
    try {
      yield call(deleteOne, payload)

      yield put({
        type: actionType[PAGE_CATEGORY_DELETE].success,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[PAGE_CATEGORY_DELETE].error,
        error,
      })
    }
  })
}

function* deleteOneSuccess() {
  yield takeEvery(actionType[PAGE_CATEGORY_DELETE].success, function* ({ args }) {
    const current_type = prop('current_type', args)
    yield put(
      push({
        pathname: route.ADMIN_PAGE_CATEGORY_LIST,
        state: {
          current_type: current_type,
          refresh: true,
        },
      })
    )
  })
}

function* activateOneRequest() {
  yield takeEvery(actionType[PAGE_CATEGORY_ACTIVATE].request, function* ({ payload }) {
    try {
      yield call(activateOne, payload)

      yield put({
        type: actionType[PAGE_CATEGORY_ACTIVATE].success,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionType[PAGE_CATEGORY_ACTIVATE].error,
        error,
      })
    }
  })
}

function* activateOneSuccess() {
  yield takeEvery(actionType[PAGE_CATEGORY_ACTIVATE].success, function* ({ args }) {
    const current_type = prop('current_type', args)
    yield put(
      push({
        pathname: route.ADMIN_PAGE_CATEGORY_LIST,
        state: {
          current_type: current_type,
          refresh: true,
        },
      })
    )
  })
}

export default function* rootSaga() {
  yield all([
    fork(getOneRequest),
    fork(createOneRequest),
    fork(createOneSuccess),
    fork(deleteOneRequest),
    fork(deleteOneSuccess),
    fork(activateOneRequest),
    fork(activateOneSuccess),
  ])
}
