import React, { Fragment } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { Field } from 'redux-form'
import MaskedInputField from '../Form/MaskedInputField'

const RenderPhones = ({ fields }) => {
  return (
    <Fragment>
      {fields.map((field, idx) => {
        return (
          <Row key={idx}>
            <Col md={3}>
              <Button color='danger' style={{ margin: '0px' }} onClick={() => fields.remove(idx)}>
                Удалить
              </Button>
            </Col>
            <Col>
              <Field name={field} component={MaskedInputField} />
            </Col>
          </Row>
        )
      })}
      <Row>
        <Col>
          <Button color='success' primary='true' onClick={() => fields.push({})}>
            Добавить
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

export default RenderPhones
