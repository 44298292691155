import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { getAll } from '../../api/complain'
import actionType, { COMPLAIN_LIST } from './actionTypes'

function* complainListRequest() {
  yield takeEvery(actionType[COMPLAIN_LIST].request, function* ({ payload }) {
    try {
      const { data } = yield call(getAll, payload)

      yield put({
        type: actionType[COMPLAIN_LIST].success,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: actionType[COMPLAIN_LIST].error,
        error,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(complainListRequest)])
}
