import { Button, Input, Modal } from 'reactstrap'
import React from 'react'

function CopyFieldsModal(props) {
  const {
    copyFieldModalOpened,
    setCopyFieldModalOpened,
    setCurrentCopyItemId,
    itemOptions,
    handleCopyFieldClicked,
  } = props

  return (
    <Modal
      className='modal-dialog-centered'
      size='sm'
      isOpen={copyFieldModalOpened}
      toggle={() => setCopyFieldModalOpened(false)}
    >
      <div className='modal-header'>
        <h4 className='modal-title' id='modal-title-default'>
          Выберите откуда хотите скопировать поля
        </h4>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={() => {
            setCurrentCopyItemId(null)
            setCopyFieldModalOpened(false)
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className='modal-body'>
        <Input
          className='form-control-sm'
          type='select'
          onChange={e => {
            setCurrentCopyItemId(e.target.value)
          }}
        >
          {itemOptions}
        </Input>
      </div>
      <div className='modal-footer'>
        <Button color='success' className='ml-auto' type='button' onClick={handleCopyFieldClicked}>
          Скопировать
        </Button>
      </div>
    </Modal>
  )
}

export default CopyFieldsModal
