export const TOKEN = 'TOKEN'
export const SIGN_IN = 'SIGN_IN'
export const USER_INFO = 'USER_INFO'
export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD'

export const COUNTRY = 'COUNTRY'
export const COUNTRY_CREATE = 'COUNTRY_CREATE'
export const COUNTRY_DELETE = 'COUNTRY_DELETE'
export const COUNTRY_LIST = 'COUNTRY_LIST'

export const REGION = 'REGION'
export const REGION_CREATE = 'REGION_CREATE'
export const REGION_DELETE = 'REGION_DELETE'
export const REGION_LIST = 'REGION_LIST'

export const CITY = 'CITY'
export const CITY_CREATE = 'CITY_CREATE'
export const CITY_DELETE = 'CITY_DELETE'
export const CITY_LIST = 'CITY_LIST'

export const DISTRICT = 'DISTRICT'
export const DISTRICT_CREATE = 'DISTRICT_CREATE'
export const DISTRICT_DELETE = 'DISTRICT_DELETE'
export const DISTRICT_LIST = 'DISTRICT_LIST'

export const RUBRIC = 'RUBRIC'
export const RUBRIC_CREATE = 'RUBRIC_CREATE'
export const RUBRIC_DELETE = 'RUBRIC_DELETE'
export const RUBRIC_ACTIVATE = 'RUBRIC_ACTIVATE'
export const RUBRIC_LIST = 'RUBRIC_LIST'
export const RUBRIC_ACTIVE_LIST = 'RUBRIC_ACTIVE_LIST'

export const RUBRIC_FIELD_CREATE = 'RUBRIC_FIELD_CREATE'
export const RUBRIC_FIELD_DELETE = 'RUBRIC_FIELD_DELETE'
export const RUBRIC_FIELD_COPY = 'RUBRIC_FIELD_COPY'

export const CATEGORY = 'CATEGORY'
export const CATEGORY_CREATE = 'CATEGORY_CREATE'
export const CATEGORY_DELETE = 'CATEGORY_DELETE'
export const CATEGORY_ACTIVATE = 'CATEGORY_ACTIVATE'
export const CATEGORY_LIST = 'CATEGORY_LIST'

export const CATEGORY_FIELD_CREATE = 'CATEGORY_FIELD_CREATE'
export const CATEGORY_FIELD_DELETE = 'CATEGORY_FIELD_DELETE'
export const CATEGORY_FIELD_COPY = 'CATEGORY_FIELD_COPY'

export const SUBCATEGORY = 'SUBCATEGORY'
export const SUBCATEGORY_CREATE = 'SUBCATEGORY_CREATE'
export const SUBCATEGORY_DELETE = 'SUBCATEGORY_DELETE'
export const SUBCATEGORY_ACTIVATE = 'SUBCATEGORY_ACTIVATE'
export const SUBCATEGORY_LIST = 'SUBCATEGORY_LIST'

export const SUBCATEGORY_FIELD_CREATE = 'SUBCATEGORY_FIELD_CREATE'
export const SUBCATEGORY_FIELD_DELETE = 'SUBCATEGORY_FIELD_DELETE'
export const SUBCATEGORY_FIELD_COPY = 'SUBCATEGORY_FIELD_COPY'

export const PAGE_CATEGORY = 'PAGE_CATEGORY'
export const PAGE_CATEGORY_CREATE = 'PAGE_CATEGORY_CREATE'
export const PAGE_CATEGORY_DELETE = 'PAGE_CATEGORY_DELETE'
export const PAGE_CATEGORY_ACTIVATE = 'PAGE_CATEGORY_ACTIVATE'
export const PAGE_CATEGORY_LIST = 'PAGE_CATEGORY_LIST'

export const PAGE = 'PAGE'
export const PAGE_CREATE = 'PAGE_CREATE'
export const PAGE_DELETE = 'PAGE_DELETE'
export const PAGE_ACTIVATE = 'PAGE_ACTIVATE'
export const PAGES_LIST = 'PAGES_LIST'

export const ADVERT = 'ADVERT'
export const ADVERT_CREATE = 'ADVERT_CREATE'
export const ADVERT_DELETE = 'ADVERT_DELETE'
export const ADVERT_ACTIVATE = 'ADVERT_ACTIVATE'
export const ADVERT_UPDATE_STATUS = 'ADVERT_UPDATE_STATUS'
export const ADVERTS_LIST = 'ADVERTS_LIST'
export const ADVERTS_LIST_GET = 'ADVERTS_LIST_GET'
export const ADVERTS_LIST_NOTIFICATION = 'ADVERTS_LIST_NOTIFICATION'

export const STORE = 'STORE'
export const STORE_CREATE = 'STORE_CREATE'
export const STORE_DELETE = 'STORE_DELETE'
export const STORE_ACTIVATE = 'STORE_ACTIVATE'
export const STORE_UPDATE_STATUS = 'STORE_UPDATE_STATUS'
export const STORES_LIST = 'STORES_LIST'
export const STORES_LIST_GET = 'STORES_LIST_GET'
export const STORES_LIST_NOTIFICATION = 'STORES_LIST_NOTIFICATION'

export const USER = 'USER'
export const USERS_ALL = `USERS_ALL`
export const USER_BLOCK = `USER_BLOCK`
export const USER_DELETE = `USER_DELETE`
export const USER_UNBLOCK = `USER_UNBLOCK`

export const COMPLAIN_LIST = 'COMPLAIN_LIST'

export const SOCKET = 'SOCKET'
