import { compose, path, pathOr, prop, propOr, map, isEmpty } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import { createBrowserHistory } from 'history'
import queryString from 'query-string'
import ClipLoader from 'react-spinners/ClipLoader'
import { css } from '@emotion/core'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  CardFooter,
} from 'reactstrap'
import Header from '../components/Headers/Header'
import Table from '../components/Table'
import TableSearch from '../components/Table/TableSearch'
import TablePagination from '../components/Pagination'
import LoadingContent from '../components/Placeholder/LoadingContent'
import UsersListModal from '../components/Modal/UsersListModal'
import * as actions from '../redux/actions'

export const enhance = compose(
  connect(({ usersList, userInfo, socket, router, form, rubricActiveList }) => {
    const list = prop('data', usersList)
    const listContent = prop('content', list)
    const listLength = prop('total_elements', list)
    const isLastPage = prop('last', list)
    const pageSize = path(['pageable', 'pageSize'], list)
    const loading = prop('loading', usersList)

    const roles = pathOr([], ['data', 'roles'], userInfo)
    const pathname = path(['location', 'pathname'], router)
    const query = path(['location', 'query'], router)

    const updated = path(['location', 'state', 'updated'], router)
    let updatedData = null

    if (updated === true) {
      updatedData = path(['location', 'state', 'data'], router)

      const history = createBrowserHistory()

      if (history.location.state) {
        let state = { ...history.location.state }
        delete state.updated
        delete state.data
        history.replace({ ...history.location, state })
      }

      const updatedId = prop('id', updatedData)
      const updatedStatus = prop('status', updatedData)
      const updatedIndex = listContent.findIndex(function (c) {
        return c.id === updatedId
      })

      listContent[updatedIndex]['status'] = updatedStatus
      listContent[updatedIndex]['loading'] = false
    }

    const socketData = prop('data', socket)

    return {
      list,
      usersList,
      listContent,
      listLength,
      isLastPage,
      pageSize,
      loading,
      roles,
      socketData,
      pathname,
      updated,
      updatedData,
      query,
      rubricActiveList,
      form,
    }
  })
)

function UsersList(props) {
  const {
    pathname,
    query,
    history,
    itemUpdateStatusLoading,
    listContent,
    dispatch,
    listLength,
    pageSize,
    isLastPage,
  } = props

  const [modalIsOpened, setModalIsOpened] = useState(false)
  const [content, setContent] = useState(null)

  const tableHeader = [
    {
      title: 'ID',
    },
    {
      title: 'Имя',
    },
    {
      title: 'Фамилия',
    },

    {
      title: 'Номер телефона',
    },
    {
      title: 'Статус',
    },
    {
      title: 'Фото',
    },
    {
      title: 'Действие',
    },
  ]
  const currentPage = propOr(1, 'page', query)

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `

  useEffect(() => {
    dispatch(actions.getUsersAll())

    props.dispatch(
      actions.getUsersAll({
        page: currentPage - 1,
        search: prop('search', query),
      })
    )
  }, [query])

  function changeStatus(id, status) {
    if (status === 'ACTIVE') {
      dispatch(actions.blockUser({ id }))
    } else if (status === 'WAITING_VERIFICATION') {
      dispatch(actions.deleteUser({ id }))
    } else if (status === 'BLOCKED') {
      dispatch(actions.activateUser({ id }))
    }
  }

  function handlePagination(page) {
    props.dispatch(
      actions.getUsersAll({
        page: page - 1,
      })
    )

    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        page: page,
      }),
    })
  }

  function handleSuccess() {
    dispatch(submit('UserEditForm'))
  }
  function handleSubmit() {}
  const initialValues = {
    ...content,
  }

  function getActionDropdown(item) {
    const id = prop('id', item)
    const loading = prop('loading', item) || false

    return (
      <td className='text-right border-top-0'>
        <UncontrolledDropdown>
          <DropdownToggle
            className='btn-icon-only text-light'
            role='button'
            size='sm'
            color=''
            onClick={e => e.preventDefault()}
          >
            {(loading && (
              <ClipLoader css={override} size={16} color={'#123abc'} loading={true} />
            )) || <i className='fas fa-ellipsis-v' />}
          </DropdownToggle>
          <DropdownMenu className='dropdown-menu-arrow' right>
            {prop('status', item) === 'ACTIVE' ? (
              <DropdownItem
                href='#pablo'
                style={{
                  cursor: 'pointer',
                }}
                onClick={e => {
                  e.preventDefault()
                  changeStatus(id, prop('status', item))
                }}
              >
                Деактивировать
              </DropdownItem>
            ) : (
              <DropdownItem
                href='#pablo'
                style={{
                  cursor: 'pointer',
                }}
                onClick={e => {
                  e.preventDefault()
                  changeStatus(id, prop('status', item))
                }}
              >
                Активировать
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    )
  }
  const createTableRow = (item, index) => {
    return (
      <tr key={index}>
        <th
          scope='row'
          style={{
            position: 'absolute',
            width: '85%',
            height: '113px',
            cursor: 'pointer',
          }}
          onClick={e => {
            e.preventDefault()
            setContent(item)
            if (!isEmpty(item)) {
              setModalIsOpened(true)
            }
          }}
        >
          <span
            style={{
              display: 'inline-block',
              marginTop: '22px',
            }}
          >
            {prop('id', item)}
          </span>
        </th>
        <td>{prop('first_name', item)}</td>
        <td>{prop('last_name', item)}</td>
        <td>{prop('phone_number', item)}</td>
        <td>{prop('status', item)}</td>
        <td>
          {(prop('profile_pic', item) === null && <p>Нет лого</p>) || (
            <img
              src={path(['profile_pic', 'url'], item)}
              style={{
                width: '52px',
                height: '52px',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          )}
        </td>
        <td>{getActionDropdown(item)}</td>
      </tr>
    )
  }

  let tableContent = map((item, index) => {
    return createTableRow(item, index)
  }, listContent || [])

  return (
    <Fragment>
      <Header />
      <Container className=' mt--7' fluid>
        <Row>
          <Col>
            <Card className='shadow'>
              <CardHeader className='border-0'>
                <Row className='align-items-center'>
                  <Col xs='9'>
                    <h3 className='mb-0'>Пользователи</h3>
                  </Col>
                  <Col className='text-right' xs='3'>
                    <TableSearch history={history} pathname={pathname} query={query} />
                  </Col>
                </Row>
              </CardHeader>

              <Table
                header={tableHeader}
                content={
                  (tableContent.length > 0 && tableContent) || (
                    <tr>
                      <td colSpan='5' align='center'>
                        {(itemUpdateStatusLoading === false && 'Нету элементов') || (
                          <LoadingContent minHeight={'56px'} color={'#24bc79'} />
                        )}
                      </td>
                    </tr>
                  )
                }
              />
              <CardFooter className='py-4'>
                <nav aria-label='...'>
                  <TablePagination
                    totalElements={listLength}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    last={isLastPage}
                    onChange={handlePagination}
                  />
                </nav>
              </CardFooter>
              {content && (
                <UsersListModal
                  setModalIsOpened={setModalIsOpened}
                  modalIsOpened={modalIsOpened}
                  user={content}
                  handleSuccess={handleSuccess}
                  handleSubmit={handleSubmit}
                  initialValues={initialValues}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default enhance(UsersList, 'test')
