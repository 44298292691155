import actionType, { REGION, REGION_CREATE, REGION_DELETE } from './actionTypes'

export const region = payload => ({
  type: actionType[REGION].request,
  payload,
})

export const regionClear = payload => ({
  type: actionType[REGION].clear,
  payload,
})

export const regionCreate = payload => ({
  type: actionType[REGION_CREATE].request,
  payload,
})

export const regionDelete = payload => ({
  type: actionType[REGION_DELETE].request,
  payload,
})
