import { prop, dissoc } from 'ramda'
import { NotAuthApi, AuthApi } from '../helpers/httpClients'
import { COUNTRY } from '../constants/api'

export const countryList = data => AuthApi().get(COUNTRY + '/', data)

export const countryGet = data =>
  AuthApi().get(`${COUNTRY}/${prop('id', data)}`, dissoc('id', data))

export const countryCreate = data => AuthApi().post(COUNTRY + '/', data)

export const countryUpdate = data => AuthApi().put(`${COUNTRY}/${prop('id', data)}`, data)

export const countryDelete = data => AuthApi().delete(`${COUNTRY}/${prop('id', data)}`)
