import { all, fork, take, takeLatest, put, call } from 'redux-saga/effects'
import { Socket } from './socket'
import actionType, { SOCKET } from './actionTypes'

function* socketRequest() {
  yield takeLatest(actionType[SOCKET].request, function* ({ payload }) {
    const channel = yield call(Socket, {
      actionType: {
        success: actionType[SOCKET].success,
        error: actionType[SOCKET].error,
      },
      onMessageHandle: payload,
    })

    while (true) {
      const action = yield take(channel)

      yield put(action)
    }
  })
}

export default function* rootSaga() {
  yield all([fork(socketRequest)])
}
