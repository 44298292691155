import { prop, dissoc } from 'ramda'
import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'
import { signIn } from '../../api/authorization'
import actionType, { SIGN_IN } from './actionTypes'
import * as actions from '../actions'
import * as route from '../../constants/routes'

function* signInRequest() {
  yield takeEvery(actionType[SIGN_IN].request, function* ({ payload }) {
    try {
      const { data } = yield call(signIn, dissoc('remember', payload))

      yield put({
        type: actionType[SIGN_IN].success,
        payload: data,
      })
    } catch ({ data, status }) {
      yield put({
        type: actionType[SIGN_IN].error,
        error: {
          ...data,
          statusCode: status,
        },
      })
    }
  })
}

function* signInSuccess() {
  yield takeEvery(actionType[SIGN_IN].success, function* ({ payload }) {
    yield put(actions.token(payload))

    yield put(
      actions.userInfo({
        type: 'login',
      })
    )
  })
}

function* signInError() {
  yield takeEvery(actionType[SIGN_IN].error, ({ error }) => {
    const status = prop('statusCode', error)

    if (status === 401) {
      toastr.error('Ошибка', 'Неверный логин или пароль')
    }

    if (status === 403) {
      toastr.error('Ошибка', 'Ваш аккаунт заблокирован администратором')
    }
  })
}

export default function* rootSaga() {
  yield all([fork(signInRequest), fork(signInSuccess), fork(signInError)])
}
