import { prop, dissoc } from 'ramda'
import { AuthApi } from '../helpers/httpClients'
import { CATEGORY, RUBRIC } from '../constants/api'

export const categoryList = data => AuthApi().get(CATEGORY + '/deleted/all', data)

export const categoryPagedList = data => AuthApi().get(CATEGORY + '/', data)

export const categoryGet = data =>
  AuthApi().get(`${CATEGORY}/full/${prop('id', data)}`, dissoc('id', data))

export const categoryGetOld = data =>
  AuthApi().get(`${CATEGORY}/${prop('id', data)}`, dissoc('id', data))

export const categoryCreate = data => AuthApi().post(CATEGORY + '/', data)

export const categoryUpdate = data => AuthApi().put(`${CATEGORY}/${prop('id', data)}`, data)

export const categoryDelete = data => AuthApi().delete(`${CATEGORY}/${prop('id', data)}`)

export const categoryActivate = data => AuthApi().put(`${CATEGORY}/activate/${prop('id', data)}`)
