import actionType, { DISTRICT, DISTRICT_CREATE, DISTRICT_DELETE } from './actionTypes'

export const district = payload => ({
  type: actionType[DISTRICT].request,
  payload,
})

export const districtClear = payload => ({
  type: actionType[DISTRICT].clear,
  payload,
})

export const districtCreate = payload => ({
  type: actionType[DISTRICT_CREATE].request,
  payload,
})

export const districtDelete = payload => ({
  type: actionType[DISTRICT_DELETE].request,
  payload,
})
