import actionType, { STORES_LIST, STORES_LIST_GET, STORES_LIST_NOTIFICATION } from './actionTypes'

export const storesListForSaga = payload => ({
  type: actionType[STORES_LIST].request,
  payload,
})

export const storesList = payload => ({
  type: actionType[STORES_LIST_GET].request,
  payload,
})

export const storesListClear = () => ({
  type: actionType[STORES_LIST].clear,
})

export const storeNotification = payload => ({
  type: actionType[STORES_LIST_NOTIFICATION].request,
  payload,
})
